import React, { useEffect, useRef, useState } from "react";
import { Table, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";

const VesselTable = (props) => {
  const [search, setSearch] = useState("");
  const [filteredOptions, setFilteredOptions] = useState([]);

  const handleSearch = (e) => {
    const searchValue = e.target.value.toLowerCase();
    setSearch(searchValue);
  };

  // using useeffect to make dynamic search for table
  useEffect(() => {
    if (search) {
      const filteredData = props?.data?.filter((option) => {
        return props?.searchKeys.some((key) =>
          option[key]?.toLowerCase().includes(search)
        );
      });
      setFilteredOptions([...filteredData]);
    }
  }, [search, props?.data, props?.searchKeys]);
  const dataSource = search ? filteredOptions : props.data;

  // useeffect for placeholder texts 
  const [placeholder, setPlaceholder] = useState('');
  const words = ['S', 'e', 'a', 'r', 'c', 'h', ' ', 'H', 'e', 'r', 'e', '.', '.',];
  const wordIndexRef = useRef(0);
  const placeholderRef = useRef('');

  useEffect(() => {
    const interval = setInterval(() => {
      if (wordIndexRef.current < words.length) {
        placeholderRef.current += words[wordIndexRef.current];
        setPlaceholder(placeholderRef.current); 
        wordIndexRef.current += 1; 
      } else {
        clearInterval(interval);
      }
    }, 90);
    return () => clearInterval(interval); 
  }, []);

  return (
    <div>
      <div style={{ marginTop: "10px", marginBottom: "10px" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            // border: "1px solid black",
            borderRadius: "4px",
          }}
        >
          <input
            type="text"
            placeholder={placeholder}
            className="custom-input"
            variant="filled"
            onChange={handleSearch}
            style={{ flex: 1, border: "1px solid #12406a", borderRadius:"5px" }}
          />
          <span style={{ padding: "0 10px" }}>
            <SearchOutlined />
          </span>
        </div>
      </div>

      <Table
        style={{
          width: "auto",
          position: "sticky",
          height: "250px",
          overflowY: "auto",
        }}
        pagination={false}
        dataSource={dataSource && dataSource.length > 0 ? dataSource : []}
        columns={props.columns}
      />
    </div>
  );
};

export default VesselTable;
