import React, { useEffect, useState } from "react";
import { Form, Button, Spin, Layout, Alert, Input } from "antd";
import URL_WITH_VERSION, {
  getAPICall,
  postAPICall,
  useStateCallback,
} from "../../../../../shared";
import redux_state from "../../../../../services/redux_state";
const { Content } = Layout;
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 10 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
  },
};
const FormItem = Form.Item;
const WorldScale = (props) => {
  const [state, setState] = useStateCallback({
    frmName: "worldscale_rate",
    // formData: {
    //   ws_flat: 0,
    //   freight_rate: 0,
    //   frat_rate: 0,
    //   ttl_freight: null,
    // },
    frmVisible: true,
  });
  const [formData, setFormData] = useState({
    ws_flat: 0,
    freight_rate: 0,
    frat_rate: 0,
    ttl_freight: null,
  });

  const [wsrId, setWsrId] = useState(null);

  const ImportFormData = async () => {
    try {
      const saveurl = `${URL_WITH_VERSION}/tcov/wordscale-save`;
      const updateurl = `${URL_WITH_VERSION}/tcov/wordscale-update`;
      const wsId = props?.formData?.world_scale_id;
      const method = wsId ? "PUT" : "POST";
      const url = wsId ? updateurl : saveurl;
      const aiPayLoad = wsId ? { ...formData, id: wsId } : formData;
      const response = await postAPICall(url, aiPayLoad, method);
      const frmData = response?.data[0];
      setFormData({
        ws_flat: frmData?.ws_flat,
        freight_rate: frmData?.freight_rate,
        frat_rate: frmData?.frat_rate,
        ttl_freight: frmData?.ttl_freight,
      });
      if (response?.data?.id) {
        setWsrId(response?.data?.id);
        props.modalCloseEvent({
          ...formData,
          world_scale_id: response?.data?.id,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getWorldScaleData = async () => {
    try {
      const wsId = props?.formData?.world_scale_id;
      if (!wsId) return; // Exit if no ID is provided

      const url = `${URL_WITH_VERSION}/tcov/wordscale-edit?ae=${wsId}`;
      const response = await getAPICall(url);
      const data = response?.data[0];

      if (data) {
        // Map the API response to formData
        const fetchedData = {
          ws_flat: data?.ws_flat || 0,
          freight_rate: data?.freight_rate || 0,
          frat_rate: data?.frat_rate || 0,
          ttl_freight: data?.ttl_freight || null,
        };

        setFormData(fetchedData);
        setWsrId(response?.data?.id);
      }
    } catch (error) {
      console.error("Error fetching world scale data:", error);
    }
  };

  useEffect(() => {
    if (props?.formData?.world_scale_id) {
      getWorldScaleData();
    }
  }, [props.formData.world_scale_id]);

  const handleChange = (name, value) => {
    let updatedFormData = { ...formData, [name]: value };
  
    let cpQty = props?.formData?.cp_qty ?? 0;
    let wsFlat = isNaN(updatedFormData.ws_flat) ? 0 : updatedFormData.ws_flat * 1;
    let freightRate = isNaN(updatedFormData.freight_rate) ? 0 : updatedFormData.freight_rate * 1;
  
    let finalFreight = wsFlat * freightRate * 0.01;
    let TotalFreight = cpQty * finalFreight;
  
    updatedFormData = {
      ...updatedFormData,
      frat_rate: isNaN(finalFreight) ? 0 : finalFreight.toFixed(2),
      ttl_freight: isNaN(TotalFreight) ? 0 : TotalFreight.toFixed(2),
    };
  
    setFormData(updatedFormData);
  };
  

  return (
    <div className="tcov-wrapper full-wraps">
      <Layout className="layout-wrapper">
        <Content className="content-wrapper">
          <div className="fieldscroll-wrap">
            {state.frmVisible ? (
              <div className="body-wrapper">
                <article className="article">
                  <div className="box box-default">
                    <div className="box-body">
                      <Form>
                        <FormItem
                          {...formItemLayout}
                          label="Select From Existing Route"
                        ></FormItem>
                        <FormItem {...formItemLayout} label="WS 100 (Flat)">
                          <Input
                            type={"number"}
                            name="ws_flat"
                            value={formData["ws_flat"]}
                            onChange={(e) =>
                              handleChange(e.target.name, e.target.value)
                            }
                            addonAfter="$/MT"
                          />
                        </FormItem>

                        <FormItem {...formItemLayout} label="Frieght rate (%)">
                          <Input
                            type={"number"}
                            className="ant-input"
                            name="freight_rate"
                            value={formData["freight_rate"]}
                            onChange={(e) =>
                              handleChange(e.target.name, e.target.value)
                            }
                          />
                        </FormItem>

                        <FormItem {...formItemLayout} label="Final flat rate">
                          <Input
                            type={"number"}
                            name="frat_rate"
                            value={formData["frat_rate"]}
                            onChange={(e) =>
                              handleChange(e.target.name, e.target.value)
                            }
                            addonAfter="$/MT"
                          />
                        </FormItem>

                        <FormItem {...formItemLayout} label="Total freight">
                          <Input
                            type={"number"}
                            className="ant-input"
                            name="ttl_freight"
                            value={formData["ttl_freight"]}
                            onChange={(e) =>
                              handleChange(e.target.name, e.target.value)
                            }
                          />
                        </FormItem>

                        <div className="action-btn text-right">
                          <Button
                            type="primary"
                            onClick={() => ImportFormData()}
                          >
                            Import
                          </Button>
                        </div>
                      </Form>
                    </div>
                  </div>
                </article>
              </div>
            ) : (
              <div className="col col-lg-12">
                <Spin tip="Loading...">
                  <Alert message=" " description="Please wait..." type="info" />
                </Spin>
              </div>
            )}
          </div>
        </Content>
      </Layout>
    </div>
  );
};

export default WorldScale;
