import React, { useEffect, useState } from "react";
import { getAPICall } from "../../shared";
import { Table, Button, Spin, DatePicker } from 'antd';
import dayjs from "dayjs";
const { RangePicker } = DatePicker;


const VesselHistory = ({ currentVesseldata }) => {
    const { 
        vessel_name
        ,SHIPNAME, IMO } = currentVesseldata;
    const [shipData, setShipData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [dateRange, setDateRange] = useState([]);

    const getDetails = async (filterByDate = false) => {
        try {
            setLoading(true);
            const date_range = dateRange.length
            ? `${dayjs(dateRange[0]).format("YYYY-MM-DD")}/${dayjs(dateRange[1]).format("YYYY-MM-DD")}`
            : "";
            // const date_range = dateRange.length
            // ? `${dayjs(dateRange[0]).format("YYYY-MM-DD")}/${dayjs(dateRange[1]).format("YYYY-MM-DD")}`
            // : `${dayjs().subtract(3, "month").format("YYYY-MM-DD")}/${dayjs().format("YYYY-MM-DD")}`;
        


            // console.log("------>>>>>>",date_range);
            // let url = `${process.env.REACT_APP_MT_URL}marine/ship-history?ship_name=${SHIPNAME !== undefined ? SHIPNAME :
            //     vessel_name
            //     }&month=${monthFilter}`;
            // let url = `${process.env.REACT_APP_MT_URL}marine/ship-history?ship_name=${SHIPNAME !== undefined ? SHIPNAME :
            //     vessel_name
            //     }&date_range=${date_range}`;


            let url = `${process.env.REACT_APP_MT_URL}marine/ship-history?ship_name=${SHIPNAME || vessel_name}`;

            if (filterByDate && dateRange.length) {
                const date_range = `${dayjs(dateRange[0]).format("YYYY-MM-DD")}/${dayjs(dateRange[1]).format("YYYY-MM-DD")}`;
                url += `&date_range=${date_range}`;
            }
           

            const response = await getAPICall(url);
            const cleanData = response?.data.map(item => ({
                ...item,
                TIMESTAMP: item?.TIMESTAMP || 'N/A',
                SPEED: item?.SPEED ?? 'N/A',
                LAT: item?.LAT ?? 'N/A',
                LON: item?.LON ?? 'N/A',
                DWT: item?.DWT ?? 'N/A',
                LAST_PORT: item?.LAST_PORT ?? 'N/A',
                NEXT_PORT_NAME: item?.NEXT_PORT_NAME ?? 'N/A',
                SHIP_CLASS: item?.SHIP_CLASS ?? 'N/A',
                YEAR_BUILT: item?.YEAR_BUILT ?? 'N/A',
            }));
            setShipData(cleanData);
        } catch (err) {
            console.log("Error fetching data:", err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getDetails(false);
    }, [SHIPNAME]);

 

    const columns = [
        {
            title: 'Ship Name',
            dataIndex: 'SHIPNAME',
            key: 'SHIPNAME',
            align: 'center',
            render: (text) => <div style={{ paddingLeft: '0.8rem', paddingRight: '0.8rem' }}>{text || 'N/A'}</div>
        },
        {
            title: 'Ship Type',
            dataIndex: 'SHIPTYPE',
            key: 'SHIPTYPE',
            align: 'center',
            render: (text) => <div style={{ paddingLeft: '0.8rem', paddingRight: '0.8rem' }}>{text || 'N/A'}</div>
        },
        {
            title: 'Time Stamp',
            dataIndex: 'TIMESTAMP',
            key: 'TIMESTAMP',
            align: 'center',
            render: (text) => <div style={{ paddingLeft: '0.8rem', paddingRight: '0.8rem' }}>{text || 'N/A'}</div>
        },
        {
            title: 'MMSI',
            dataIndex: 'MMSI',
            key: 'MMSI',
            align: 'center',
            render: (text) => <div style={{ paddingLeft: '0.8rem', paddingRight: '0.8rem' }}>{text || 'N/A'}</div>
        },
        {
            title: 'IMO',
            dataIndex: 'IMO',
            key: 'IMO',
            align: 'center',
            render: (text) => <div style={{ paddingLeft: '0.8rem', paddingRight: '0.8rem' }}>{text || 'N/A'}</div>
        },
        {
            title: 'Speed',
            dataIndex: 'SPEED',
            key: 'SPEED',
            align: 'center',
            render: (text) => <div style={{ paddingLeft: '0.8rem', paddingRight: '0.8rem' }}>{text || 'N/A'}</div>
        },
        {
            title: 'Max Speed',
            dataIndex: 'MAX_SPEED',
            key: 'MAX_SPEED',
            align: 'center',
            render: (text) => <div style={{ paddingLeft: '0.8rem', paddingRight: '0.8rem' }}>{text || 'N/A'}</div>
        },
        {
            title: 'AVG Speed',
            dataIndex: 'AVG_SPEED',
            key: 'AVG_SPEED',
            align: 'center',
            render: (text) => <div style={{ paddingLeft: '0.8rem', paddingRight: '0.8rem' }}>{text || 'N/A'}</div>
        },
        {
            title: 'DWT',
            dataIndex: 'DWT',
            key: 'DWT',
            align: 'center',
            render: (text) => <div style={{ paddingLeft: '0.8rem', paddingRight: '0.8rem' }}>{text || 'N/A'}</div>
        },
        {
            title: 'Longitude',
            dataIndex: 'LON',
            key: 'LON',
            align: 'center',
            render: (text) => <div style={{ paddingLeft: '0.8rem', paddingRight: '0.8rem' }}>{text || 'N/A'}</div>
        },
        {
            title: 'Latitude',
            dataIndex: 'LAT',
            key: 'LAT',
            align: 'center',
            render: (text) => <div style={{ paddingLeft: '0.8rem', paddingRight: '0.8rem' }}>{text || 'N/A'}</div>
        },
        {
            title: 'Last Port',
            dataIndex: 'LAST_PORT',
            key: 'LAST_PORT',
            align: 'center',
            render: (text) => <div style={{ paddingLeft: '0.8rem', paddingRight: '0.8rem' }}>{text || 'N/A'}</div>
        },
        {
            title: 'Ship Class',
            dataIndex: 'SHIP_CLASS',
            key: 'SHIP_CLASS',
            align: 'center',
            render: (text) => <div style={{ paddingLeft: '0.8rem', paddingRight: '0.8rem' }}>{text || 'N/A'}</div>
        },
        {
            title: 'Next Port',
            dataIndex: 'NEXT_PORT_NAME',
            key: 'NEXT_PORT_NAME',
            align: 'center',
            render: (text) => <div style={{ paddingLeft: '0.8rem', paddingRight: '0.8rem' }}>{text || 'N/A'}</div>
        },
        {
            title: 'Year Built',
            dataIndex: 'YEAR_BUILT',
            key: 'YEAR_BUILT',
            align: 'center',
            render: (text) => <div style={{ paddingLeft: '0.8rem', paddingRight: '0.8rem' }}>{text || 'N/A'}</div>
        },
    ];

    const rowClassName = (record, index) => {
        return index % 2 === 0 ? 'row1-white' : 'row1-grey';
    };

    if (loading) {
        return <Spin size="large" />;
    }


    const handleDateChange = (dates) => {
        setDateRange(dates);

        // if (!dates || dates.length === 0) {
        //     getDetails(false);
        // }

    };

    const applyDateFilter = () => {
        getDetails(true);
    };

    const disabledDate = (current) => {
        return current && current < dayjs().subtract(3, "months").startOf("day");
    };

    return (
        <div>
            <h4 style={{ color: "#003366" }}>{SHIPNAME}</h4>
            <div style={{ display: "flex", gap: "1rem", marginBottom: "1rem" }}>
                <RangePicker 
                    onChange={handleDateChange} 
                    disabledDate={disabledDate} 
                    format="YYYY-MM-DD"
                    allowClear
                />
                {/* <Button type="primary" onClick={getDetails}>Filter</Button> */}

                <Button type="primary" onClick={applyDateFilter}>Filter</Button>
              
             

            </div>
            {/* <div style={{ display: "flex", gap: "0.5rem", marginBottom: "0.2rem" }}>
                <Button className="red-gradient-button1" onClick={() => setMonthFilter("1m")}>1 Month Data</Button>
                <Button className="red-gradient-button1" onClick={() => setMonthFilter("2m")}>2 Months Data</Button>
                <Button className="red-gradient-button1" onClick={() => setMonthFilter("3m")}>3 Months Data</Button>
            </div> */}

            <Table
                columns={columns}
                dataSource={shipData}
                rowKey="IMO"
                pagination={true}
                bordered
                rowClassName={rowClassName}
            />
        </div>
    );
};

export default VesselHistory;
