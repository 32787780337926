import React, { useEffect, useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Form,
  //Switch,
  //Icon,
  //Radio,
  //Select,
  Input,
  Button,
  Table,
  Layout,
  Modal,
  Drawer,
  Row,
  Col,
  Checkbox,
  notification,
  Spin,
  Alert,
} from "antd";
import RightBarUI from "../../../../components/RightBarUI";
//import ToolbarUI from 'components/ToolbarUI';
import NormalFormIndex from "../../../../shared/NormalForm/normal_from_index";
import {
  uploadAttachment,
  deleteAttachment,
  getAttachments,
} from "../../../../shared/attachments";
import URL_WITH_VERSION, {
  getAPICall,
  postAPICall,
  awaitPostAPICall,
  openNotificationWithIcon,
  apiDeleteCall,
  objectToQueryStringFunc,
  useStateCallback,
} from "../../../../shared";
import EstimateSummary from "../tcto/right-panel/EstimateSummary";
import PlSummary from "./PlSummary";
//import '../../../../assets/main.css';
//import DemdesTerm from './Demdesterm/';
import Properties from "../tcov/Properties";
import CargoContract from "../cargo-contract";
import Attachment from "../../../../shared/components/Attachment";
import VcPurchageReports from "../../../form-reports/VcPurchageReports";
import moment from "moment";
import {
  DeleteOutlined,
  MenuFoldOutlined,
  PlusOutlined,
  SaveOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { useState } from "react";
import CargoPurchaseReport from "./CargoPurchaseReport";
import MailSpiltView from "../../../inbox/Inbox";
import { useDispatch, useSelector } from "react-redux";
import { toggleCollapsedNav } from "../../../../actions/settingsActions";
import redux_state from "../../../../services/redux_state";

const FormItem = Form.Item;
const InputGroup = Input.Group;
const { TextArea } = Input;

const { Content } = Layout;
//const Option = Select.Option;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 10 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
  },
};

const columns10 = [
  {
    title: "Quantity",
    dataIndex: "Quantity",
    key: "Quantity",
  },
  {
    title: "Laycan From",
    dataIndex: "laycan_from",
    key: "laycan_from",
  },
  {
    title: "Laycan To",
    dataIndex: "laycan_to",
    key: "laycan_to",
  },
  {
    title: "CP Date",
    dataIndex: "cp_date",
    key: "cp_date",
  },
];

const data10 = [];

const openNotification = (keyName, isValid = false, type = "save") => {
  let msg = `Please save VC (Purchase) Form first, and then click on ${keyName}`;
  if (isValid)
    msg = `You cannot ${keyName} as it is already being attached. Kindly update it in same form.`;

  if (type == "edit")
    msg = `You cannot ${keyName} as VC-Purchase Id is not attached yet.`;

  notification.info({
    message: `Can't Open ${keyName}`,
    description: msg,
    placement: "topRight",
  });
};

const CargoDetails = (props) => {
  // console.log("CALLED------->>>>2");
  const [refreshCounter, setRefreshCounter] = useState(0);
  const [state, setState] = useStateCallback({
    vc_purchase_id: undefined,
    visibleFixture: false,
    isVisibleCopy: false,
    frmName: "vcargo_in_contract_form",
    fileArr: [],
    formData: Object.assign(
      {
        id: 0,
        cp_date: moment(),
        laycan_from: moment(),
        laycan_to: moment(),
        billingandbankingdetails: {
          due_date: moment(),
        },
      },
      props.formData || {}
    ),
    cargoContracts: [],
    frmVisible: true,
    visibleDrawer: false,
    isVisibleCopy: false,
    frmName: "vcargo_in_contract_form",
    formData: Object.assign(
      {
        id: 0,
        cp_date: moment(),
        laycan_from: moment(),
        laycan_to: moment(),
        billingandbankingdetails: {
          due_date: moment(),
        },
      },
      props.formData || {}
    ),
    cargoContracts: [],
    frmVisible: true,
    visibleDrawer: false,

    title: undefined,
    loadComponent: undefined,
    width: 1200,
    showSideListBar:
      props.showSideListBar === false ? props.showSideListBar : false,
    isVisible: false,
    isCargoPurchaseReport: false,
    visibleContactAttachment: false,
    isShowVcPurchageReports: false,
    postFormData: [],
    secondaryData: {},
    selectedID: null,
  });
  const formRef = useRef();
  const saveformDataRef = useRef();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  const storeData = useSelector((store) => store.settings);
  const dispatch = useDispatch();

  // useEffect(() => {
  //   if (params.id) {
  //     _onLeftSideListClick(params.id);
  //   } else {
  //     _onCreateFormData();
  //   }
  // }, [window.location.hash]);

  const _onCreateFormData = () => {
    navigate(`/add-voyage-cargo`);
    setState(
      (prev) => ({ ...prev, frmVisible: false }),
      () => {
        setState((prev) => ({
          ...prev,
          formData: {
            id: 0,
            cp_date: moment(),
            laycan_from: moment(),
            laycan_to: moment(),
            billingandbankingdetails: {
              due_date: moment(),
            },
          },
          showSideListBar: false,
          frmVisible: true,
          selectedID: null,
        }));
      }
    );
  };

  useEffect(() => {
    const _isSetsLeftBtn = [
      {
        id: "7",
        key: "menu-fold",
        type: <MenuFoldOutlined />,
        withText: "List",
        showToolTip: true,
        event: "menu-fold",
      },
      {
        id: "1",
        key: "add",
        type: <PlusOutlined />,
        withText: "Add New",
        showToolTip: true,
        event: (key, data) => _onCreateFormData(),
      },

      {
        id: "3",
        key: "save",
        type: <SaveOutlined />,
        withText: "Save",
        showToolTip: true,
        event: (key, data) => saveFormData(data),
      },

      ...(state?.formData?.id !== 0 &&
      state?.formData?.id !== undefined &&
      !props?.showDeleteIcon &&
      props.showDeleteIcon !== "noShow"
        ? [
            {
              id: "4",
              key: "delete",
              type: <DeleteOutlined />,
              withText: "Delete",
              showToolTip: true,
              event: (key, data) => _onDeleteFormData(data),
            },
          ]
        : []),
      {
        id: "20",
        key: "refresh",
        type: <SyncOutlined />,
        withText: "Refresh",
        showToolTip: true,
        event: () => {
          reFreshForm();
        },
      },
    ];

    setState((prevState) => ({
      ...prevState,
      isSetsLeftBtn: _isSetsLeftBtn,
      // isSetsRightBtn: _isSetsRightBtn,
    }));
  }, [state?.formData]);

  // useEffect(()=>{
  //   /*
  //   ======Added this useEffect to fixed below issue========
  //   The new estimate form does not open when clicked from the side menu in VC Purchase (TOCERP-2425).
  //   */
  //   if(location.pathname === "/add-voyage-cargo" && Object.keys(params)?.length === 0 ){
  //     setState((prevState)=>({
  //       ...prevState,
  //       frmVisible:false,
  //       formData:{}
  //     }));
  //     setTimeout(() => {
  //       setState((prevState)=>({
  //         ...prevState,
  //         frmVisible:true
  //       }));
  //     }, 500);
  //   }
  // },[location.pathname,params])

  const reFreshForm = () => {
    setRefreshCounter((prevCounter) => prevCounter + 1);

    if (
      formData?.hasOwnProperty("vc_purchase_id") &&
      formData?.["vc_purchase_id"]
    ) {
      _onLeftSideListClick(formData);
    }

    if (!state.formData?.id) {
      setState(
        (prevState) => ({
          ...prevState,
          formData: { ...formRef.current },
          frmVisible: false,
        }),
        () => {
          setState((prev) => ({ ...prev, frmVisible: true }));
        }
      );
    }
  };

  const onCloseDrawer = () =>
    setState((prev) => ({
      ...prev,
      visibleDrawer: false,
      title: undefined,
      loadComponent: undefined,
    }));

  const _onDeleteFormData = (postData) => {
    if (postData && postData.id <= 0) {
      openNotificationWithIcon(
        "error",
        "Cargo Id is empty. Kindly check it again!"
      );
    }
    Modal.confirm({
      title: "Confirm",
      content: "Are you sure, you want to delete it?",
      onOk: () => _onDelete(postData),
    });
  };

  const _onDelete = (postData) => {
    let _url = `${URL_WITH_VERSION}/voyagecargo/delete`;
    apiDeleteCall(_url, { id: postData.id }, (response) => {
      if (response && response.data) {
        openNotificationWithIcon("success", response.message);

        let setData = Object.assign({
          id: 0,
          cp_date: moment(),
          laycan_from: moment(),
          laycan_to: moment(),
          billingandbankingdetails: {
            due_date: moment(),
          },
        });

        if (postData?.is_fixed === 1) {
          editformdata(postData.vc_purchase_id);
        } else {
          setState(
            (prev) => ({ ...prev, formData: {}, frmVisible: false }),
            () => {
              setState((prev) => ({
                ...prev,
                frmVisible: true,
                showSideListBar: true,
              }));
            }
          );
        }

        // setState(
        //   (prev) => ({ ...prev, formData: {}, frmVisible: false }),
        //   () => {
        //     setState((prev) => ({
        //       ...prev,
        //       frmVisible: true,
        //       showSideListBar: true,
        //     }));
        //   }
        // );
        // navigate(`/edit-voyage-cargo${postData.vc_purchase_id}`);
        navigate(`/add-voyage-cargo`);
      } else {
        openNotificationWithIcon("error", response.message);
      }
    });
  };

  const onClickRightMenu = async (key, options) => {
    if (!storeData.collapsedNav.collapsedNav) {
      dispatch(toggleCollapsedNav(true));
    }

    onCloseDrawer();
    let loadComponent = undefined;

    switch (key) {
      case "summary":
        loadComponent = <EstimateSummary />;
        break;
      case "pl-summary":
        loadComponent = <PlSummary />;
        break;
      case "properties":
        loadComponent = <Properties />;
        break;
      case "attachment":
        const { vc_purchase_id } = state.formData; //different

        if (vc_purchase_id) {
          const attachments = await getAttachments(vc_purchase_id, "EST");
          const callback = (fileArr) =>
            uploadAttachment(fileArr, vc_purchase_id, "EST", "voyage-cargo");
          loadComponent = (
            <Attachment
              uploadType="Estimates"
              attachments={attachments}
              onCloseUploadFileArray={callback}
              deleteAttachment={(file) =>
                deleteAttachment(file.url, file.name, "EST", "voyage-cargo")
              }
              tableId={0}
            />
          );
        } else {
          openNotificationWithIcon(
            "info",
            "Attachments are not allowed here. Please Save First."
          );
        }
        break;

      case "mail": {
        loadComponent = <MailSpiltView />;
        break;
      }

      default:
        break;
    }

    setState((prev) => ({
      ...prev,

      visibleDrawer: true,
      title: options.title,
      loadComponent: loadComponent,
      width: options.width && options.width > 0 ? options.width : 1200,
    }));

    if (loadComponent) {
      setState((prev) => ({
        ...prev,

        visibleDrawer: true,
        title: options.title,
        loadComponent: loadComponent,
        width: options.width && options.width > 0 ? options.width : 1200,
      }));
    }
  };

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      extraFormFields: {
        isShowInMainForm: true,
        content: getExternalFormFields(),
      },
    }));
    let _id = props.fullEstimate
      ? state.formData?.cargo_contract_id || state.formData?.vc_purchase_id
      : params?.id;

    if (_id) {
      editformdata(_id);
      let createvc = localStorage.getItem("createVc");
      if (createvc == "true") {
        localStorage.setItem("createVc", "false");
      }
    }
  }, []);

  const onCloseFixture = () => {
    setState({
      visibleFixture: false,
    });
  };

  const callback = (evt) => {
    if (evt === "copy-existing") {
      setState({ isVisibleCopy: true });
    }
  };

  const onCloseModal = () => {
    setState({
      isVisibleCopy: false,
    });
  };

  const getExternalFormFields = () => {
    return (
      <>
        <Row gutter={16} style={{ width: "100%", padding: "0 15px" }}>
          <Col xs={24} sm={24} md={8} lg={8} xl={8} style={{ width: "100%" }}>
            <FormItem
              label="Remark"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <TextArea
                placeholder="Remark"
                autoSize={{ minRows: 4, maxRows: 4 }}
              />
            </FormItem>
          </Col>
        </Row>
        <Row gutter={16} style={{ width: "100%", padding: "15px" }}>
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <FormItem
              label="Remattance Bank"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Input placeholder="Remattance Bank" defaultValue="" />
            </FormItem>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <FormItem
              label="Invoice %"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Input placeholder="Invoice %" defaultValue="0.000" />
            </FormItem>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <FormItem
              label="Payment Terms"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <InputGroup compact>
                <Input style={{ width: "25%" }} defaultValue="" />
                <Input style={{ width: "75%" }} defaultValue="" />
              </InputGroup>
            </FormItem>
          </Col>
        </Row>
        <Row gutter={16} style={{ width: "100%", padding: "15px" }}>
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <FormItem
              label="Balance %"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Input placeholder="Balance %" defaultValue="0.00" disabled />
            </FormItem>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <FormItem
              label="Payment Terms"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <InputGroup compact>
                <Input style={{ width: "25%" }} defaultValue="" />
                <Input style={{ width: "75%" }} defaultValue="" />
              </InputGroup>
            </FormItem>
          </Col>
        </Row>
      </>
    );
  };

  const saveFormData = async (postData, innerCB) => {
    saveformDataRef.current = postData;
    // console.log(postData, "post data", postData["vc_purchase_id"]);
    const { frmName } = state;
    let _url;
    let _method;

    if (
      state.formData.c_status == 73 &&
      (postData["c_status"] == 112 || postData["c_status"] == 74)
    ) {
      let msg = `You cannot change Enquiry/Confirmed after fix status.`;
      openNotificationWithIcon(
        "info",
        <div dangerouslySetInnerHTML={{ __html: msg }} />,
        5
      );
      return false;
    }

    setState((prevState) => ({
      ...prevState,
      frmVisible: false,
    }));

    if (
      postData.hasOwnProperty("vc_purchase_id") &&
      postData["vc_purchase_id"]
    ) {
      _url = "update";
      _method = "put";
    } else {
      _url = "save";
      _method = "post";
    }

    if (postData.disablefield) {
      delete postData.disablefield;
    }

    Object.keys(postData).forEach(
      (key) => postData[key] === null && delete postData[key]
    );

    ["l_d_rates", "ld_ru", "ld_terms", "ld_tt", "pexp"].forEach(
      (e) => delete postData[e]
    );

    postAPICall(
      `${URL_WITH_VERSION}/voyagecargo/${_url}?frm=${frmName}`,
      postData,
      _method,
      (data) => {
        if (data.data) {
          openNotificationWithIcon("success", data.message);
          if (typeof props.onSaveEvent === "function" && data.row)
            props.onSaveEvent(data.row);
          let setData = Object.assign(
            {
              id: 0,
              cp_date: moment(),
              laycan_from: moment(),
              laycan_to: moment(),
              billingandbankingdetails: {
                due_date: moment(),
              },
            },
            props.formData || {}
          );

          // setState(
          //   (prev) => ({
          //     ...prev,
          //     frmVisible: false,
          //     formData: setData,
          //     showSideListBar: true,
          //   }),
          //   () => {
          //     setState((prev) => ({ ...prev, frmVisible: true }));
          //   }
          // );
          setState((prev) => ({
            ...prev,
            formData: setData,
            showSideListBar: true,
          }));

          if (!location.pathname.includes("/voy-relet-full-estimate-edit/")) {
            // if (props.modalCloseEvent && typeof props.modalCloseEvent === "function") {
            //   props.modalCloseEvent("refresh");
            // } else if (innerCB && typeof innerCB === "function") {
            //   innerCB();
            // }
          }

          if (data.row.vc_purchase_id) {
            _onLeftSideListClick(data.row);
          }
        } else {
          let dataMessage = data.message;
          let msg = "<div className='row'>";

          if (typeof dataMessage !== "string") {
            Object.keys(dataMessage).map(
              (i) =>
                (msg +=
                  "<div className='col-sm-12'>" + dataMessage[i] + "</div>")
            );
          } else {
            msg += dataMessage;
          }
          msg += "</div>";
          openNotificationWithIcon(
            "error",
            <div dangerouslySetInnerHTML={{ __html: msg }} />
          );

          setState(
            (prev) => ({
              ...prev,
              formData: postData,
              showSideListBar: true,
            }),
            () => {
              setState((prev) => ({ ...prev, frmVisible: true }));
            }
          );
        }
      }
    );
  };

  const editformdata = async (coaVciId) => {
    // console.log('coaVciId :',coaVciId)
    setState((prev) => ({ ...prev, frmVisible: false, coaVciId: coaVciId }));
    // `${URL_WITH_VERSION}/voyagecargo/edit?ae=${coaVciId}`
    let createvc = localStorage.getItem("createVc");
    const response = await getAPICall(
      formData.coa_vc_purchase_id
        ? JSON.parse(createvc)
          ? `${URL_WITH_VERSION}/coavci/props-edit?ae=${coaVciId}`
          : `${URL_WITH_VERSION}/voyagecargo/edit?ae=${coaVciId}`
        : `${URL_WITH_VERSION}/voyagecargo/edit?ae=${coaVciId}`
    );

    const resData = await response["data"];
    if (resData && !resData.hasOwnProperty("--"))
      resData["--"] = [
        { load_ports_name: "Select Port", discharge_ports_name: "Select Port" },
      ];

    // if (resData.hasOwnProperty('loadoptions') && resData.loadoptions.length > 0) {
    //   resData.loadoptions.map(e => {
    //     e['port_area_name'] = e.port_name
    //   })
    // }
    // if (resData.hasOwnProperty('dischargeoptions') && resData.dischargeoptions.length > 0) {
    //   resData.dischargeoptions.map(e => {
    //     e['port_area_name'] = e.port_name
    //   })
    // }
    // if (resData.hasOwnProperty('itineraryoptions') && resData.itineraryoptions.length > 0) {
    //   resData.itineraryoptions.map(e => {
    //     e['port_id_name'] = e.port_name
    //   })
    // }
    // if (resData.hasOwnProperty('rebillsettings') && resData.rebillsettings.length > 0) {
    //   resData.rebillsettings.map(e => {
    //     //e['cv_port_name'] = e.port_name
    //   })
    // }
    // if (resData.hasOwnProperty('--') && resData['--'].length > 0) {
    //   resData['--'].map(e => {
    // e['load_ports_name'] = e.port_name
    // e['discharge_ports_name'] = e.dis_port_name
    //   })
    // }
    if (resData.hasOwnProperty("is_fixed") && resData["is_fixed"] === 1) {
      resData["disablefield"] = [
        "charterer",
        "cp_qty",
        "cp_unit",
        "freight_rate",
        "freight_type",
        "cargo_contract_id",
        "cargo_name",
      ];
    }

    setState(
      (prev) => ({
        ...prev,
        frmVisible: false,
        secondaryData: resData["row"],
        formData: resData,
        showSideListBar: false,
        selectedID: parseInt(coaVciId.replace("VC(PUR)-", "")),
      }),
      () => {
        setState((prev) => ({ ...prev, frmVisible: true }));
      }
    );
  };

  const vcPurchageReports = async (showVcPurchageReports) => {
    // if (coaVciId || (match.params.id && match.path == ('/edit-voyage-cargo/:id'))) {
    //   let qParams = { ae: (coaVciId || match.params.id) };

    if (state.formData.vc_purchase_id === undefined) {
      openNotificationWithIcon(
        "info",
        "Please Save VC (Purchase) Contract First",
        5
      );
      return;
    }
    let qParamString = objectToQueryStringFunc({
      ae: state.formData.vc_purchase_id,
    });
    // for report Api
    const responseReport = await getAPICall(
      `${URL_WITH_VERSION}/voyagecargo/report?${qParamString}`
    );
    const respDataReport = await responseReport["data"];
    if (responseReport) {
      setState(
        (prev) => ({ ...prev, reportFormData: respDataReport }),
        () =>
          setState((prev) => ({
            ...prev,
            isShowVcPurchageReports: showVcPurchageReports,
          }))
      );
    } else {
      openNotificationWithIcon("error", "Unable to show report", 5);
    }
  };

  // const _onLeftSideListClick = async (evt) => {
  //   try {
  //     const coaVciId = evt.vc_purchase_id ? evt.vc_purchase_id : evt;
  //     setState((prevState) => ({ ...prevState }));
  //     const pattern = /^\/edit-voyage-cargo\/[A-Z0-9-]+$/;
  //     if (coaVciId && !props.fullEstimate) {
  //       navigate(`/edit-voyage-cargo/${coaVciId}`);
  //     }
  //     await editformdata(coaVciId);
  //     setState((prevState) => ({
  //       ...prevState,
  //       formData: {},
  //       // frmVisible: true,
  //       vc_purchase_id: "",
  //       cargoContracts: [],
  //     }));
  //   } catch (err) {
  //     console.log("err", err);
  //   }
  // };

  const _onLeftSideListClick = async (evt) => {
    let coaVciId = evt.vc_purchase_id ? evt.vc_purchase_id : evt;
    const pattern = /^\/edit-voyage-cargo\/[A-Z0-9-]+$/;
    if (coaVciId && !props.fullEstimate && !/^VC/.test(coaVciId)) {
      navigate(`/edit-voyage-cargo/${coaVciId}`);
    }
    await editformdata(coaVciId);
  };

  const viewEstimate = () => {
    const { secondaryData } = state;
    props.navigate(`/edit-voy-relet/${secondaryData["estimate_id"]}`);
  };

  const viewVoyageManger = () => {
    const { secondaryData } = state;
    props.navigate(`/voyage-manager/${secondaryData["vm"]}`);
  };

  const toggleCargoRightMenu = (val, type = "save") => {
    const { formData } = state;

    const {
      /*id,*/ mix_qty,
      vc_purchase_id,
      booking_no,
      contract_type,
      my_company,
      lob,
      c_status,
      coa_vc_purchase_id,
      invoice_by,
      trade_area,
      cargo_name,
      min_inv_qty,
      min_inv_unit,
      voyage_no,
      cargo_group,
      bl_qty,
      bl_unit,
      stow_m3_mt,
      stow_ft3_mt,
      charterer,
      currency,
      load_disch_currency,
      cp_qty,
      cp_unit,
      freight_bill_via,
      load_dem,
      load_dep,
      min_qty,
      max_qty,
      freight_type,
      cp_date,
      cp_place,
      freight_rate,
      discharge_dem,
      discharge_dep,
      laycan_from,
      laycan_to,
      nominated_vessel,
      user_fixed_by,
      cp_draft_m,
      vessel_type,
    } = formData;

    let postFormData = Object.assign({
      id: 0,
      booking_no,
      contract_type: contract_type,
      my_company: my_company,
      lob: lob,
      cargo_status: c_status,
      vc_purchase_id: vc_purchase_id,

      invoice_by: invoice_by,
      trade_area: trade_area,
      cargo_name: cargo_name,
      min_inv_qty: min_inv_qty,
      min_inv_unit: min_inv_unit,
      voyage: voyage_no,
      cargo_group: cargo_group,
      bl_qty: bl_qty,
      bl_unit: bl_unit,
      m3_mt: stow_m3_mt,
      ft3_mt: stow_ft3_mt,
      charterer: charterer,
      currency: currency,
      load_disch_currency: load_disch_currency,
      cp_qty: cp_qty,
      cp_unit: cp_unit,
      freight_bill_via: freight_bill_via,
      load_dem: load_dem,
      load_dep: load_dep,
      mix_qty: mix_qty,
      max_qty: max_qty,
      freight_type: freight_type,
      cp_date: cp_date,
      cp_place: cp_place,
      freight_rate: freight_rate,
      discharge_dem: discharge_dem,
      discharge_dep: discharge_dep,
      laycan_from: laycan_from,
      laycan_to: laycan_to,
      nomiated_vessel: nominated_vessel,
      fixed_by: user_fixed_by,
      cp_draft_m: cp_draft_m,
      vessel_type: vessel_type,
    });

    if (val) {
      if (type == "edit") {
        if (
          formData &&
          formData.hasOwnProperty("cargo_contract_id") &&
          formData["cargo_contract_id"]
        ) {
          postFormData = { params: { id: formData["cargo_contract_id"] } };
          setState((prev) => ({ ...prev, isVisible: val, postFormData }));
        } else openNotification("Create Cargo", true, "edit");
      } else {
        if (
          formData &&
          formData.hasOwnProperty("id") &&
          formData["id"] > 0 &&
          !formData["cargo_contract_id"]
        )
          setState((prev) => ({ ...prev, isVisible: val, postFormData }));
        else if (
          formData &&
          formData.hasOwnProperty("cargo_contract_id") &&
          formData["cargo_contract_id"]
        )
          openNotification("Create Cargo", true);
        else openNotification("Create Cargo");
      }
    } else {
      setState((prev) => ({ ...prev, isVisible: val, postFormData: [] }));
    }
  };

  const isContactAttachmentOk = () => {
    setTimeout(() => {
      setState((prev) => ({ ...prev, visibleContactAttachment: false }));
    }, 3000);
  };

  let formData1 = redux_state.reduxStore?.["tcov_full_estimate_form"];

  let formData2 = redux_state.reduxStore?.["VOYAGE_RELET"];

  const isContactAttachmentCancel = () =>
    setState((prev) => ({ ...prev, visibleContactAttachment: false }));

  const importData = async (data) => {
    if (!data.hasOwnProperty("vc_purchase_id")) {
      openNotificationWithIcon(
        "info",
        "Please Save VC (Purchase) Contract First",
        3
      );
      return;
    }
    if (
      data &&
      data.hasOwnProperty("is_schedule") &&
      data["is_schedule"] == 1
    ) {
      openNotificationWithIcon(
        "info",
        "This Purchase Cargo has been already linked with voyage manager.",
        3
      );
      return;
    } else if (data.is_fixed !== 1) {
      openNotificationWithIcon(
        "info",
        "Please Change Status to Fix before import",
        3
      );
      return;
    }

    const voyage_estinmate_dwt = parseFloat(formData1?.dwt);
    const voyage_relate_dwt = parseFloat(formData2?.dwt);

    const cp_qty = parseFloat(state?.formData?.cp_qty);

    if (cp_qty > voyage_estinmate_dwt || cp_qty > voyage_relate_dwt) {
      openNotificationWithIcon(
        "info",
        "CP Quantity cannot exceed the Vessel's DWT",
        3
      );
    }
    if (props.isVoyageRelete) {
      // let obj = {
      //   cargo_name: data && data.cargo_name,
      //   charterer: data && data.charterer,
      //   f_rate: data && data.freight_rate,
      //   f_type: data && data.freight_type,
      //   curr: data && data.currency,
      // };
      //props.cargoImport(obj);

      data["index"] = props.formData.index;
      data["serial_no"] = props.formData.serial_no;

      await props.getCargo(data);
      await props.onCancelModal();
    } else {
      data["sp_type"] = "187";
      props.getCargo(data);
      //props.cargoImport(data)
    }
  };

  const fixedVCPurchaseCargoContract = (data) => {
    const vcPurchaseid = data["vc_purchase_id"]
    postAPICall(
      `${URL_WITH_VERSION}/voyagecargo/fix`,
      { cargo_contract_id: data["vc_purchase_id"] },
      "POST",
      (respData) => {
        if (respData.data) {
          openNotificationWithIcon("success", respData.message);
          // window.location.reload();
          if(vcPurchaseid){
             editformdata(vcPurchaseid)
          } 
          
        } else {
          let dataMessage = respData.message;
          let msg = "<div className='row'>";

          if (typeof dataMessage !== "string") {
            Object.keys(dataMessage).map(
              (i) =>
                (msg +=
                  "<div className='col-sm-12'>" + dataMessage[i] + "</div>")
            );
          } else {
            msg += dataMessage;
          }

          msg += "</div>";
          openNotificationWithIcon(
            "error",
            <div dangerouslySetInnerHTML={{ __html: msg }} />,
            5
          );
        }
      }
    );
  };
  const onClickExtraIcon = async (action, data) => {
    let delete_id = data && data.id;
    let groupKey = action["gKey"];
    let frm_code = "";
    if (groupKey == "--") {
      frm_code = "vcargo_in_contract_pricing";
    }
    if (groupKey == "Broker") {
      groupKey = "broker";
      frm_code = "vcargo_in_contract_pricing";
    }
    if (groupKey == "Extra Freight Term") {
      groupKey = "extrafreightterm";
      frm_code = "vcargo_in_contract_pricing";
    }
    if (groupKey == "Rev Exp Info") {
      groupKey = "revexpinfo";
      frm_code = "vcargo_in_contract_rev_exp";
    }
    if (groupKey == "Rebill Settings") {
      groupKey = "rebillsettings";
      frm_code = "vcargo_in_contract_rebill";
    }
    if (groupKey == "Itinerary Options") {
      groupKey = "itineraryoptions";
      frm_code = "vcargo_in_contract_itinerary_option_form";
    }
    if (groupKey == "Load Options") {
      groupKey = "loadoptions";
      frm_code = "vcargo_in_contract_form";
    }
    if (groupKey == "Discharge Options") {
      groupKey = "dischargeoptions";
      frm_code = "vcargo_in_contract_form";
    }
    if (groupKey && delete_id && Math.sign(delete_id) > 0 && frm_code) {
      let data1 = {
        id: delete_id,
        frm_code: frm_code,
        group_key: groupKey,
      };
      postAPICall(
        `${URL_WITH_VERSION}/tr-delete`,
        data1,
        "delete",
        (response) => {
          if (response && response.data) {
            openNotificationWithIcon("success", response.message);
          } else {
            openNotificationWithIcon("error", response.message);
          }
        }
      );
    }
  };

  const openCargoPurchaseReports = async (
    cargoPurchaseReport,
    cargoPurchaseID
  ) => {
    // for report Api
    try {
      const responseReport = await getAPICall(
        `${URL_WITH_VERSION}/voyagecargo/report?ae=${cargoPurchaseID}`
      );
      const respDataReport = await responseReport["data"];

      if (respDataReport) {
        setState((prevState) => ({
          ...prevState,
          reportFormData: respDataReport,
          isCargoPurchaseReport: cargoPurchaseReport,
        }));
      } else {
        openNotificationWithIcon("error", "Unable to show report", 5);
      }
    } catch (err) {
      openNotificationWithIcon("error", "Something went wrong.", 5);
    }
  };

  const performFix = async () => {
    try {
      const { formData } = state;

      let CargoPurchaseId = formData["vc_purchase_id"];
      // let tcCode = formData["tc_code"];
      let _url = `${URL_WITH_VERSION}/voyagecargo/fix`;
      let data = { cargo_contract_id: CargoPurchaseId }; // Fixed variable name from idRef.current to tcoId

      let response = await awaitPostAPICall(_url, data);
      let respData = response.data; // Access response data directly

      if (respData !== false) {
        openNotificationWithIcon("success", response.message);
        window.emitNotification({
          n_type: "TCO Fixed",
          msg: window.notificationMessageCorrector(
            `TCI is Fixed, for vessel(${formData.vessel_code}), by ${window.userName}`
          ),
        });

        // Uncomment the following block if modalCloseEvent is required
        // if (props.modalCloseEvent && typeof props.modalCloseEvent === "function") {
        //   props.modalCloseEvent();
        // }

        _onLeftSideListClick(CargoPurchaseId); // Fixed function parameter from idRef.current to tcoId
      } else {
        let dataMessage = response.message;
        let msg = "<div className='row'>";

        if (typeof dataMessage !== "string") {
          Object.keys(dataMessage).map(
            (i) =>
              (msg += "<div className='col-sm-12'>" + dataMessage[i] + "</div>")
          );
        } else {
          msg += dataMessage;
        }

        msg += "</div>";
        openNotificationWithIcon(
          "error",
          <div dangerouslySetInnerHTML={{ __html: msg }} />
        );
      }
    } catch (error) {
      console.error("Error in performFix:", error);
    }
  };

  const {
    loadComponent,
    isShowVcPurchageReports,
    reportFormData,
    title,
    visibleDrawer,
    frmName,
    coaVciId,
    formData,
    cargoContracts,
    frmVisible,
    showSideListBar,
    isSetsLeftBtn,
    isVisible,
    visibleContactAttachment,
    postFormData,
    selectedID,
    isCargoPurchaseReport,
  } = state;

  let isSetsLeftBtnArr = [],
    isSetsRightBtnArr = [];
  if (props.hasOwnProperty("isDisabled") && props.isDisabled) {
    isSetsLeftBtnArr = [
      {
        id: "7",
        key: "menu-fold",
        type: <MenuFoldOutlined />,
        withText: "List",
        showToolTip: true,
        event: "menu-fold",
      },
      // {
      //   id: "3",
      //   key: "save",
      //   type: <SaveOutlined />,
      //   withText: "Save",
      //   event: (key, data) => saveFormData(data),
      // },
      {
        id: "3",
        key: "save",
        type: <SaveOutlined />,
        withText: "Save 123",
        showToolTip: true,
        event: (key, data) => saveFormData(data),
      },
    ];
    isSetsRightBtnArr = [
    
    
      {
        key: "fix",
        isDropdown: 0,
        withText: "Fix",
        type: "",
        // showToolTip: true,
        menus: null,
        event: (key, data) => {
          data && data.hasOwnProperty("id") && data["id"] > 0
            ? Modal.confirm({
                title: "Confirm",
                content: "Are you sure, you want to Fix it?",
                onOk: () => performFix(),
              })
            : openNotificationWithIcon(
                "info",
                "Please Save VC (Purchase) Contract first, then click on fix."
              );
        },
      },
      
      {
        key: "report",
        isDropdown: 0,
        withText: "To view reports Of VC(Purchase)",
        type: "Reports",
        showToolTip: true,
        menus: null,
        event: (key, data) =>
          data && data.id > 0
            ? vcPurchageReports(true)
            : openNotificationWithIcon(
                "info",
                "Please select any item in the list!"
              ),
      },

      

    ];
  } else if (props.hasOwnProperty("isShowImport") && props.isShowImport) {
    isSetsRightBtnArr = [
      {
        key: "menu",
        isDropdown: 0,
        withText: "Menu",
        type: "",
      },

      ...(state?.formData?.is_fixed !== 1
        ? [
            {
              key: "fix",
              isDropdown: 0,
              withText: "Fix",
              type: "",
              // showToolTip: true,
              menus: null,
              event: (key, data) => {
                data && data.hasOwnProperty("id") && data["id"] > 0
                  ? Modal.confirm({
                      title: "Confirm",
                      content: "Are you sure, you want to Fix it?",
                      onOk: () => performFix(),
                    })
                  : openNotificationWithIcon(
                      "info",
                      "Please Save VC (Purchase) Contract first, then click on fix."
                    );
              },
            },
          ]
        : []),

      {
        key: "reports",
        isDropdown: 0,
        withText: "To view reports Of VC(Purchase)",
        type: "Reports",
        showToolTip: true,
        menus: null,
        event: (key, data) =>
          data && data.id > 0
            ? openCargoPurchaseReports(true, data.vc_purchase_id)
            : openNotificationWithIcon(
                "info",
                "Please Save VC (Purchase) Contract first."
              ),
      },

      {
        key: "import",
        isDropdown: 0,
        withText: "Import",
        type: "",
        menus: null,
        event: (key, data) => importData(data),
      },
    ];
    isSetsLeftBtnArr = [
      {
        id: "1",
        key: "menu-fold",
        type: <MenuFoldOutlined />,
        withText: "List",
        showToolTip: true,
        event: "menu-fold",
      },
      {
        id: "3",
        key: "save",
        type: <SaveOutlined />,
        withText: "Save",
        showToolTip: true,
        event: (key, data) => saveFormData(data),
      },
    ];
  } else if (
    formData.hasOwnProperty("is_schedule") &&
    formData["is_schedule"] === 1
  ) {
    isSetsLeftBtnArr = [
      {
        id: "7",
        key: "menu-fold",
        type: <MenuFoldOutlined />,
        withText: "List",
        showToolTip: true,
        event: "menu-fold",
      },
      {
        id: "1",
        key: "add",
        type: <PlusOutlined />,
        withText: "Add New",
        showToolTip: true,
        event: (key, data) => _onCreateFormData(),
      },

      {
        id: "3",
        key: "save",
        type: <SaveOutlined />,
        withText: "Save",
        showToolTip: true,
        event: (key, data) => saveFormData(data),
      },

      ...(state?.formData?.id !== 0 && state?.formData?.id !== undefined
        ? [
            {
              id: "4",
              key: "delete",
              type: <DeleteOutlined />,
              withText: "Delete",
              showToolTip: true,
              event: (key, data) => _onDeleteFormData(data),
            },
          ]
        : []),
      {
        id: "20",
        key: "refresh",
        type: <SyncOutlined />,
        withText: "Refresh",
        showToolTip: true,
        event: () => {
          reFreshForm();
        },
      },
    ];
    isSetsRightBtnArr = [
      { key: "menu", isDropdown: 0, withText: "Menu", type: "", menus: null },
      {
        key: "estimate",
        isDropdown: 0,
        withText: "Estimate",
        type: "",
        menus: null,
        event: (key) => viewEstimate(),
      },
      {
        key: "voyagemanger",
        isDropdown: 0,
        withText: "Voyage Manger",
        type: "",
        menus: null,
        event: (key) => viewVoyageManger(),
      },
      {
        key: "report",
        isDropdown: 0,
        withText: "To view reports Of VC(Purchase)",
        type: "Reports",
        showToolTip: true,
        menus: null,
        event: (key, data) =>
          data && data.id > 0
            ? vcPurchageReports(true)
            : openNotificationWithIcon(
                "info",
                "Please select any item in the list!"
              ),
      },
    ];
  } else {
    isSetsRightBtnArr = [
      {
        key: "contracts",
        isDropdown: 1,
        withText: "Contracts",
        type: "Contracts",
        showToolTip: true,
        menus: [
          {
            href: null,
            icon: null,
            label: "Go To COA(Cargo)",
            modalKey: "Go To COA(Cargo)",
            event: (key) => {
              navigate("/add-coa-contract");
            },
          },
          {
            href: null,
            icon: null,
            label: "Go To COA(Cargo) List",
            modalKey: "Go To COA(Cargo) List",
            event: (key) => {
              navigate("/coa-list");
            },
          },

          {
            href: null,
            icon: null,
            label: "Go To Cargo Contract",
            modalKey: "Go To Cargo Contract",
            event: (key) => {
              navigate("/add-cargo-contract");
            },
          },
          {
            href: null,
            icon: null,
            label: "Go To Cargo Contract List",
            modalKey: "Go To Cargo Contract List",
            event: (key) => {
              navigate("/cargo-contract-list");
            },
          },
          {
            href: null,
            icon: null,
            label: "Go To COA(VC)",
            modalKey: "Go To COA(VC)",
            event: (key) => {
              navigate("/add-coa-vci");
            },
          },
          {
            href: null,
            icon: null,
            label: "Go To COA(VC) List",
            modalKey: "Go To COA(VC) List",
            event: (key) => {
              navigate("/list-coa-vci");
            },
          },
        ],
      },
      {
        key: "cargo",
        isDropdown: 1,
        withText: "Click here to fill Cargo Contract",
        type: "Cargo Contract",
        showToolTip: true,
        menus: [
          {
            href: null,
            icon: null,
            label: "Create & Link Cargo Contract",
            modalKey: null,
            event: (key) => toggleCargoRightMenu(true),
          },
          {
            href: null,
            icon: null,
            label: "Edit Cargo Contract",
            modalKey: null,
            event: (key) => this.toggleCargoRightMenu(true, "edit"),
          },
        ],
      },
      formData &&
      formData.hasOwnProperty("is_fixed") &&
      formData["is_fixed"] === 0
        ? {
            key: "fix_cargo",
            isDropdown: 0,
            withText: "Click to fix the current cargo contract details",
            type: "Fix",
            showToolTip: true,
            menus: null,
            event: (key, data) =>
              data && data.hasOwnProperty("id") && data["id"] > 0
                ? Modal.confirm({
                    title: "Confirm",
                    content: "Are you sure, you want to Fix it?",
                    onOk: () => fixedVCPurchaseCargoContract(data),
                  })
                : openNotification("Fix"),
          }
        : undefined,
      formData &&
      formData.hasOwnProperty("is_fixed") &&
      formData["is_fixed"] === 0 &&
      formData["is_schedule"] === 0
        ? {
            key: "schedule_vayage",
            isDropdown: 0,
            withText: "Schedule Voyage",
            type: "",
            menus: null,
            event: (key, data) => {
              openNotificationWithIcon(
                "warning",
                "Please Create TCOV or Voy Relet or TCTO and Link these pages from voyage estimate"
              );
            },
          }
        : undefined,
      {
        key: "estimate",
        isDropdown: 0,
        withText: "Estimate",
        type: "Estimate",
        showToolTip: true,
        menus: null,
      },
      {
        key: "report",
        isDropdown: 0,
        withText: "To view reports Of VC(Purchase)",
        type: "Reports",
        showToolTip: true,
        menus: null,
        event: (key, data) =>
          data && data.id > 0
            ? vcPurchageReports(true)
            : openNotificationWithIcon(
                "info",
                "Please save VC (Purchase) first."
              ),
      },
    ];

    // isSetsLeftBtnArr = isSetsLeftBtn.filter(
    //   (item) =>
    //     !(
    //       formData &&
    //       formData.hasOwnProperty("id") &&
    //       formData.id <= 0 &&
    //       item.key === "delete"
    //     )
    // );

    isSetsLeftBtnArr = isSetsLeftBtn
      ? isSetsLeftBtn.filter(
          (item) =>
            !(
              formData &&
              formData.hasOwnProperty("id") &&
              formData.id <= 0 &&
              item.key === "delete"
            )
        )
      : [];
  }
  if (props.hasOwnProperty("import") && props.import) {
    isSetsRightBtnArr.push({
      key: "import",
      isDropdown: 0,
      withText: "Import",
      type: "",
      menus: null,
      event: (key, data) => importData(data),
    });
  }

  const formDataWithZeroDisabledField = { ...formData, disablefield: [] };
  return (
    <div className="tcov-wrapper full-wraps voyage-fix-form-wrap">
      <Layout className="layout-wrapper">
        <Layout>
          <Content className="content-wrapper">
            <Row gutter={16} style={{ marginRight: 0 }}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <div className="body-wrapper">
                  <div className="row">
                    <div className="col-md-12">
                      <article className="article">
                        <div className="">
                          <div className="box-body fieldscroll-wraps">
                            <Row>
                              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <article className="article">
                                  <div className="box box-default">
                                    <div
                                      className="box-body"
                                      key={refreshCounter}
                                    >
                                      {frmName && frmVisible ? (
                                        <NormalFormIndex
                                          key={"key_" + frmName + "_0"}
                                          formClass="label-min-height"
                                          formData={
                                            formDataWithZeroDisabledField
                                          }
                                          showForm={true}
                                          frmCode={frmName}
                                          inlineLayout={true}
                                          showButtons={
                                            [
                                              // { "id": "cancel", "title": "Reset", "type": "danger" },
                                              // { "id": "save", "title": "Save", "type": "primary", "event": (data, innerCB) => { this.saveFormData(data, innerCB) } }
                                            ]
                                          }
                                          showToolbar={[
                                            {
                                              // leftWidth: 8,
                                              // rightWidth: 16,
                                              isLeftBtn: [
                                                { isSets: isSetsLeftBtnArr },
                                              ],
                                              isRightBtn: [
                                                {
                                                  key: "s2",
                                                  isSets: isSetsRightBtnArr,
                                                },
                                              ],
                                              isResetOption: false,
                                            },
                                          ]}
                                          sideList={{
                                            selectedID: selectedID,
                                            showList: true,
                                            title: "VC (Purch.)- List",
                                            uri: "/voyagecargo/list?l=0",
                                            columns: [
                                              "vc_purchase_id",
                                              "charterer_name",
                                              "status_name",
                                            ],
                                            icon: true,
                                            rowClickEvent: (evt) =>
                                              _onLeftSideListClick(
                                                evt.vc_purchase_id
                                              ),
                                            statusList: "",
                                          }}
                                          showSideListBar={showSideListBar}
                                          isShowFixedColumn={[
                                            "Broker",
                                            "Load Options",
                                            "Discharge Options",
                                            "Itinerary Options",
                                            "Rev/Exp Info",
                                          ]}
                                          tableRowDeleteAction={(
                                            action,
                                            data
                                          ) => onClickExtraIcon(action, data)}

                                          // staticTabs={{
                                          //   "Dem/Des Term": () => { return <DemdesTerm /> }

                                          // }}
                                        />
                                      ) : (
                                        <div className="col col-lg-12">
                                          <Spin tip="Loading...">
                                            <Alert
                                              message=" "
                                              description="Please wait..."
                                              type="info"
                                            />
                                          </Spin>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </article>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </article>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Content>
        </Layout>

        <RightBarUI
          pageTitle="voyage-cargo-righttoolbar"
          callback={(data, options) => onClickRightMenu(data, options)}
        />
        {loadComponent !== undefined &&
        title !== undefined &&
        visibleDrawer === true ? (
          <Drawer
            title={state.title}
            placement="right"
            closable={true}
            onClose={onCloseDrawer}
            visible={state.visibleDrawer}
            getContainer={false}
            style={{ position: "absolute" }}
            width={state.width}
            maskClosable={false}
            className="drawer-wrapper-container"
          >
            <div className="tcov-wrapper">
              <div className="layout-wrapper scrollHeight">
                <div className="content-wrapper noHeight">
                  {state.loadComponent}
                </div>
              </div>
            </div>
          </Drawer>
        ) : undefined}

        <Modal
          title="Copy Setup"
          visible={state.isVisibleCopy}
          width={1200}
          onCancel={onCloseModal}
          footer={false}
        >
          <article className="article">
            <div className="box box-default">
              <div className="box-body">
                <Form className="m-b-18">
                  <Row gutter={16}>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                      <FormItem {...formItemLayout} label="No. Of Copies">
                        <Input
                          size="default"
                          placeholder="No. Of Copies"
                          defaultValue="0"
                        />
                      </FormItem>
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                      <FormItem {...formItemLayout} label="Total Period">
                        <Input
                          size="default"
                          placeholder="Total Period"
                          defaultValue="0"
                        />
                      </FormItem>
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                      <FormItem {...formItemLayout} label="Start">
                        <Input size="default" placeholder="" defaultValue="0" />
                      </FormItem>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Checkbox>Keep Nominated Vessel</Checkbox>
                      <Checkbox>Keep Booking No.</Checkbox>
                      <Button type="primary">Create</Button>
                    </Col>
                  </Row>
                </Form>
                <Table
                  columns={columns10}
                  dataSource={data10}
                  pagination={false}
                  bordered
                  footer={() => (
                    <div className="text-center">
                      <Button type="link">Add New</Button>
                    </div>
                  )}
                />
              </div>
            </div>
          </article>
        </Modal>

        {isCargoPurchaseReport ? (
          <Modal
            title="Cargo Purchase Report"
            open={state.isCargoPurchaseReport}
            width={"95%"}
            // onCancel={onCloseModal}
            onCancel={() =>
              setState({ ...state, isCargoPurchaseReport: false })
            }
            footer={false}
          >
            <CargoPurchaseReport data={reportFormData} />
          </Modal>
        ) : undefined}
        {isVisible === true ? (
          <Modal
            title={
              postFormData && postFormData.hasOwnProperty("params")
                ? "Edit Cargo Contract"
                : "Create Cargo Contract"
            }
            visible={isVisible}
            width="95%"
            onCancel={() => toggleCargoRightMenu(false)}
            style={{ top: "10px" }}
            bodyStyle={{ height: 790, overflowY: "auto" }}
            footer={null}
          >
            <div className="body-wrapper">
              <article className="article">
                <div className="box box-default">
                  {postFormData && postFormData.hasOwnProperty("params") ? (
                    <CargoContract
                      history={props.history}
                      match={postFormData}
                      isDisabled={true}
                      modalCloseEvent={() => toggleCargoRightMenu(false)}
                    />
                  ) : (
                    <CargoContract
                      showSideListBar={false}
                      history={props.history}
                      formData={postFormData}
                      isDisabled={true}
                      modalCloseEvent={() => toggleCargoRightMenu(false)}
                    />
                  )}
                </div>
              </article>
            </div>
          </Modal>
        ) : undefined}
        {visibleContactAttachment ? (
          <Modal
            visible={visibleContactAttachment}
            title="Upload Attachment ( Upload Contact Details )"
            onOk={isContactAttachmentOk}
            onCancel={isContactAttachmentCancel}
            footer={null}
            width={1000}
            maskClosable={false}
          >
            <Attachment
              uploadType="Address Book"
              // directory={formData['estimate_id']}
              // onCloseUploadFileArray={fileArr => this.uploadedFiles(fileArr)}
            />
          </Modal>
        ) : undefined}
      </Layout>

      {isShowVcPurchageReports ? (
        <Modal
          style={{ top: "2%" }}
          title="Reports"
          visible={isShowVcPurchageReports}
          // onOk={handleOk}
          onCancel={() =>
            setState((prev) => ({ ...prev, isShowVcPurchageReports: false }))
          }
          width="95%"
          footer={null}
        >
          <VcPurchageReports data={reportFormData} />
        </Modal>
      ) : undefined}
    </div>
  );
};

export default CargoDetails;
