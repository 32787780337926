import React, { useState } from "react";
import { ClockCircleOutlined, CloseOutlined } from "@ant-design/icons";

const colorArr = [
  "#81BFDA", "#E195AB", "#A8CD89", "#659287", "#8B5DFF", "#4335A7",
  "#D76C82", "#F3C623", "#384B70", "#229799", "#C683D7", "#36BA98",
  "#E88D67", "#8E3E63", "#874CCC", "#D6589F", "#5F374B", "#EE4266",
  "#219C90", "#3D3B40", "#527853",
];

const AiAgent = ({ data }) => {
  const [notifications, setNotifications] = useState(data);
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleRemoveNotification = (index) => {
    setNotifications(notifications.filter((_, i) => i !== index));
  };

  return (
    <div style={{ cursor: "pointer" }}>
      {Array.isArray(notifications) && notifications.length > 0 ? (
        notifications.map((notification, index) => (
          <div
            key={index}
            style={{
              color: "black",
              padding: "10px",
              borderRadius: "5px",
              backgroundColor:
                hoveredIndex === index ? colorArr[index % colorArr.length] : "white",
              transition: "background-color 0.3s ease, color 0.3s ease",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            onMouseEnter={() => setHoveredIndex(index)}
            onMouseLeave={() => setHoveredIndex(null)}
          >
            <div style={{ flex: 1 }}>
              {/* <h4 style={{ margin: "0 0 5px 0" }}>{notification.title}</h4> */}
              <p style={{ margin: "0" }}>{notification.message}</p>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              {/* <p style={{ marginRight: "10px", whiteSpace: "nowrap" }}>
                <ClockCircleOutlined style={{ marginRight: "5px" }} />
                {notification.time}
              </p> */}
              <CloseOutlined
                style={{
                  cursor: "pointer",
                  color: "red",
                  fontSize: "14px",
                }}
                onClick={() => handleRemoveNotification(index)}
              />
            </div>
          </div>
        ))
      ) : (
        <p>No notifications available.</p>
      )}
    </div>
  );
};

export default AiAgent;
