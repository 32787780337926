import React, { useState, useEffect } from "react";
import AiLoader from "../../../../shared/components/PL/tcov/AiLoader";
import "./AIVoyage.scss";
import { openNotificationWithIcon, postAPICall } from "../../../../shared";

const AiVoyagePlanner = ({
  cargoData,
  getCargoFromAI,
  liveVesselPosition,
  marineData,
}) => {
  const [loading, setLoading] = useState(true);
  const [vesselData, setVesselData] = useState({});
  const [displayData, setDisplayData] = useState("");
  const [idx, setIdx] = useState(0);
  const [show, setShow] = useState(false);

  const menuItems = [
    "AI Optimized Route",
    "AI Dynamic Rate Forecasting",
    "AI Speed Optimization",
    "AI Bunker Market Insights",
    "AI Voyage Report Insights",
  ];

  useEffect(() => {
    if (marineData && marineData.MMSI !== null && marineData.SHIP_ID !== null) {
      getLivePosition();
    } else{
      openNotificationWithIcon('info', "Please Select a Vessel first.")
      setLoading(false)
        return
    }
  }, [marineData?.MMSI, marineData?.SHIP_ID]);

     const getLivePosition = async () => {
        const token = localStorage.getItem("oceanToken");

      const { SHIPNAME, MMSI, SHIP_ID ,IMO } = marineData

        const urlVesselPosition = `${process.env.REACT_APP_MT_URL}marine/vessel-position-imo/${MMSI}/${SHIP_ID}/${SHIPNAME}/${IMO}`;
    
        try {
          const vesselPositionResponse = await fetch(urlVesselPosition, {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: token,
            },
          });
    
          const vesselPositionDataFetched = await vesselPositionResponse.json();
          if (vesselPositionDataFetched) {
            fetchData(vesselPositionDataFetched?.data)
          }
          console.log("DEMO------->>>>>>>>>",vesselPositionDataFetched)
          

        } catch (error) {
          console.error("Failed to fetch vessel position data:", error);
        } 
      };

  const fetchData = async (payload) => {
    setLoading(true);
    try {
      const aiPayLoad = {
        vessel_info:payload
          // liveVesselPosition?.vessel_position !== undefined
          //   ? liveVesselPosition?.vessel_position
          //   : marineData,
      };

      const url = `${process.env.REACT_APP_URL}v1/recommendation_bp/route_prediction`;
      const response = await postAPICall(
        url,
        { ...aiPayLoad, key: menuItems },
        "POST"
      );
      const data = response?.route_prediction || {};
      setVesselData(data);
      setDisplayData(data?.forecasting);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSelection = (key) => {
    if (!vesselData) return;
    switch (key) {
      case 0:
        setDisplayData(vesselData.route || "No forecasting data available.");
        break;
      case 1:
        setDisplayData(
          vesselData.forecasting || "No market insights data available."
        );
        break;
      case 2:
        setDisplayData(
          vesselData.optimization || "No optimization data available."
        );
        break;
      case 3:
        setDisplayData(
          vesselData.market_insights || "No report insights data available."
        );
        break;
      case 4:
        setDisplayData(
          vesselData.report_insights || "No route data available."
        );
        break;
      default:
        setDisplayData("Invalid selection.");
        break;
    }
  };

  // useEffect(() => {
  //   fetchData();
  // }, [cargoData]);

  useEffect(() => {
    handleSelection(idx);
    if (vesselData) {
      // setDisplayData(vesselData.forecasting);
      setShow(true);
    }
  }, [idx, vesselData]);

  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            marginTop: "20px",
            height: "500px",
            justifyContent: "center",
          }}
        >
          <AiLoader
            text={
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  height: "100%",
                }}
              >
                <p style={{ fontSize: "27px" }}>
                  {show ? "AI Analizing" : "Loading..."}
                </p>
                {!show && <p style={{ fontSize: "20px" }}>AI Voyage Planner</p>}
              </div>
            }
          />
        </div>
      ) : (
        <div>
          <div className="menu-bar">
            {menuItems.map((item, index) => (
              <div
                key={index}
                className={`menu-item ${idx === index ? "ai-active" : ""}`}
                onClick={() => {
                  setShow(true);
                  setIdx(index);
                }}
              >
                {item}
              </div>
            ))}
          </div>

          {show && (
            <div className="mapbox-gl-container">
              <div className="info-box">
                <h2>{menuItems[idx]}</h2>
                <p>{displayData}</p>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default AiVoyagePlanner;
