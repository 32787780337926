import { useState, forwardRef } from "react";
import { PrinterOutlined } from "@ant-design/icons";
import ReactToPrint from "react-to-print";
import jsPDF from "jspdf";
import moment from "moment";

import html2canvas from "html2canvas";
import { useRef } from "react";

const ComponentToPrint = forwardRef((props, ref) => {
  const [formReportdata, setFormReportdata] = useState(
    Object.assign({}, props.data || {})
  );

  return (
    <article className="article toolbaruiWrapper" ref={ref}>
      <div className="box box-default" id="divToPrint">
        <div className="box-body">
          <table className="table table-bordered table-invoice-report-colum">
            <tbody>
              <tr>
                <td className="font-weight-bold">Vessel Name:</td>
                <td>
                  {formReportdata && formReportdata.vessel_name
                    ? formReportdata.vessel_name
                    : "N/A"}
                </td>

                <td className="font-weight-bold">My Company/LOB:</td>
                <td>
                  {formReportdata && formReportdata.my_company_name
                    ? formReportdata.my_company_name
                    : "N/A"}
                </td>

                <td className="font-weight-bold">Status:</td>
                <td>
                  {formReportdata && formReportdata.v_status_name
                    ? formReportdata.v_status_name
                    : "N/A"}
                </td>
              </tr>

              <tr>
                <td className="font-weight-bold">Head Fixture Code:</td>
                <td>
                  {formReportdata && formReportdata.head_fix_code
                    ? formReportdata.head_fix_code
                    : "N/A"}
                </td>

                <td className="font-weight-bold">Fixed By User:</td>
                <td>
                  {formReportdata && formReportdata.fixed_by
                    ? formReportdata.fixed_by
                    : "N/A"}
                </td>

                <td className="font-weight-bold">Commence date:</td>
                <td>
                  {formReportdata && formReportdata.commence
                    ? moment(formReportdata.commence).format("YYYY-MM-DD HH:mm")
                    : "YYYY-MM-DD HH:MM:SS"}
                </td>
              </tr>

              <tr>
                <td className="font-weight-bold">Reg./Desp. Owner:</td>
                <td>
                  {formReportdata && formReportdata.owner_name
                    ? formReportdata.owner_name
                    : "N/A"}
                </td>

                <td className="font-weight-bold">Contract Type:</td>
                <td>
                  {formReportdata && formReportdata.con_type_name
                    ? formReportdata.con_type_name
                    : "N/A"}
                </td>

                <td className="font-weight-bold">Release date:</td>
                <td>
                  {formReportdata &&
                  formReportdata.release_date &&
                  formReportdata.release_date !== "0000-00-00 00:00:00"
                    ? moment(formReportdata.release_date).format(
                        "YYYY-MM-DD HH:mm"
                      )
                    : "YYYY-MM-DD HH:MM:SS"}
                </td>
              </tr>

              <tr>
                <td className="font-weight-bold">Beneficiary:</td>
                <td>
                  {formReportdata && formReportdata.beneficiary_name
                    ? formReportdata.beneficiary_name
                    : "N/A"}
                </td>

                <td className="font-weight-bold">Config Date:</td>
                <td>
                  {formReportdata &&
                  formReportdata.config_date &&
                  formReportdata.config_date !== "0000-00-00 00:00:00"
                    ? moment(formReportdata.config_date).format(
                        "YYYY-MM-DD HH:mm"
                      )
                    : "0000-00-00 00:00:00"}
                </td>

                <td className="font-weight-bold">Ownership type:</td>
                <td>
                  {formReportdata && formReportdata.ownership_type_name
                    ? formReportdata.ownership_type_name
                    : "N/A"}
                </td>
              </tr>
            </tbody>
          </table>

          <h4 className="font-weight-bold tc-sub-header">OFF Hire</h4>

          <table className="table table-bordered table-invoice-report-colum">
            <thead>
              <tr className="HeaderBoxText">
                <th>Voyage No</th>
                <th>P&L Status</th>
                <th>Activity</th>
                <th>Reason</th>
                <th>From Date Time</th>
                <th>To Date Time</th>
                <th>Hours</th>
                <th>Days</th>
                <th>Miles</th>
                <th>Hire Rate</th>
                {/* <th>IFO QTY</th>
                <th>IFO Value</th>
                <th>VLSFO QTY</th>
                <th>VLSFO QTY</th>
                <th>ULSFO Qty</th>
                <th>ULSFO Value</th>
                <th>LSMGO QTY</th>
                <th>LSMGO Value</th>
                <th>MGO Qty</th>
                <th>MGO Value</th>
                <th>Net Bunker Amount</th>
                <th>Time Lose Value</th>
                <th>Final Amount</th> */}
              </tr>
            </thead>

            <tbody>
              {formReportdata?.offhire?.length > 0 &&
                formReportdata?.offhire.map((e, idx) => (
                  <tr key={idx}>
                    <td>{e.voyage_no ?? ""}</td>
                    <td>{e.status ?? "0"}</td>
                    <td>{e.activity_name ?? ""}</td>
                    <td>{e.reason_name ?? ""}</td>
                    <td>{e.delay_from ?? ""}</td>
                    <td>{e.delay_to ?? ""}</td>
                    <td>{e.hours ?? "0.00"}</td>
                    <td>{e.days ?? "0"}</td>
                    <td>{e.miles ?? "0.00"}</td>
                    <td>{e.hire_rate ?? "0.00"}</td>
                    {/* <td>{e.ifo_qty ?? "0.00"}</td>
                    <td>{e.ifo_value ?? "0.00"}</td>
                    <td>{e.vlsfo_qty ?? "0.00"}</td>
                    <td>{e.vlsfo_value ?? "0"}</td>
                    <td>{e.ulsfo_qty ?? ""}</td>
                    <td>{e.ulsfo_value ?? "0.00"}</td>
                    <td>{e.lsmgo_qty ?? "0.00"}</td>
                    <td>{e.lsmgo_value ?? "0.00"}</td>
                    <td>{e.mgo_qty ?? "0.00"}</td>
                    <td>{e.mgo_value ?? "0"}</td>
                    <td>{e.net_bunker_amt ?? ""}</td>
                    <td>{e.time_loss_value ?? "0.00"}</td>
                    <td>{e.final_amt ?? "0.00"}</td> */}
                  </tr>
                ))}
            </tbody>
          </table>
          <table className="table table-bordered table-invoice-report-colum">
            <thead>
              <tr className="HeaderBoxText">
                {/* <th>Voyage No</th>
                <th>P&L Status</th>
                <th>Activity</th>
                <th>Reason</th>
                <th>From Date Time</th>
                <th>To Date Time</th>
                <th>Hours</th>
                <th>Days</th>
                <th>Miles</th>
                <th>Hire Rate</th> */}
                <th>IFO QTY</th>
                <th>IFO Value</th>
                <th>VLSFO QTY</th>
                <th>VLSFO Value</th>
                <th>ULSFO Qty</th>
                <th>ULSFO Value</th>
                <th>LSMGO QTY</th>
                <th>LSMGO Value</th>
                <th>MGO Qty</th>
                <th>MGO Value</th>
                <th>Net Bunker Amount</th>
                <th>Time Loss Value</th>
                <th>Final Amount</th>
              </tr>
            </thead>

            <tbody>
              {formReportdata?.offhire?.length > 0 &&
                formReportdata?.offhire.map((e, idx) => (
                  <tr key={idx}>
                    {/* <td>{e.vessel_code ?? ""}</td>
                    <td>{e.status ?? "0"}</td>
                    <td>{e.activity_name ?? ""}</td>
                    <td>{e.reason_name ?? ""}</td>
                    <td>{e.delay_from ?? ""}</td>
                    <td>{e.delay_to ?? ""}</td>
                    <td>{e.hours ?? "0.00"}</td>
                    <td>{e.days ?? "0"}</td>
                    <td>{e.miles ?? "0.00"}</td>
                    <td>{e.hire_rate ?? "0.00"}</td> */}
                    <td>{e.ifo_qty ?? "0.00"}</td>
                    <td>{e.ifo_value ?? "0.00"}</td>
                    <td>{e.vlsfo_qty ?? "0.00"}</td>
                    <td>{e.vlsfo_value ?? "0"}</td>
                    <td>{e.ulsfo_qty ?? ""}</td>
                    <td>{e.ulsfo_value ?? "0.00"}</td>
                    <td>{e.lsmgo_qty ?? "0.00"}</td>
                    <td>{e.lsmgo_value ?? "0.00"}</td>
                    <td>{e.mgo_qty ?? "0.00"}</td>
                    <td>{e.mgo_value ?? "0"}</td>
                    <td>{e.net_bunker_amt ?? ""}</td>
                    <td>{e.time_loss_value ?? "0.00"}</td>
                    <td>{e.final_amt ?? "0.00"}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </article>
  );
});

const VesselFileReport = (props) => {
  const [name, setName] = useState("Printer");
  const componentRef = useRef();

  const printReceipt = () => {
    window.print();
  };

  const printDocument = () => {
    // in this method report is downloading, but logo is not coming showing err of loading image of s3.
    html2canvas(document.getElementById("divToPrint"), {
      logging: true,
      letterRendering: 1,
      useCORS: true,
      allowTaint: true,
    }).then(function (canvas) {
      // download image
      const link = document.createElement("a");
      link.download = "html-to-img.png";
      const pdf = new jsPDF();
      const imgProps = pdf.getImageProperties(canvas);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(canvas, "PNG", 0, 20, pdfWidth, pdfHeight);
      pdf.save("vesselfileReport.pdf");
    }).catch = (err) => {
      console.log(err);
    };
  };

  return (
    <div className="body-wrapper modalWrapper">
      <article className="article toolbaruiWrapper">
        <div className="box box-default">
          <div className="box-body">
            <div className="toolbar-ui-wrapper">
              <div className="leftsection" />
              <div className="rightsection">
                <span className="wrap-bar-menu">
                  <ul className="wrap-bar-ul">
                    <li onClick={printDocument}>Download</li>

                    <li>
                      <ReactToPrint
                        trigger={() => (
                          <span className="text-bt">
                            <PrinterOutlined />
                            Print
                          </span>
                        )}
                        content={() => componentRef.current}
                      />
                    </li>
                  </ul>
                </span>
              </div>
            </div>
          </div>
        </div>
      </article>

      <article className="article">
        <div className="box box-default">
          <div className="box-body">
            <ComponentToPrint ref={componentRef} data={props.data} />
          </div>
        </div>
      </article>
    </div>
  );
};

export default VesselFileReport;
