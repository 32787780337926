import React, { useEffect, useState } from "react";
import URL_WITH_VERSION, {
  apiDeleteCall,
  getAPICall,
  objectToQueryStringFunc,
  openNotificationWithIcon,
  useStateCallback,
} from "../../shared";
import { Input, Modal, Popconfirm, Table } from "antd";
import { DeleteOutlined, EnvironmentOutlined } from "@ant-design/icons";
import ToolbarUI from "../../components/CommonToolbarUI/toolbar_index";
import { Icon } from "@iconify-icon/react/dist/iconify.js";
import VesselHistory from "./VesselHistory";

export default function TrackMyFleetTable(props) {

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentVesseldata, setCurrentVesselData]= useState({})

  const showModal = (el, record) => {
    setCurrentVesselData(record)
    
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const tableAction = {
    title: "Action",
    key: "action",
    fixed: "right",
    width: 50,

    render: (el, record) => {
      return (
        <div className="editable-row-operations">
          <span className="iconWrapper cancel">
            <Popconfirm
              title="Are you sure, you want to delete it?"
              onConfirm={() => onRowDeletedClick(record.SHIP_ID)}
            >
              <DeleteOutlined />
            </Popconfirm>
          </span>
          {/* <span className="iconWrapper cancel">
            <Popconfirm
              title="Are you sure, you want to delete it?"
              onConfirm={() => onRowDeletedClick(record.id)}
            >
             
            </Popconfirm>
            <DeleteOutlined onClick={() => onRowDeletedClick(record.SHIP_ID)} />
          </span> */}
        </div>
      );
    },
  };
  let tableHeaders = [
    {
      title: "Vessel Name",
      dataIndex: "SHIPNAME",
      key: "vessel_name",
      ellipsis: "true",
      width: "200px",
    },
    {
      title: "Ship Type",
      dataIndex: "SHIPTYPE",
      key: "SHIPTYPE",
      ellipsis: "true",
      width: "200px",
    },
    {
      title: "IMO No",
      dataIndex: "IMO",
      key: "imo_no",
      ellipsis: "true",
      width: "100px",
    },
    {
      title: "Vessel Last Port.",
      dataIndex: "LAST_PORT",
      key: "LAST_PORT",
      ellipsis: "true",
      width: "200px",
    },
    {
      title: "Status",
      dataIndex: "STATUS",
      key: "STATUS",
      ellipsis: "true",
      width: "200px",
    },
    {
      title: "Current Port Name",
      dataIndex: "CURRENT_PORT",
      key: "CURRENT_PORT",
      ellipsis: "true",
      width: "200px",
    },
    {
      title: "Speed",
      dataIndex: "SPEED",
      key: "speed",
      ellipsis: "true",
      width: "200px",
    },
    {
      title: "Last pos. Latitude",
      dataIndex: "LAT",
      key: "last_pos_lat",
      ellipsis: "true",
      width: "200px",
    },
    {
      title: "Last Pos Longitude",
      dataIndex: "LON",
      key: "last_pos_lon",
      ellipsis: "true",
      width: "200px",
    },
    {
      title: "Degree",
      dataIndex: "ROT",
      key: "ROT",
      ellipsis: "true",
      width: "100px",
    },
    {
      title: "Vessel History",
      width: "100px",
      fixed: "right",
      align: "center",
      render: (el, record) => {
        return (
          <div>
            <span>
              <Icon
                icon="bx:history"
                style={{
                  fontSize: "25px",
                  height: "19px",
                  alignSelf: "center",
                  color: "red",
                  cursor: "pointer",
                }}
                onClick={()=>showModal(el,record)}
              />
            </span>
          </div>
        );
      },
    },
    {
      title: "Live On Map",
      width: "100px",
      fixed: "right",
      align: "center",
      render: (data) => {
        let icon = (
          <EnvironmentOutlined
            onClick={() => trackVessel(data)}
            style={{
              fontSize: "25px",
              color: "red",
              alignSelf: "center",
              height: "19px",
            }}
          />
        );
        return data.LAT && data.LON && data.LAT != "None" && data.LON != "None"
          ? icon
          : null;
      },
    },
  ];
  tableHeaders.push(tableAction);

  const [trackMyfleetState, setTrackMyfleetState] = useStateCallback({
    data: [],
    mapDataArr: [],
    pageOptions: { pageIndex: 1, pageLimit: 10, totalRows: 0 },
    columns: tableHeaders,
    mapData: null,
    loading: false,
    filterVessels: [],
    isAdd: true,
    isVisible: false,
    formDataValues: {},
    localvesselList: [],
    isVisibleDetails: false,
    formDetailsValues: {},
    visibleLiveVessel: false,
    isLiveDetailLoading: false,
    typesearch: {},
    donloadArray: [],
    isShowLocationFromtable: false,
    showvesseldetailfromtable: {},
    filterData: [],
  });

  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    getTableData();
  }, [props.isInMyFleet]);

  const trackVessel = async (dataset) => {
    try {
      let url = `${
        process.env.REACT_APP_MT_URL
      }marine/get-vessels-name/${dataset.SHIPNAME.replace(" ", "%20")}`;
      const response = await getAPICall(url);

      setTrackMyfleetState(
        (prev) => ({
          ...prev,
          showvesseldetailfromtable: dataset,
          isShowLocationFromtable: true,
        }),
        () => {
          props.showlocation(true);
          props.searchedVessel(response[0]);
          props.scrollToMap();
        }
      );
      props.alltabledata(trackMyfleetState.data, [dataset]);
    } catch (err) {
      console.log("err", err);
    }
  };

  const getlocalvessellist = () => {
    // from here we will get the all vessel list created by us manually.
    setTrackMyfleetState((prev) => ({
      ...prev,
      loading: true,
    }));
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    try {
      fetch(
        `http:${process.env.REACT_APP_URL}v1/` + "vessel/live/list?p=" + 0,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          setTrackMyfleetState((prev) => ({
            ...prev,
            localvesselList: result.data,
          }));
        })
        .catch((error) => undefined);
    } catch (err) {
      openNotificationWithIcon("error", "Something went wrong", 5);
    }

    setTrackMyfleetState((prev) => ({ ...prev, loading: false }));
  };

  const getTableData = async (searchtype = {}) => {
    const { pageOptions } = trackMyfleetState;
    let qParams = { p: pageOptions.pageIndex, l: pageOptions.pageLimit };
    let headers = {};
    let search =
      searchtype &&
      searchtype.hasOwnProperty("searchOptions") &&
      searchtype.hasOwnProperty("searchValue")
        ? searchtype
        : trackMyfleetState.typesearch;

    if (
      search &&
      search.hasOwnProperty("searchValue") &&
      search.hasOwnProperty("searchOptions") &&
      search["searchOptions"] !== "" &&
      search["searchValue"] !== ""
    ) {
      let wc = {};
      search["searchValue"] = search["searchValue"].trim();
      if (search["searchOptions"].indexOf(";") > 0) {
        let so = search["searchOptions"].split(";");
        wc = { OR: {} };
        so.map((e) => (wc["OR"][e] = { l: search["searchValue"] }));
      } else {
        wc[search["searchOptions"]] = { l: search["searchValue"] };
      }

      headers["where"] = wc;
      trackMyfleetState.typesearch = {
        searchOptions: search.searchOptions,
        searchValue: search.searchValue,
      };
    }

    setTrackMyfleetState((prev) => ({
      ...prev,
      loading: true,
      data: [],
    }));

    let qParamString = objectToQueryStringFunc(qParams);

    let _url = `${process.env.REACT_APP_MT_URL}marine/get-vessel-position-my-fleet?v=9&timespan=1440&limit=2000`;
    const response = await getAPICall(_url, headers);
    const data = await response;
    const totalRows = data && data.total_rows ? data.total_rows : 0;
    let dataArr = data && data.data ? data.data : [];
    let state = { loading: false };
    let donloadArr = [];

    props.alltabledata(dataArr);
    if (dataArr.length > 0) {
      dataArr.forEach((d) => donloadArr.push(d["id"]));
      state["data"] = dataArr;
    }
    props.showMyFleetOnMap(state.data);
    setTrackMyfleetState((prev) => ({
      ...prev,
      ...state,
      donloadArray: donloadArr,
      filterData: dataArr,
      pageOptions: {
        pageIndex: pageOptions.pageIndex,
        pageLimit: pageOptions.pageLimit,
        totalRows: totalRows,
      },
      loading: false,
    }));
  };

  const callOptions = (evt) => {
    if (
      evt.hasOwnProperty("searchOptions") &&
      evt.hasOwnProperty("searchValue")
    ) {
      let pageOptions = trackMyfleetState.pageOptions;
      let search = {
        searchOptions: evt["searchOptions"],
        searchValue: evt["searchValue"],
      };
      pageOptions["pageIndex"] = 1;

      setTrackMyfleetState(
        (prev) => ({
          ...prev,
          search: trackMyfleetState.search,
          pageOptions: trackMyfleetState.pageOptions,
        }),
        () => {
          getTableData(search);
        }
      );
    } else if (
      evt &&
      evt.hasOwnProperty("actionName") &&
      evt["actionName"] === "reset-serach"
    ) {
      let pageOptions = trackMyfleetState.pageOptions;
      pageOptions["pageIndex"] = 1;
      setTrackMyfleetState(
        (prev) => ({
          ...prev,
          search: {},
          pageOptions: trackMyfleetState.pageOptions,
          typesearch: {},
        }),
        () => {
          getTableData();
        }
      );
    } else if (
      evt &&
      evt.hasOwnProperty("actionName") &&
      evt["actionName"] === "column-filter"
    ) {
      // column filtering show/hide
      let responseData = trackMyfleetState.data;
      let columns = Object.assign([], trackMyfleetState.columns);

      if (responseData.length > 0) {
        for (var k in responseData[0]) {
          let index = columns.some(
            (item) =>
              (item.hasOwnProperty("dataIndex") && item.dataIndex === k) ||
              (item.hasOwnProperty("key") && item.key === k)
          );
          if (!index) {
            let title = k
              .split("_")
              .map((snip) => {
                return snip[0].toUpperCase() + snip.substring(1);
              })
              .join(" ");
            let col = Object.assign(
              {},
              {
                title: title,
                dataIndex: k,
                key: k,
                invisible: "true",
                isReset: true,
              }
            );
            columns.splice(columns.length - 1, 0, col);
          }
        }
      }
      setTrackMyfleetState((prev) => ({
        ...prev,
        sidebarVisible: evt.hasOwnProperty("sidebarVisible")
          ? evt.sidebarVisible
          : !trackMyfleetState.sidebarVisible,
        columns: evt.hasOwnProperty("columns")
          ? evt.columns
          : trackMyfleetState.columns,
      }));
    } else {
      let pageOptions = trackMyfleetState.pageOptions;
      pageOptions[evt["actionName"]] = evt["actionVal"];

      if (evt["actionName"] === "pageLimit") {
        pageOptions["pageIndex"] = 1;
      }

      setTrackMyfleetState(
        (prev) => ({ ...prev, pageOptions: trackMyfleetState.pageOptions }),
        () => {
          getTableData();
        }
      );
    }
  };

  const onActionDonwload = (downType, pageType) => {
    let params = `t=${pageType}`,
      cols = [];
    const { columns, pageOptions, donloadArray } = trackMyfleetState;

    let qParams = {
      p: pageOptions.pageIndex,
      l: trackMyfleetState.pageOptions.pageLimit,
    };

    columns.map((e) =>
      e.invisible === "false" && e.key !== "action"
        ? cols.push(e.dataIndex)
        : false
    );

    const filter = donloadArray.join();
    window.open(
      `${process.env.REACT_APP_ATTACHMENT}/download/file/${downType}?${params}&l=${qParams.l}&ids=${filter}`,
      "_blank"
    );
  };

  const onRowDeletedClick = (id) => {
    const { data, typesearch } = trackMyfleetState;
    let _url = `${process.env.REACT_APP_MT_URL}marine/delete-from-fleet/${id}`;
    apiDeleteCall(_url, { id: id }, (response) => {
      if (response && response.msg) {
        openNotificationWithIcon("success", response.msg);
        getTableData();
        // if (data.length === 1) {
        //   setTrackMyfleetState(
        //     (prev) => ({ ...prev, typesearch: {} }),
        //     () => {
        //       getTableData(1);
        //     }
        //   );
        // } else {
        //   getTableData(1);
        // }
      } else {
        openNotificationWithIcon("error", response.message);
      }
    });
  };

  const handleResize =
    (index) =>
    (e, { size }) => {
      trackMyfleetState((prev) => {
        const nextColumns = [...prev.columns];
        nextColumns[index] = {
          ...nextColumns[index],
          width: size.width,
        };
        return { columns: nextColumns };
      });
    };

  const tableCol = trackMyfleetState.columns
    .filter((col) => (col && col.invisible !== "true" ? true : false))
    .map((col, index) => ({
      ...col,
      onHeaderCell: (column) => ({
        width: column.width,
        onResize: handleResize(index),
      }),
    }));

  const handleSearch = (e) => {
    setSearchText(e.target.value);
    const filterData = trackMyfleetState.data.filter((item) => {
      const { SHIPNAME } = item;
      let shipName = SHIPNAME.toLowerCase();
      let valLowerCase = e.target.value.toLowerCase();
      return shipName.includes(valLowerCase);
    });

    setTrackMyfleetState((prev) => ({ ...prev, filterData }));
    // setTrackMyfleetState()
  };
  return (
    <div>
    <div style={{ marginTop: "2rem" }}>
      <b style={{ marginLeft: "2rem" }}>Vessels In My Fleet</b>
      {trackMyfleetState.loading === false ? (
        //    <div style={{width : '98%', marginTop:'2rem'}}> <ToolbarUI

        //    routeUrl={"track-my-fleet"}
        //    optionValue={{
        //      pageOptions: trackMyfleetState.pageOptions,
        //      columns: trackMyfleetState.columns,
        //      search: trackMyfleetState.search,
        //    }}
        //    callback={(e) => callOptions(e)}
        //    filter={trackMyfleetState.filter}
        //    dowloadOptions={[
        //      {
        //        title: "CSV",
        //        event: () => onActionDonwload("csv", "track-vessel"),
        //      },
        //      {
        //        title: "PDF",
        //        event: () => onActionDonwload("pdf", "track-vessel"),
        //      },
        //      {
        //        title: "XLS",
        //        event: () => onActionDonwload("xlsx", "track-vessel"),
        //      },
        //    ]}
        //  /></div>
        <div>
          <Input
            placeholder="Search vessels"
            value={searchText}
            onChange={handleSearch}
            style={{
              maxWidth: "400px",
              width: "100%",
              marginLeft: "auto",
              display: "block",
              border: "1px solid black",
            }}
          />
        </div>
      ) : null}
      <Table
        style={{ marginTop: "2rem" }}
        bordered
        columns={tableCol}
        dataSource={
          trackMyfleetState.filterData.length
            ? trackMyfleetState.filterData
            : trackMyfleetState.data
        }
        scroll={{ x: "max-content" }}
        loading={trackMyfleetState.loading}
        className="inlineTable resizeableTable"
        size="small"
        // title={()=> 'Vessels In My Fleet'}
        pagination={{ pageSize: 10 }}
        rowClassName={(r, i) =>
          i % 2 === 0
            ? "table-striped-listing"
            : "dull-color table-striped-listing"
        }
      />
    </div>
      <Modal
        className="vesselhistorymodal"
        title="Vessel History"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        bodyStyle={{ height: "30rem", overflowY: "auto" }}
      >
        <VesselHistory currentVesseldata={currentVesseldata} />
      </Modal>
      </div>

  );
}
