import {
  DeleteOutlined,
  MenuFoldOutlined,
  PlusOutlined,
  SaveOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { Col, Drawer, Layout, Modal, Row } from "antd";
import _ from "lodash";
import * as moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import CustomLoader from "../../../../components/Loading/CustomLoader.js";
import URL_WITH_VERSION, {
  apiDeleteCall,
  awaitPostAPICall,
  getAPICall,
  openNotificationWithIcon,
  postAPICall,
  useStateCallback,
} from "../../../../shared";
import NormalFormIndex from "../../../../shared/NormalForm/normal_from_index";
import VesselFileReport from "../../../operation-reports/VesselFileReport.js";
import { useNavigate, useParams } from "react-router-dom";
import RightBarUI from "../../../../components/RightBarUI/index.js";
import AttachmentFile from "../../../../shared/components/Attachment";
import { deleteAttachment, getAttachments, uploadAttachment } from "../../../../shared/attachments.js";
import Remark from "../../../../shared/components/Remarks/index.js";
import MailLayoutDrawer from "../../../inbox/MailLayoutDrawer.jsx";
import redux_state from "../../../../services/redux_state.js";
import ParsedInfo from "../../../voyage-list/components/ParsedInfo.js";
import { events } from "../../../mail-folder/constants.js";

const { Content } = Layout;

const newFormData = {
  bunkers: [
    {
      short_code: 1,
      items_id: 274,
    },
    {
      short_code: 2,
      items_id: 275,
    },
    {
      short_code: 3,
      items_id: 276,
    },
    {
      short_code: 4,
      items_id: 277,
    },
    {
      short_code: 5,
      items_id: 282,
    },
  ],
};

const HeadFixture = (props) => {
  const _formData = _.cloneDeep(newFormData)
  const [state, setState] = useStateCallback({
    frmName: "head_fixture_form",
    formData: Object.assign(_formData, props.formData || {}),
    frmVisible: true,
    showTDE: false,
    showDownloadInvoice: false,
    tdeData: null,
    showInvoicePopup: false,
    invoiceReport: null,
    isSaved: false,
    popupdata: null,
    loadComponent: false,
    title: undefined,
    width: 1200,
    isshowVesselFileReport: false,
    reportFormData: null,
    isVesselfileFixed: false,
    isVesselFileSchedule: false,
    showItemList: false,
  });
const [counter, setCounter] = useState(0);
  const formdataref = useRef();
  const navigate = useNavigate();

  const hf_data = localStorage.getItem("hf_id");

  const { id } = useParams();

  // useEffect(() => {
  //   const { formData } = state;
  //   if (formData && formData["id"]) {
  //     setState(
  //       (prevState) => ({
  //         ...prevState,
  //         frmVisible: false,
  //         formData: {
  //           ownership_type:
  //             (props.formdata && props.formdata.ownership_type) || null,
  //           vessel: (props.formdata && props.formdata.vessel) || null,
  //           vessel_code: (props.formdata && props.formdata.vessel_code) || null,
  //           commence: (props.formdata && props.formdata.commence) || null,
  //           owner: (props.formdata && props.formdata.owner) || null,
  //           beneficiary: (props.formdata && props.formdata.beneficiary) || null,
  //           my_company: (props.formdata && props.formdata.my_company) || null,
  //           fixed_by: (props.formdata && props.formdata.fixed_by) || null,
  //           con_type: (props.formdata && props.formdata.con_type) || null,
  //           config_date: (props.formdata && props.formdata.config_date) || null,
  //           release_date:
  //             (props.formdata && props.formdata.release_date) || null,
  //           v_status: (props.formdata && props.formdata.v_status) || null,
  //           id: (props.formdata && props.formdata.id) || null,
  //         },
  //         isVesselfileFixed:
  //           props.formdata && props.formdata.is_fixed == 1 ? true : false,
  //         isVesselFileSchedule:
  //           props.formdata && props.formdata.is_schedule == 1 ? true : false,
  //       }),
  //       () => setState((prevState) => ({ ...prevState, frmVisible: true }))
  //     );
  //   }
  // }, []);

  useEffect(() => {
    if (hf_data && id) {
      const hfObject = JSON.parse(hf_data);

      const id = hfObject.id;
      const hfCode = hfObject.hf_code;
      onEditClick(id);
    }
  }, [hf_data]);

  const saveFormData = (vData) => {
    let type = "save";
    let suMethod = "POST";
    if (vData.hasOwnProperty("id") && vData["id"]) {
      type = "update";
      suMethod = "PUT";
      vData = {
        ...vData,
        commence: vData.commence ? moment(vData.commence) : "",
        config_date: vData.config_date ? moment(vData.config_date) : "",
        release_date: vData.release_date ? moment(vData.release_date) : "",
      };
    }
    if (
      vData.hasOwnProperty("ownership_type") &&
      vData["ownership_type"] === ""
    ) {
      vData["ownership_type"] = "162";
    }
    const { frmName } = state;
    formdataref.current = vData;
    setState((prevState) => ({ ...prevState, frmVisible: false }));
    let suURL = `${URL_WITH_VERSION}/head-fixture/${type}?frm=${frmName}`;
    postAPICall(suURL, vData, suMethod, (data) => {
      if (data?.data) {
        openNotificationWithIcon("success", data.message);

        setState((prevState) => ({
          ...prevState,
          formData: {
            ...formdataref.current,
            id: data?.data?.id,
            vessel_code: data?.data?.vessel_code,
          },
        }));
        if (data?.data?.id) {
          onEditClick(data?.data?.id);
        }
      } else {
        openNotificationWithIcon("error", data.message);
        setState((prevState) => ({
          ...prevState,
          frmVisible: true,
          formData: formdataref.current,
        }));
      }
    });
  };

  const onEditClick = async (id) => {
    const { formData } = state;

    if (id) {
      setState((prevState) => ({ ...prevState, frmVisible: false }));
      const response = await getAPICall(
        `${URL_WITH_VERSION}/head-fixture/edit?e=${id}`
      );
      const respData = await response["data"];
      setState((prevState) => ({
        ...prevState,
        formData: respData,
        frmVisible: true,
      }));
    } else {
      openNotificationWithIcon("error", "Something went wrong", 4);
      setState((prevState) => ({ ...prevState, frmVisible: true }));
    }
  };

   const getMarineVessel = async (vesselname) => {
    // console.log("first");
    
      try {
        let url = `${process.env.REACT_APP_MT_URL}marine/get-vessels-name/${vesselname}`;
        let marineData = await getAPICall(url);
        let marinVessel = marineData.filter(
          (item) => item?.SHIPNAME.toLowerCase() === vesselname.toLowerCase()
        );
        if (marinVessel.length) {
          marinVessel = marinVessel[0];
        }
        return marinVessel;
      } catch (err) {
        console.log("err", err);
      }
    };

  const _onCreateFormData = () => {
    setState(
      (prevState) => ({
        ...prevState,
        frmVisible: false,
        formData: _formData,
        isVesselfileFixed: false,
        isVesselFileSchedule: false,
      }),
      () => setState((prevState) => ({ ...prevState, frmVisible: true }))
    );
    navigate("/head-fixture");
  };

  const onCloseDrawer = () =>
    setState((prevState) => ({
      ...prevState,
      visibleDrawer: false,
      title: undefined,
      loadComponent: undefined,
    }));

  const onClickRightMenu = async (key, options) => {
    const { formData } = state;
    onCloseDrawer();
    let loadComponent = undefined;
    let marinedata = {};
    switch (key) {

      case "attachment":
        const { id } = formData;
        if (id) {
          const attachments = await getAttachments(id, "EST");
          const callback = (fileArr) =>
            uploadAttachment(fileArr, id, "EST", "head-fixture");
          loadComponent = (
            <AttachmentFile
              uploadType="Estimates"
              attachments={attachments}
              onCloseUploadFileArray={callback}
              deleteAttachment={(file) =>
                deleteAttachment(file.url, file.name, "EST", "head-fixture")
              }
              tableId={0}
            />
          );
        } else {
          openNotificationWithIcon(
            "info",
            "Attachment will open only after save"
          );
        }
        break;

        case "remark":  
          if (formData && formData.hasOwnProperty("id") && formData["id"] > 0)
            loadComponent = (
              <Remark
                remarksID={formData?.head_fix_code}
                remarkType="head-fixture"
                idType="head_id"
              />
            );
          else openNotificationWithIcon("info", "Remarks will open after save");
          break;
          
          case "mail": {
            const formData = redux_state.reduxStore["head_fixture_form"];
            if (
              !formData?.vesselInfo &&
              !formData?.vesselInfo?.vessel_name?.trim()?.lenght > 0 &&
              !formData?.vessel_name
            ) {
              openNotificationWithIcon("info", "Please select a vessel");
              return;
            }
            loadComponent = (
              <MailLayoutDrawer
                voyageEstimate={true}
                shipName={
                  state?.marineData?.SHIPNAME ||
                  formData?.vesselInfo?.vessel_name ||
                  formData?.vessel_name || ""
                }
                companyName={
                  formData?.companyInfo?.name ||
                  formData?.company_fixed_with_name || ""
                }
              />
            );
            break;
          }
          case "sideMap": {
            const { formData, marineData } = state;
    
            if (marineData?.["SHIPNAME"]) {
              marinedata = marineData;      
            } else {
              marinedata = await getMarineVessel(formData?.vessel_name); 
            }
            if (!marinedata?.SHIPNAME) {
              openNotificationWithIcon("info", "Vessel data not found");
              return;
            }
            loadComponent = <ParsedInfo marineData={marinedata} />;
            break;
          }

      default:
        break;
    }

    setState((prevState) => ({
      ...prevState,
      visibleDrawer: true,
      title: options.title,
      loadComponent: loadComponent,
      width: options.width && options.width > 0 ? options.width : 1200,
    }));
  };

  const _onDeleteFormData = (postData) => {
    Modal.confirm({
      title: "Confirm",
      content: "Are you sure, you want to delete it?",
      onOk: () => DeleteFormData(postData),
    });
  };

  const DeleteFormData = (data) => {
    let URL = `${URL_WITH_VERSION}/head-fixture/delete`;

    if (data && data["id"]) {
      apiDeleteCall(URL, { id: data["id"] }, (resp) => {
        if (resp && resp.data) {
          openNotificationWithIcon("success", resp.message);
          if (props.hasOwnProperty("modalCloseEvent")) {
            props.modalCloseEvent();
          } else {
            _onCreateFormData();
          }
        } else {
          openNotificationWithIcon("error", resp.message);
        }
      });
    } else {
      openNotificationWithIcon("info", "Please fill the form first.", 3);
    }
  };

  const openVesselFileReport = async (showVesselFileReport) => {
    if (showVesselFileReport) {
      try {
        if (state.formData["id"] === undefined) {
          openNotificationWithIcon("info", "Kindly save the form first.", 5);
          return;
        }
        const responseReport = await getAPICall(
          `${URL_WITH_VERSION}/head-fixture/report?e=${state.formData["id"]}`
        );
        const respDataReport = await responseReport["data"];

        if (respDataReport) {
          setState((prevState) => ({
            ...prevState,
            reportFormData: respDataReport,
            isshowVesselFileReport: showVesselFileReport,
          }));
        } else {
          openNotificationWithIcon("error", "Unable to show report", 5);
        }
      } catch (err) {
        openNotificationWithIcon("error", "Something went wrong.", 5);
      }
    } else {
      setState((prevState) => ({
        ...prevState,
        isshowVesselFileReport: showVesselFileReport,
      }));
    }
  };

  const performFix = async () => {
    const { formData } = state;
    setState((prevState) => ({ ...prevState, frmVisible: false }));
    if (formData && formData.hasOwnProperty("id") && formData["id"] > 0) {
      let _url = `${URL_WITH_VERSION}/vessel-file/fix`,
        data = { id: state.formData.id };
      let response = await awaitPostAPICall(_url, data);
      let respData = await response;
      if (respData["data"] !== false) {
        openNotificationWithIcon("success", respData.message);
        onEditClick(state.formData.id);
        setState((prevState) => ({
          ...prevState,
          isVesselfileFixed: true,
          frmVisible: true,
        }));
      } else {
        let dataMessage = respData.message;
        let msg = "<div className='row'>";
        if (typeof dataMessage !== "string") {
          Object.keys(dataMessage).map(
            (i) =>
              (msg += "<div className='col-sm-12'>" + dataMessage[i] + "</div>")
          );
        } else {
          msg += dataMessage;
        }
        msg += "</div>";
        openNotificationWithIcon(
          "error",
          <div dangerouslySetInnerHTML={{ __html: msg }} />
        );
        setState((prevState) => ({ ...prevState, frmVisible: true }));
      }
    } else {
      openNotificationWithIcon("info", "Please save Vessel file First.", 3);
    }
  };

  const scheduleVesselFile = async () => {
    const { formData } = state;

    const response = await getAPICall(
      `${URL_WITH_VERSION}/vessel-file/schedule/${state.formData["id"]}`
    );
    const respData = await response;
    setState((prevState) => ({ ...prevState, frmVisible: false }));
    if (respData["data"] === true) {
      openNotificationWithIcon("success", respData["message"]);
      // if (formData && formData.id) {
      //   _onLeftSideListClick(formData.id)
      // }
      if (
        props.modalCloseEvent &&
        typeof props.modalCloseEvent === "function"
      ) {
        props.modalCloseEvent();
      }
      // window.location.href='#/vessel-file-list';
      onEditClick(state.formData.id);
      // setState({ ...state, frmVisible:true})
    } else if (respData["data"] === false) {
      let dataMessage = respData["message"];
      let msg = "<div className='row'>";

      if (typeof dataMessage !== "string") {
        Object.keys(dataMessage).map(
          (i) =>
            (msg += "<div className='col-sm-12'>" + dataMessage[i] + "</div>")
        );
      } else {
        msg += dataMessage;
      }

      msg += "</div>";
      openNotificationWithIcon(
        "error",
        <div dangerouslySetInnerHTML={{ __html: msg }} />
      );
    }
  };

  const reFreshForm = () =>{
    setCounter((prevCounter) => prevCounter + 1);
  }

  const {
    loadComponent,
    formData,
    frmName,
    title,
    visibleDrawer,
    frmVisible,
    isVesselfileFixed,
    isVesselFileSchedule,
    isshowVesselFileReport,
    reportFormData,
  } = state;

  return (
    <div className="wrap-rightbar full-wraps">
      <Layout className="layout-wrapper">
        <Layout>
          <Content className="content-wrapper">
            <div className="fieldscroll-wrap">
              <div className="body-wrapper">
                <Row gutter={16}>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    {frmVisible ? (
                      <article className="article">
                        <div className="box box-default">
                          <div className="box-body" key={counter}>
                              <NormalFormIndex
                                key={"key_" + frmName + "_0"}
                                formClass="label-min-height"
                                formData={formData}
                                showForm={true}
                                frmCode={frmName}
                                addForm={true}
                                inlineLayout={true}
                                showToolbar={[
                                  {
                                    leftWidth: 8,
                                    rightWidth: 16,
                                    isLeftBtn: [
                                      {
                                        key: "s1",
                                        isSets: [
                                          {
                                            id: "1",
                                            key: "menu-fold",
                                            type: <MenuFoldOutlined />,
                                            withText: "List",
                                            showToolTip: true,
                                            event: "menu-fold",
                                          },
                                          {
                                            id: "2",
                                            key: "add",
                                            type: <PlusOutlined />,
                                            withText: "Add New",
                                            showToolTip: true,
                                            event: (key, data) =>
                                              _onCreateFormData(),
                                          },

                                          {
                                            id: "3",
                                            key: "save",
                                            type: <SaveOutlined />,
                                            withText: formData.id
                                              ? "Update"
                                              : "Save",
                                            showToolTip: true,
                                            event: (key, data) =>
                                              saveFormData(data),
                                          },
                                          {
                                            id: "4",
                                            key: "delete",
                                            type: <DeleteOutlined />,
                                            withText: "Delete",
                                            showToolTip: true,
                                            event: (key, data) =>
                                              _onDeleteFormData(data),
                                          },

                                          {
                                            id: "5",
                                            key: "refresh",
                                            type: <SyncOutlined/>,
                                            showToolTip:true,
                                            event: () =>{
                                              reFreshForm()
                                            }
                                          }
                                          // {
                                          //   id: "4",
                                          //   key:
                                          //     state.formData?.hasOwnProperty(
                                          //       "id"
                                          //     ) && state.formData?.["id"]
                                          //       ? "delete"
                                          //       : "reset",
                                          //   type:
                                          //     state.formData?.hasOwnProperty(
                                          //       "id"
                                          //     ) && state.formData?.["id"] ? (
                                          //       <DeleteOutlined />
                                          //     ) : (
                                          //       <SyncOutlined />
                                          //     ),
                                          //   withText: state.formData?.["id"]
                                          //     ? "Delete"
                                          //     : "Refresh",
                                          //   showToolTip: true,
                                          //   event: (key, data) => {
                                          //     state?.formData?.["id"]
                                          //       ? _onDeleteFormData(data)
                                          //       : _onCreateFormData();
                                          //   },
                                          // },
                                        ],
                                      },
                                    ],
                                    isRightBtn: [
                                      {
                                        key: "s2",
                                        isSets: [
                                          {
                                            key: isVesselFileSchedule
                                              ? ""
                                              : isVesselfileFixed
                                              ? "schedule"
                                              : "fix",
                                            isDropdown: 0,
                                            withText: isVesselFileSchedule
                                              ? ""
                                              : isVesselfileFixed
                                              ? "Schedule"
                                              : "Fix",
                                            type: "",
                                            menus: null,
                                            // event: () => {
                                            //   Modal.confirm({
                                            //     title: "Confirm",
                                            //     content: isVesselFileSchedule
                                            //       ? ""
                                            //       : isVesselfileFixed
                                            //       ? "Are you sure, you want to Schedule it?"
                                            //       : "Are you sure, you want to Fix it?",
                                            //     onOk: () =>
                                            //       isVesselFileSchedule
                                            //         ? ""
                                            //         : isVesselfileFixed
                                            //         // ? scheduleVesselFile()
                                            //         // : performFix(),
                                            //   });
                                            // },
                                          },

                                          {
                                            key: "Create Contract",
                                            isDropdown: 1,
                                            withText: "",
                                            showToolTip: true,
                                            type: "Create Contract",
                                            menus: [
                                              {
                                                href: null,
                                                icon: null,
                                                id:"create_voyage_estimate",
                                                label: "Create Voyage Estimate",
                                              },
                                              {
                                                href: null,
                                                icon: null,
                                                id:"create_tc_estimate",
                                                label: "Create TC Estimate",
                                              },
                                              {
                                                href: null,
                                                icon: null,
                                                id:"create_voyage_manager",
                                                label: "Create Voyage Manager",
                                              },
                                            ],
                                            event: (key) => {
                                              //console.log(key);
                                            },
                                          },
                                          {
                                            key: "Create Invoice",
                                            isDropdown: 1,
                                            withText: "",
                                            showToolTip: true,
                                            type: "Create Invoice",
                                            menus: [
                                              {
                                                href: null,
                                                icon: null,
                                                id:"create_bunker_invoice_list",
                                                label: "Create Bunker Invoice - List",
                                              },
                                              {
                                                href: null,
                                                icon: null,
                                                id:"make_other_payment",
                                                label: "Make Other Payment",
                                              },
                                            ],
                                            event: (key) => {
                                              //console.log(key);
                                            },
                                          },

                                          {
                                            key: "report",
                                            isDropdown: 0,
                                            withText: "Report",
                                            type: "",
                                            menus: null,
                                            event: (key) =>
                                              openVesselFileReport(true),
                                          },
                                        ],
                                      },
                                    ],
                                    isResetOption: false,
                                  },
                                ]}
                                isShowFixedColumn={["OFF Hire"]}
                                sideList={{
                                  //statusColor : formData && formData[0]['tci_status_name'] &&  formData['tci_status_name'] == 'FIX' ? '#28a745' : formData['status_name'] == 'PENDING' ? '#01bcd4' : '#9e9e9e',
                                  //selectedID: selectedID,
                                  showList: true,
                                  title: "Head Fixture List",
                                  uri: "/head-fixture/list?l=0",
                                  columns: [
                                    ["head_fix_code", "v_status_name"],
                                    "vessel_name",
                                    "owner_name",
                                  ],
                                  icon: true,
                                  searchString:
                                    "Head Fixture Code,Status,Vessel Name,Owner Name",
                                  rowClickEvent: (evt) => onEditClick(evt.id),
                                }}
                                showSideListBar={state.showItemList}
                              />
                          </div>
                        </div>
                      </article>
                    ) : (
                      <CustomLoader />
                    )}
                  </Col>
                </Row>
              </div>
            </div>
          </Content>
        </Layout>
        <RightBarUI
            pageTitle= "head-fixture-righttoolbar"
            callback={(data, options) => onClickRightMenu(data, options)}
          />
        {loadComponent !== undefined &&
        title !== undefined &&
        visibleDrawer === true ? (
          state?.title === "Attachments" ? (
            // Special Drawer for Attachments
            <Drawer
              title={state.title}
              placement="right"
              closable={true}
              onClose={onCloseDrawer}
              open={state.visibleDrawer}
              getContainer={false}
              style={{ position: "absolute", width: "60%", right: "0" }}
              maskClosable={false}
              className="drawer-wrapper-container attachments-drawer"
            >
              <div className="tcov-wrapper">
                <div className="layout-wrapper scrollHeight">
                  <div className="content-wrapper noHeight">
                    {state?.loadComponent}
                  </div>
                </div>
              </div>
            </Drawer>
          ) : (
            <Drawer
              title={state?.title}
              placement="right"
              closable={true}
              onClose={onCloseDrawer}
              open={state.visibleDrawer}
              getContainer={false}
              style={{ position: "absolute"}}
              width={state.width}
              maskClosable={false}
              className="drawer-wrapper-container"
            >
              <div className="tcov-wrapper">
                <div className="layout-wrapper scrollHeight">
                  <div className="content-wrapper noHeight">
                    {state?.loadComponent}
                  </div>
                </div>
              </div>
            </Drawer>
          )
        ) : undefined}
          
      </Layout>
      {isshowVesselFileReport ? (
        <Modal
          style={{ top: "2%" }}
          title="Reports"
          open={isshowVesselFileReport}
          onCancel={() => openVesselFileReport(false)}
          width="95%"
          footer={null}
        >
          <VesselFileReport data={reportFormData} />
        </Modal>
      ) : undefined}
    </div>
  );
};

export default HeadFixture;
