import { Button, Checkbox, Input } from "antd";
import React, { useState } from "react";
import { ArrowLeftOutlined, ArrowRightOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons";

const SelectMailFromGroup = (props) => {
  const {
    toGroup,
    ccGroup,
    bccGroup,
    setToGroup,
    setCcGroup,
    setBccGroup,
    groupEmails,
    type,
  } = props;

  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const emailsPerPage = 5;

  // Filter emails based on search term
  const filteredEmails = groupEmails.filter((email) =>
    email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Paginate filtered emails
  const totalPages = Math.ceil(filteredEmails.length / emailsPerPage);
  const paginatedEmails = filteredEmails.slice(
    (currentPage - 1) * emailsPerPage,
    currentPage * emailsPerPage
  );

  // Handle email selection
  const handleCheckboxChange = (email, checked, type) => {
    if (type === "to") {
      setToGroup(
        checked ? [...toGroup, email] : toGroup.filter((e) => e !== email)
      );
    } else if (type === "cc") {
      setCcGroup(
        checked ? [...ccGroup, email] : ccGroup.filter((e) => e !== email)
      );
    } else if (type === "bcc") {
      setBccGroup(
        checked ? [...bccGroup, email] : bccGroup.filter((e) => e !== email)
      );
    }
  };

  // Handle Select All functionality
  const handleSelectAllChange = (checked, type) => {
    if (type === "to") {
      setToGroup(checked ? filteredEmails : []);
    } else if (type === "cc") {
      setCcGroup(checked ? filteredEmails : []);
    } else if (type === "bcc") {
      setBccGroup(checked ? filteredEmails : []);
    }
  };

  return (
    <div>
      <Input
        placeholder="Search emails..."
        value={searchTerm}
        onChange={(e) => {
          setSearchTerm(e.target.value);
          setCurrentPage(1); // Reset to first page on search
        }}
        style={{ marginBottom: "15px" }}
      />

      {/* Email Selection Section */}
      <div>
        <h4>{type.toUpperCase()}</h4>
        <Checkbox
          checked={filteredEmails.every((email) =>
            props[`${type}Group`].includes(email)
          )}
          indeterminate={
            filteredEmails.some((email) =>
              props[`${type}Group`].includes(email)
            ) &&
            !filteredEmails.every((email) =>
              props[`${type}Group`].includes(email)
            )
          }
          onChange={(e) => handleSelectAllChange(e.target.checked, type)}
        >
          Select All
        </Checkbox>

        {paginatedEmails.map((email) => (
          <div key={email} style={{ marginBottom: "5px" }}>
            <Checkbox
              checked={props[`${type}Group`].includes(email)}
              onChange={(e) =>
                handleCheckboxChange(email, e.target.checked, type)
              }
            >
              {email}
            </Checkbox>
          </div>
        ))}
      </div>

      {/* Pagination Controls */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "10px",
        }}
      >
        <ArrowLeftOutlined
          onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
          style={{
            color: "green",
            fontSize: "30px",
            cursor: currentPage === 1 ? "not-allowed" : "pointer",
          }}
        />

        <span>
          Page {currentPage} of {totalPages}
        </span>

        <ArrowRightOutlined
          onClick={() =>
            setCurrentPage((prev) => Math.min(prev + 1, totalPages))
          }
          style={{
            color:  "green",
            fontSize: "30px",
            cursor: currentPage === totalPages ? "not-allowed" : "pointer",
          }}
        />
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "10px",
        }}
      >
        <Button onClick={props.close}>Ok</Button>
      </div>
    </div>
  );
};

export default SelectMailFromGroup;
