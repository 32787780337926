import {
  Alert,
  Col,
  Drawer,
  Layout,
  Modal,
  notification,
  Row,
  Spin,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import {
  DeleteOutlined,
  MenuFoldOutlined,
  PlusOutlined,
  SaveOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { toggleCollapsedNav } from "../../../../actions/settingsActions";
import RightBarUI from "../../../../components/RightBarUI";
import URL_WITH_VERSION, {
  apiDeleteCall,
  getAPICall,
  objectToQueryStringFunc,
  openNotificationWithIcon,
  postAPICall,
  useStateCallback,
} from "../../../../shared";
import {
  deleteAttachment,
  getAttachments,
  uploadAttachment,
} from "../../../../shared/attachments";
import Attachment from "../../../../shared/components/Attachment";
import NormalFormIndex from "../../../../shared/NormalForm/normal_from_index";
import CargoContractReports from "../../../form-reports/CargoContractReports";
import CargoChildForm from "../cargo/cargo-child";
import TCOV from "../tcov";
import Properties from "../tcov/Properties";
import FullEstimate from "../voy-relet/FullEstimate";
import CargoDetails from "../voyage-cargo-in";
import EstimateSummary from "./EstimateSummary";
import PlSummary from "./PlSummary";

import MailSpiltView from "../../../inbox/Inbox";
import SuggestionTable from "./SuggestionTable";
import UpComingVesselSuggestion from "./UpComingVesselSuggestion";
import redux_state from "../../../../services/redux_state";

const { Content } = Layout;
//const FormItem = Form.Item;
//const TextArea = Input.TextArea;
//const InputGroup = Input.Group;

const openNotification = (keyName, isValid = false, type = "create") => {
  let msg = `Please save voyage charter Form first, and then click on ${keyName}`;
  if (isValid)
    msg = `You cannot ${keyName} as it is already being attached. Kindly update it in same form.`;

  if (type == "edit")
    msg = `You cannot ${keyName} as VC-Purchase Id is not attached yet.`;

  notification.info({
    message: `Can't Open ${keyName}`,
    description: msg,
    placement: "topRight",
  });
};

const suggestionColsCargo = [
  {
    title: "Vessel Name",
    dataIndex: "vessel_name",
    key: "vessel_name",
    width: 150,
    align: "center",
  },
  {
    title: "DWT",
    dataIndex: "dwt",
    key: "dwt",
    width: 150,
    align: "center",
  },
  {
    title: "Vessel Type",
    dataIndex: "vessel_type",
    key: "dwt",
    width: 150,
    align: "center",
  },
  {
    title: "Country",
    dataIndex: "country",
    key: "country",
    width: 150,
    align: "center",
  },
];
const suggestionColsLoad = [
  {
    title: "Vessel Name",
    dataIndex: "vessel_name",
    key: "vessel_name",
    width: 150,
    align: "center",
  },
  {
    title: "DWT",
    dataIndex: "dwt",
    key: "dwt",
    width: 150,
    align: "center",
  },
  {
    title: "Estimated Arrival",
    dataIndex: "estimated_arrival",
    key: "estimated_arrival",
    width: 150,
    align: "center",
  },
  {
    title: "Current Port",
    dataIndex: "current_port",
    key: "current_port",
    width: 150,
    align: "center",
  },
  {
    title: "Area",
    dataIndex: "area",
    key: "area",
    width: 150,
    align: "center",
  },
];

const CargoContract = (props) => {
  const isInitialRenderRef = useRef(true);
  const routeParams = useParams();
  const [counter, setCounter] = useState(0);
  const [state, setState] = useStateCallback({
    // estimateID: estimateId,
    isShowImport: props.isShowImport || false,
    isShowInPopup: props.isShowInPopup || false,
    value: 1,
    frmName: "cargo_contract_form",
    copyFrmName: "ccc_form",
    cargoData: {},
    visisbleCargoChild: false,
    formData: Object.assign(
      {
        id: 0,
        cp_date: moment(),
        laycan_from: moment(),
        laycan_to: moment(),
        // cargo_name:props?.importedCargoData?.cargo_id||"",
        // laycan_from: moment(props?.importedCargoData?.laycan_from)?.format('YYYY-MM-DD')||null,
        // laycan_to: moment(props?.importedCargoData?.laycan_to)?.format('YYYY-MM-DD')||null,
        billingandbankingdetails: {
          due_date: moment(),
        },
      },
      props?.formData || {}
    ),

    formDataReport: Object.assign(
      {
        id: 0,
        cp_date: moment(),
        laycan_from: moment(),
        laycan_to: moment(),
        billingandbankingdetails: {
          due_date: moment(),
        },
      },
      props?.formData || {}
    ),
    copyFormData: {},
    cargoContracts: [],
    coaVciId: props.formData,
    frmVisible: true,
    extraFormFields: null,
    visibleTask: false,
    visibleRevision: false,
    isVisibleCopy: false,
    isVisible: false,
    doCopyContract: true,
    visibleDrawer: false,
    reportFormData: {
      // portitinerary: [
      //   {
      //     port_id: "4444",
      //     port: "2944",
      //     funct: 3,
      //     s_type: 1,
      //     wf_per: 10,
      //     miles: "0.00",
      //     speed: "0.00",
      //     eff_speed: "0.00",
      //     gsd: "0.00",
      //     tsd: "0.00",
      //     xsd: "0.00",
      //     l_d_qty: "0.00",
      //     l_d_rate: "0.00",
      //     l_d_rate1: "0.00",
      //     turn_time: "0.00",
      //     days: "0.00",
      //     xpd: "0.00",
      //     p_exp: "0.00",
      //     t_port_days: "0.00",
      //     l_d_term: 1,
      //     editable: true,
      //     index: 0,
      //     ID: -9e6,
      //   },
      //   {
      //     port_id: "6297",
      //     port: "5530",
      //     s_type: 1,
      //     wf_per: 10,
      //     miles: "0.00",
      //     speed: "0.00",
      //     eff_speed: "0.00",
      //     gsd: "0.00",
      //     tsd: "0.00",
      //     xsd: "0.00",
      //     l_d_qty: "0.00",
      //     l_d_rate: "0.00",
      //     l_d_rate1: "0.00",
      //     turn_time: "0.00",
      //     days: "0.00",
      //     xpd: "0.00",
      //     p_exp: "0.00",
      //     t_port_days: "0.00",
      //     l_d_term: 1,
      //     editable: true,
      //     index: 1,
      //     ID: -9e6 + 1,
      //   },
      // ],
    },
    cargoSuggestion: [],
    title: undefined,
    loadComponent: undefined,
    // width: 1200,

    showSideListBar: false,

    isSetsRightBtn: [
      {
        key: "menu",
        isDropdown: 0,
        withText: "Menu",
        type: "",
      },
    ],

    isShowCargoContractReports: false,
    visibleContactAttachment: false,
    postFormData: [],
    secondaryData: {},
    cargocoaVciID:
      props.formData && props.formData["cargo_coa"]
        ? props.formData["cargo_coa"]
        : null,
    selectedID: null,
    isShowEstimate: false,
    isShowTCOV: false,
    suggestions: [],
  });

  const storeData = useSelector((store) => store.settings);
  const dispatch = useDispatch();

  useEffect(() => {
    const _isSetsLeftBtn = [
      {
        id: "1",
        key: "menu-fold",
        type: <MenuFoldOutlined />,
        withText: "List",
        showToolTip: true,
        event: "menu-fold",
      },
      {
        id: "2",
        key: "plus",
        type: <PlusOutlined />,
        withText: "Add New",
        showToolTip: true,
        event: (key, data) => _onCreateFormData(),
      },

      {
        id: "3",
        key: "save",
        type: <SaveOutlined />,
        withText: "Save",
        showToolTip: true,
        event: (key, data) => saveFormData(data),
      },

      ...(state?.formData?.id !== 0 &&
      state?.formData?.id !== undefined &&
      !props?.showDeleteIcon &&
      props.showDeleteIcon !== "noShow"
        ? [
            {
              id: "4",
              key: "delete",
              type: <DeleteOutlined />,
              withText: "Delete",
              showToolTip: true,
              event: (key, data) => _onDeleteFormData(data),
            },
          ]
        : []),
      {
        id: "20",
        key: "refresh",
        type: <SyncOutlined />,
        withText: "Refresh",
        showToolTip: true,
        event: () => {
          reFreshForm();
        },
      },
    ];
    setState((prevState) => ({
      ...prevState,
      isSetsLeftBtn: _isSetsLeftBtn,
    }));
  }, [state?.formData]);

  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  const { data: datafromlist } = location.state || {};

  const redirectToVoyageRelet = (st, type) => {
    const { formData } = state;
    if (st === true) {
      let _formData = {};
      _formData["cargos"] = [
        {
          sp_type: formData.contract_type,
          cargo_contract_id: formData.cargo_contract_id,
          cargo_name: formData.cargo_name,
          charterer: formData.charterer,
          quantity: formData.cp_qty,
          unit: formData.cp_unit,
          //option_percentage:,
          // option_type:,
          f_type: formData.freight_type,
          f_rate: formData.freight_rate,
          //lumpsum:,
          // commission:,
          //extra_rev:,
          curr: formData.currency,
          //dem_rate_pd:,
        },
      ];
      if (type == "tcov") {
        setState({ ...state, estimateCargoData: _formData, isShowTCOV: st });
      } else if (type == "voy-relet") {
        setState({
          ...state,
          estimateCargoData: _formData,
          isShowEstimate: st,
        });
      }
    } else {
      setState({
        ...state,
        isShowEstimate: st,
        estimateCargoData: null,
        isShowTCOV: st,
      });
    }
  };

  let formref = useRef(null);

  useEffect(() => {
    isInitialRenderRef.current = false;
  }, []);

  const reFreshForm = () => {
    // let _data = {...datafromlist}
    setCounter((prevCounter) => prevCounter + 1);

    // console.log("formData",formData);

    if (
      formData?.hasOwnProperty("cargo_contract_id") &&
      formData?.["cargo_contract_id"]
    ) {
      _onLeftSideListClick(formData);
    }
  };

  useEffect(() => {
    try {
      if (location.pathname === "/add-cargo-contract") {
        setState((prevState) => ({
          ...prevState,
          formData: {
            id: 0,
            cp_date: moment(),
            laycan_from: moment(),
            laycan_to: moment(),
            billingandbankingdetails: {
              due_date: moment(),
            },
            ...(props.formData || {}), // Include existing formData from props, if any
          },

          // frmVisible: false, // Set frmVisible to false initially
        }));
        setCounter((prevCounter) => prevCounter + 1);
      } else {
        let _data = {};

        if (
          datafromlist?.hasOwnProperty("cargo_contract_id") &&
          datafromlist?.["cargo_contract_id"]
        ) {
          _data = { ...datafromlist };
        } else if (
          props?.formData?.hasOwnProperty("cargo_contract_id") &&
          props?.formData?.["cargo_contract_id"]
        ) {
          _data = { ...props.formData };
        } else {
          _data = { cargo_contract_id: params.id };
        }

        if (
          _data?.hasOwnProperty("cargo_contract_id") &&
          _data?.["cargo_contract_id"]
        ) {
          _onLeftSideListClick(_data);
        }
      }
    } catch (err) {
      console.log("err", err);
    }
  }, [location.pathname]);

  isInitialRenderRef.current = true;

  useEffect(() => {
    if (props.fullEstimate && props.fullEstimate == true) {
      if (props.formData && !props.formData.hasOwnProperty("key")) {
        setState({
          ...state,
          formData: {
            ...props.formData,
            // cargo_name:props?.importedCargoData?.cargo||"",
            // laycan_from: moment(props?.importedCargoData?.laycan_from)?.format('YYYY-MM-DD')||null,
            // laycan_to: moment(props?.importedCargoData?.laycan_to)?.format('YYYY-MM-DD')||null,
            // cp_qty: props?.importedCargoData?.cp_qty?.toString()||"",
            // charterer:props?.importedCargoData?.charterer||"",
            // freight_rate:props?.importedCargoData?.frat_rate||"",
          },
          formDataReport: {
            ...props.formDataReport,
            cargo_name: props?.importedCargoData?.cargo || "",
            laycan_from: props?.importedCargoData?.laycan_from || null,
            laycan_to: props?.importedCargoData?.laycan_to || null,
            cp_qty: props?.importedCargoData?.cp_qty?.toString() || "",
            charterer: props?.importedCargoData?.charterer || "",
            min_qty: props?.importedCargoData?.min_qty || 0,
            max_qty: props?.importedCargoData?.max_qty || 0,
            // freight_rate:props?.importedCargoData?.frat_rate||"",
            freight_rate: (() => {
              const frtType = props?.importedCargoData?.frt_type;
              if (frtType == 104) {
                return props?.importedCargoData?.lumsum;
              } else if (frtType == 38) {
                return props?.importedCargoData?.frat_rate;
              } else if (frtType == 259) {
                return props?.importedCargoData?.world_scale;
              } else {
                return "";
              }
            })(),
            freight_type: parseInt(props?.importedCargoData?.frt_type) || 38,
            booking_no:
              parseInt(props?.importedCargoData?.opt_percentage) || "",
          },
        });

        if (props.fullEstimate == true && !state.formData?.cargo_contract_id) {
          setState({ ...state, formDataReport: props.formData });
        }
      } else {
        _onLeftSideListClick(props.formData);
      }
    } else {
      const { formData } = state;
      if (
        params.id ||
        (formData.hasOwnProperty("cargo_contract_id") &&
          formData["cargo_contract_id"])
      ) {
        let est_id = params.id
          ? params.id
          : formData["cargo_contract_id"]
          ? formData["cargo_contract_id"]
          : null;
        //_onLeftSideListClick(est_id);
      }
    }
    setState((prevState) => ({
      ...prevState,
      extraFormFields: { isShowInMainForm: true },
    }));
  }, [counter]);

  const _onCreateFormData = () => {
    // navigate("/add-voyage-charter");
    navigate("/add-cargo-contract");
    setState(
      (prevState) => ({
        ...prevState,
        frmVisible: false,
      }),
      () => {
        setState((prevState) => ({
          ...prevState,
          formData: {
            id: 0,
            cp_date: moment(),
            laycan_from: moment(),
            laycan_to: moment(),
            billingandbankingdetails: {
              due_date: moment(),
            },
          },
          showSideListBar: false,
          frmVisible: true,
          selectedID: null,
          cargocoaVciID: null,
        }));
      }
    );
  };

  const getVesselNameFromChild = (data, bool) => {
    CargoAutoSuggestions(bool, data);
  };

  const CargoAutoSuggestions = (bool, suggestions) => {
    setState((prev) => ({
      ...prev,
      visibleDrawer: true,
      title: "suggestion",
      // marineData: vesselDetails,
      suggestions: suggestions,
      loadComponent: (
        <div style={{ display: "flex" }}>
          <SuggestionTable
            data={suggestions}
            suggestionColsCargo={suggestionColsCargo}
          />
        </div>
      ),
    }));
  };

  const openLoadPortDrawer = async (data) => {
    let portSelected = data?.port?.port_name;
    let url = `${URL_WITH_VERSION}/recommendation/upcoming_vessels`;

    await postAPICall(
      url,
      { port_name: portSelected.split(" ").join("") },
      "post",
      (data) => {
        setState((prev) => ({
          ...prev,
          visibleDrawer: true,
          loadComponent: (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                justifyContent: "center",
                padding: "20px",
              }}
            >
              <SuggestionTable
                data={state.suggestions}
                suggestionColsCargo={suggestionColsCargo}
              />
              {/* <SuggestionTable data={data.data.expected_arrivals} suggestionColsLoad = {suggestionColsLoad}/> */}
              <UpComingVesselSuggestion data={data.data.expected_arrivals} />
            </div>
          ),
        }));
      }
    );
  };

  const onCloseDrawer = () =>
    setState((prevState) => ({
      ...prevState,
      visibleDrawer: false,
      title: undefined,
      loadComponent: undefined,
    }));

  const onClickRightMenu = async (key, options) => {
    // console.log("state");
    onCloseDrawer();
    if (!storeData.collapsedNav.collapsedNav) {
      dispatch(toggleCollapsedNav(true));
    }

    let loadComponent = undefined;
    let openDrawer = true;
    switch (key) {
      case "pl-summary":
        loadComponent = <PlSummary />;
        break;
      case "summary":
        loadComponent = <EstimateSummary />;
        break;
      case "properties":
        loadComponent = <Properties />;
        break;
      case "attachment":
        const { cargocoaVciID } = state;

        if (cargocoaVciID) {
          const attachments = await getAttachments(cargocoaVciID, "EST");
          const callback = (fileArr) =>
            uploadAttachment(fileArr, cargocoaVciID, "EST", "cargo-contract");
          loadComponent = (
            <Attachment
              uploadType="Estimates"
              attachments={attachments}
              onCloseUploadFileArray={callback}
              deleteAttachment={(file) =>
                deleteAttachment(file.url, file.name, "EST", "cargo-contract")
              }
              tableId={0}
            />
          );
        } else {
          openNotificationWithIcon(
            "info",
            "Attachments are not allowed here. Please Save First."
          );
          openDrawer = false;
        }
        break;

      case "mail": {
        loadComponent = <MailSpiltView />;
        break;
      }

      default:
        break;
    }
    if (openDrawer) {
      setState({
        ...state,
        visibleDrawer: true,
        title: options.title,
        loadComponent: loadComponent,
        width: options.width && options.width > 0 ? options.width : 1200,
      });
    }
  };

  const saveFormData = (postData) => {
    const { frmName } = state;

    let _url = "save",
      _method = "post";
    if (
      state.formData.cargo_status == 73 &&
      (postData["cargo_status"] == 112 || postData["cargo_status"] == 74)
    ) {
      let msg = `You cannot change Enquiry/Confirmed after fix status.`;
      openNotificationWithIcon(
        "info",
        <div dangerouslySetInnerHTML={{ __html: msg }} />,
        5
      );
      return false;
    }

    setState((prevState) => ({ ...prevState, frmVisible: true }));
    if (props.fullEstimate) {
      postData.isTCOVForm = 1;
    }
    if (postData.hasOwnProperty("id") && postData["id"] > 0) {
      _url = "update";
      _method = "put";

      if (postData.hasOwnProperty("cp_qty")) {
        if (postData["cp_qty"] == 0) {
          delete postData["cp_qty"];
        }
      }

      if (postData.hasOwnProperty("freight_rate")) {
        if (postData["freight_rate"] == 0) {
          delete postData["freight_rate"];
        }
      }

    } else {
    }
    if (postData.disablefield) {
      delete postData.disablefield;
    }
    Object.keys(postData).forEach(
      (key) => postData[key] === null && delete postData[key]
    );

    ["l_d_rates", "l_h_rates", "ld_terms", "pexp", "ld_tt", "draft"].forEach(
      (e) => delete postData[e]
    );

    postAPICall(
      `${URL_WITH_VERSION}/cargo/${_url}?frm=${frmName}`,
      postData,
      _method,
      (data) => {
        if (data.data) {
          openNotificationWithIcon("success", data.message);

          if (data.row) {
            const _data = Object.assign({ sp_type: 186 }, data.row);
            // if (typeof props.onSaveEvent === 'function') props.onSaveEvent(_data);
            // if (
            //   props.modalCloseEvent &&
            //   typeof props.modalCloseEvent === "function"
            // ) {
            //   props.modalCloseEvent(_data);
            //   if (props.fullEstimate) {
            //     props.getCargo(data.row);
            //   }
            //   return;
            // }
          }

          // let setData = Object.assign(
          //   {
          //     id: 0,
          //     cp_date: moment(),
          //     laycan_from: moment(),
          //     laycan_to: moment(),
          //     billingandbankingdetails: {
          //       due_date: moment(),
          //     },
          //   },
          //   props.formData || {}
          // );

          // if (match && ['/edit-voyage-charter/:id', "/add-voyage-charter"].includes(match.path) && data.row && data.row.cargo_contract_id) {
          //   _onLeftSideListClick(data.row)
          // }
          if (data.row && data.row.cargo_contract_id) {
            _onLeftSideListClick(data.row);
          }

          // setState((prevState) => ({
          //   ...prevState,
          //   formData: setData,
          //   showSideListBar: true,
          //   frmVisible: true,
          // }));
        } else {
          setState((prevState) => ({ ...prevState, frmVisible: true }));
          let dataMessage = data.message;
          let msg = "<div className='row'>";

          if (typeof dataMessage !== "string") {
            Object.keys(dataMessage).map(
              (i) =>
                (msg +=
                  "<div className='col-sm-12'>" + dataMessage[i] + "</div>")
            );
          } else {
            msg += dataMessage;
          }

          msg += "</div>";
          openNotificationWithIcon(
            "info",
            <div dangerouslySetInnerHTML={{ __html: msg }} />,
            5
          );
        }
      }
    );
  };

  const _onDeleteFormData = (postData) => {
    if (postData && postData.id <= 0) {
      openNotificationWithIcon(
        "error",
        "Cargo Id is empty. Kindly check it again!"
      );
    }
    Modal.confirm({
      title: "Confirm",
      content: "Are you sure, you want to delete it?",
      onOk: () => _onDelete(postData),
    });
  };

  const _onDelete = (postData) => {
    let _url = `${URL_WITH_VERSION}/cargo/delete`;
    try {
      apiDeleteCall(_url, { id: postData.id }, (response) => {
        if (response && response.data) {
          openNotificationWithIcon("success", response.message);
          if (
            response.message ===
            "Contract sent to Enquiry stage for deletion successfully."
          ) {
            let setData = Object.assign(
              {},
              {
                id: 0,
                cp_date: moment(),
                laycan_from: moment(),
                laycan_to: moment(),
                billingandbankingdetails: {
                  due_date: moment(),
                },
              }
            );
            _onLeftSideListClick(postData.cargo_contract_id);
            setState(
              (pre) => ({
                ...pre,
                formData: setData,
                frmVisible: false,
              }),
              () => {
                setState((pre) => ({
                  ...pre,
                  frmVisible: true,
                  showSideListBar: true,
                }));
              }
            );
          } else if (
            response.message === "Voyage Charter has been deleted successfully."
          ) {
            // navigate("/add-voyage-charter");
            navigate("/add-cargo-contract");
            let setData = Object.assign(
              {},
              {
                id: 0,
                cp_date: moment(),
                laycan_from: moment(),
                laycan_to: moment(),
                billingandbankingdetails: {
                  due_date: moment(),
                },
              }
            );
            setState(
              (pre) => ({
                ...pre,
                formData: setData,
                frmVisible: false,
              }),
              () => {
                setState((pre) => ({
                  ...pre,
                  frmVisible: true,
                  showSideListBar: true,
                }));
              }
            );
          } else {
          }
        } else {
          openNotificationWithIcon("error", response.message);
        }
      });
    } catch (error) {
      console.error("An error occurred during the API call:", error);
      openNotificationWithIcon(
        "error",
        "An error occurred during the API call"
      );
    }
  };

  const _onLeftSideListClick = async (evt) => {
    try {
      const coaVciId = evt?.cargo_contract_id ? evt.cargo_contract_id : evt;
      const { isSetsLeftBtn, isShowImport } = state;
      setState((prevState) => ({ ...prevState, frmVisible: false }));
      const isSetsRightBtn = [
        { key: "menu", isDropdown: 0, withText: "Menu", type: "" },
        {
          key: "fix",
          isDropdown: 0,
          withText: "Fix",
          type: "",
          menus: null,
          event: (key, data) =>
            data && data.hasOwnProperty("id") && data["id"] > 0
              ? Modal.confirm({
                  title: "Confirm",
                  content: "Are you sure, you want to Fix it?",
                  onOk: () => fixedCargoContract(data),
                })
              : openNotification("Fix"),
        },
        {
          key: "reports",
          isDropdown: 0,
          withText: "Click to generate and view reports of the cargo contract",
          type: "Reports",
          showToolTip: true,
          menus: null,
          event: (key, data) => {
            data && data.id > 0
              ? openCargoContractReport(true, data.cargo_contract_id)
              : openNotificationWithIcon(
                  "info",
                  "Please select any item in the list!"
                );
          },
        },
      ];

      let _isSetsLeftBtn = Object.assign([], isSetsLeftBtn),
        _isSetsRightBtn = Object.assign([], isSetsRightBtn);

      // const pattern = /^\/edit-voyage-charter\/[A-Z0-9-]+$/;

      const pattern = /^\/edit-cargo-contract\/[A-Z0-9-]+$/;

      if (
        coaVciId &&
        !props.fullEstimate
        //  &&
        // location &&
        // (pattern.test(location.pathname) ||
        //   location.pathname === "/add-cargo-contract ")
      ) {
        navigate(`/edit-cargo-contract/${coaVciId}`);
        // alert("hi");
        // navigate(`/edit-voyage-charter/${coaVciId}`);
        // navigate(`/edit-cargo-contract/${coaVciId}`);
      }

      const response = await getAPICall(
        `${URL_WITH_VERSION}/cargo/edit?ae=${coaVciId}`
      );

      const respData = await response;
      const data = respData?.["data"];
      if (data && !data.hasOwnProperty("--"))
        data["--"] = [
          {
            load_ports_name_l: "Select Port",
            discharge_ports_name_d: "Select Port",
          },
        ];
      const sData = respData["row"];
      if (data.hasOwnProperty("loadoptions") && data.loadoptions.length > 0) {
        data.loadoptions.map((e) => {
          e["port_area_name"] = e.port_name;
        });
      }
      if (
        data.hasOwnProperty("dischargeoptions") &&
        data.dischargeoptions.length > 0
      ) {
        data.dischargeoptions.map((e) => {
          e["port_area_name"] = e.port_name;
        });
      }
      if (
        data.hasOwnProperty("itineraryoptions") &&
        data.itineraryoptions.length > 0
      ) {
        data.itineraryoptions.map((e) => {
          e["port_id_name"] = e.port_name;
        });
      }
      if (
        data.hasOwnProperty("rebillsettings") &&
        data.rebillsettings.length > 0
      ) {
        data.rebillsettings.map((e) => {
          e["cv_port_name"] = e.port_name;
        });
      }
      if (data.hasOwnProperty("billingandbankingdetails")) {
        for (let key in data.billingandbankingdetails) {
          if (!data["billingandbankingdetails"][key]) {
            data["billingandbankingdetails"][key] = undefined;
          }
        }
      }
      // if (data.hasOwnProperty('--') && data['--'].length > 0) {
      //   data['--'].map(e => {
      //     e['load_ports_name'] = e.load_port_name
      //     e['discharge_ports_name'] = e.discharge_port_name
      //   })
      // }

      let setData = Object.assign(
        {},
        {
          id: 0,
          cp_date: moment(),
          laycan_from: moment(),
          laycan_to: moment(),
          billingandbankingdetails: {
            due_date: moment(),
          },
        },
        data || {}
      );

      if (data.hasOwnProperty("is_fixed") && data["is_fixed"] === 1) {
        // delete _isSetsLeftBtn[3]
        delete _isSetsRightBtn[1];

        setData["disablefield"] = [
          "charterer",
          "cp_qty",
          "cp_unit",
          "vc_purchase_id",
          "cargo_name",
        ];

        // "freight_rate",freight_type  will always be enabled.
      }

      // if (data.hasOwnProperty('vc_purchase_id') && data['vc_purchase_id'] !== null) {

      //   delete _isSetsRightBtn[1]['menus'][0]
      // }
      if (data.hasOwnProperty("is_schedule") && data["is_schedule"] === 1) {
        _isSetsRightBtn[1] = {
          key: "estimate",
          isDropdown: 0,
          withText: "Estimate",
          type: "",
          menus: null,
          event: (key) => viewEstimate(),
        };
        //_isSetsRightBtn[2] = { key: 'voyagemanger', isDropdown: 0, withText: 'Voyage Manger', type: '', menus: null, event: key => viewVoyageManger() };
        delete _isSetsRightBtn[3];
        delete _isSetsRightBtn[1];
      }
      setState((prevState) => ({
        ...prevState,
        cargocoaVciID: coaVciId,
        secondaryData: sData,
        formData: setData,
        showSideListBar: false,
        isSetsLeftBtn: _isSetsLeftBtn,
        isSetsRightBtn: _isSetsRightBtn,
        selectedID: evt.id,
        frmVisible: true,
      }));
    } catch (err) {
      console.log("err", err);
    }
  };

  const openCargoContractReport = async (
    showCargoContractReports,
    cargoid = null
  ) => {
    const { cargocoaVciID } = state;
    // report APi
    const pattern = /^\/edit-voyage-charter\/[A-Z0-9-]+$/;
    try {
      if (
        cargoid ||
        cargocoaVciID ||
        (params.id && pattern.test(location.pathname))
      ) {
        let qParams = { e: cargoid || cargocoaVciID || params.id };
        let qParamString = objectToQueryStringFunc(qParams);

        // for report Api
        const responseReport = await getAPICall(
          `${URL_WITH_VERSION}/cargo/report?${qParamString}`
        );
        const respDataReport = await responseReport["data"];
        if (responseReport) {
          setState((prevState) => ({
            ...prevState,
            reportFormData: respDataReport,
            isShowCargoContractReports: showCargoContractReports,
          }));
        } else {
          openNotificationWithIcon("error", "Unable to show report", 5);
        }
      }
    } catch (err) {
      openNotificationWithIcon("error", "Something went wrong.", 5);
    }
  };

  const viewEstimate = () => {
    const { secondaryData } = state;
    if (secondaryData["type"] === "tcov") {
      navigate(`/edit-voyage-estimate/${secondaryData["estimate_id"]}`);
    } else if (secondaryData["type"] === "voyagerelet") {
      navigate(`/edit-voy-relet/${secondaryData["estimate_id"]}`);
    }
  };

  const viewVoyageManger = () => {
    const { secondaryData } = state;
    navigate(`/voyage-manager/${secondaryData["vm"]}`);
  };

  const onCloseRevision = () => {
    setState({
      ...state,
      visibleRevision: false,
    });
  };

  const onCloseModal = (visible) => {
    if (
      visible === true &&
      state.formData &&
      state.formData["id"] &&
      state.formData["id"] * 1 > 0
    ) {
      setState({ ...state, isVisibleCopy: visible });
    } else if (
      visible === true &&
      state.formData &&
      !state.formData.hasOwnProperty("id")
    ) {
      openNotificationWithIcon(
        "error",
        <div
          dangerouslySetInnerHTML={{
            __html: "Please Save Contract First and Then Click on Copy.",
          }}
        />
      );
    } else if (visible === false) {
      setState({ ...state, isVisibleCopy: visible });
    }
  };

  const copyContract = (data, innerCB) => {
    let cpData = Object.assign(
      { cargo_id: state.formData["cargo_contract_id"] },
      data
    );
    setState({ ...state, doCopyContract: false });
    postAPICall(`${URL_WITH_VERSION}/cargo/copy`, cpData, "post", (resp) => {
      if (resp.data) {
        openNotificationWithIcon("success", resp.message);
        let rows = Object.assign(data, {
          contractcopy: resp.row.contract,
          id: 0,
        });
        setState({ ...state, copyFormData: rows, doCopyContract: true });
      } else {
        let dataMessage = resp.message;
        let msg = "<div className='row'>";

        if (typeof dataMessage !== "string") {
          Object.keys(dataMessage).map(
            (i) =>
              (msg += "<div className='col-sm-12'>" + dataMessage[i] + "</div>")
          );
        } else {
          msg += dataMessage;
        }

        msg += "</div>";
        openNotificationWithIcon(
          "error",
          <div dangerouslySetInnerHTML={{ __html: msg }} />,
          5
        );
      }
    });
  };

  const updateCopyContarct = (data, innerCB) => {
    setState({ ...state, doCopyContract: false });
    postAPICall(
      `${URL_WITH_VERSION}/cargo/copy/update`,
      data,
      "put",
      (resp) => {
        if (resp.data) {
          openNotificationWithIcon("success", resp.message);
          setState({ ...state, doCopyContract: true });
          onCloseModal(false);
          if (innerCB && typeof innerCB === "function") {
            innerCB();
          }
        } else {
          let dataMessage = resp.message;
          let msg = "<div className='row'>";

          if (typeof dataMessage !== "string") {
            Object.keys(dataMessage).map(
              (i) =>
                (msg +=
                  "<div className='col-sm-12'>" + dataMessage[i] + "</div>")
            );
          } else {
            msg += dataMessage;
          }

          msg += "</div>";
          openNotificationWithIcon(
            "error",
            <div dangerouslySetInnerHTML={{ __html: msg }} />,
            5
          );
        }
      }
    );
  };

  const toggleCargoRightMenu = async (val, type = "save", data = null) => {
    const { formData } = state;
    const {
      contract_type,
      booking_no,
      my_company,
      lob,
      invoice_by,
      trade_area,
      cargo_name,
      min_inv_unit,
      min_inv_qty,
      cargo_group,
      bl_qty,
      bl_unit,
      currency,
      load_disch_currency,
      cp_date,
      cp_qty,
      cp_unit,
      freight_bill_via,
      min_qty,
      max_qty,
      freight_type,
      load_dem,
      load_dep,
      cp_place,
      freight_rate,
      discharge_dem,
      discharge_dep,
      laycan_from,
      laycan_to,
      nomiated_vessel,
      cp_draft_m,
      vessel_type,
      vc_purchase_id,
      id,
      cargo_status,
      voyage,
      m3_mt,
      ft3_mt,
      charterer,
      fixed_by,
    } = formData;

    //const response = await getAPICall(`${URL_WITH_VERSION}/voyagecargo/list`);
    //const respData = await response['data'];

    //let vc_purchaseID = 0

    let postFormData = Object.assign({
      id: 0,
      coa_vc_purchase_id: vc_purchase_id,
      cargo_contract_id: id,
      contract_type,
      booking_no,
      my_company,
      lob,
      c_status: cargo_status,
      invoice_by,
      trade_area,
      cargo_name,
      min_inv_qty,
      min_inv_unit,
      voyage_no: voyage,
      cargo_group,
      bl_qty,
      bl_unit,
      stow_m3_mt: m3_mt,
      stow_ft3_mt: ft3_mt,
      charterer,
      currency,
      load_disch_currency,
      cp_date,
      cp_qty,
      cp_unit,
      freight_bill_via,
      min_qty: min_qty,
      max_qty,
      freight_type,
      load_dem,
      load_dep,
      cp_place,
      freight_rate,
      discharge_dem,
      discharge_dep,
      laycan_from,
      laycan_to,
      nominated_vessel: nomiated_vessel,
      // user_fixed_by: fixed_by,
      cp_draft_m,
      vessel_type,
    });

    if (
      postFormData.billingandbankingdetails &&
      postFormData.billingandbankingdetails.id
    ) {
      delete postFormData.billingandbankingdetails.id;
    }
    if (postFormData.broker && postFormData.broker.length > 0) {
      postFormData.broker.map((e) => {
        delete e.id;
      });
    }
    if (
      postFormData.extrafreightterm &&
      postFormData.extrafreightterm.length > 0
    ) {
      postFormData.extrafreightterm.map((e) => {
        delete e.id;
      });
    }
    if (
      postFormData.itineraryoptions &&
      postFormData.itineraryoptions.length > 0
    ) {
      postFormData.itineraryoptions.map((e) => {
        delete e.id;
      });
    }
    if (postFormData.loadoptions && postFormData.loadoptions.length > 0) {
      postFormData.loadoptions.map((e) => {
        delete e.id;
      });
    }
    if (postFormData.rebillsettings && postFormData.rebillsettings.length > 0) {
      postFormData.rebillsettings.map((e) => {
        delete e.id;
      });
    }
    if (postFormData.revexpinfo && postFormData.revexpinfo.length > 0) {
      postFormData.revexpinfo.map((e) => {
        delete e.id;
      });
    }
    if (
      postFormData.dischargeoptions &&
      postFormData.dischargeoptions.length > 0
    ) {
      postFormData.dischargeoptions.map((e) => {
        delete e.id;
      });
    }

    if (val) {
      if (type == "edit") {
        // const response = await getAPICall(`${URL_WITH_VERSION}/voyagecargo/list`);
        // const respData = await response['data'];
        // let filterData = respData.filter(e => e.id == data.ID)
        //if (filterData && filterData.length > 0) {
        let id = vc_purchase_id;
        postFormData = { params: { id } };
        navigate(`/edit-voyage-cargo/${id}`);
        //setState({ ...state, isVisible: val, postFormData });
        //}
        // if (data && data.hasOwnProperty("index") && data["index"] > -1) {
        //   let id = formData["-"][data["index"]]["id"];
        //   postFormData = { "params": { id } }
        //   setState({ ...state, isVisible: val, postFormData });
        // }
      } else {
        if (formData && formData.hasOwnProperty("id") && formData["id"] > 0) {
          setState({ ...state, isVisible: val, postFormData });
        } else openNotification("Create VC (purchase)");
      }
    } else {
      setState({ ...state, isVisible: val, postFormData: [] });
    }
  };

  const isContactAttachmentOk = () => {
    setTimeout(() => {
      setState({ ...state, visibleContactAttachment: false });
    }, 3000);
  };

  const fixedCargoContract = (data) => {
    let evt = data;
    postAPICall(
      `${URL_WITH_VERSION}/cargo/fix`,
      { cargo_contract_id: data["cargo_contract_id"] },
      "POST",
      (respData) => {
        if (respData.data) {
          openNotificationWithIcon("success", respData.message);
          _onLeftSideListClick(evt);
        } else {
          let dataMessage = respData.message;
          let msg = "<div className='row'>";

          if (typeof dataMessage !== "string") {
            Object.keys(dataMessage).map(
              (i) =>
                (msg +=
                  "<div className='col-sm-12'>" + dataMessage[i] + "</div>")
            );
          } else {
            msg += dataMessage;
          }

          msg += "</div>";
          openNotificationWithIcon(
            "error",
            <div dangerouslySetInnerHTML={{ __html: msg }} />,
            5
          );
        }
      }
    );
  };

  let formData1 = redux_state.reduxStore?.["tcov_full_estimate_form"];

  let formData2 = redux_state.reduxStore?.["VOYAGE_RELET"];


  

  const isContactAttachmentCancel = () =>
    setState({ ...state, visibleContactAttachment: false });

  const importData = async (data) => {
    if (!data.id) {
      openNotificationWithIcon("info", "Please save voyage charter first.", 3);
      return;
    }
    if (
      data &&
      data.hasOwnProperty("is_schedule") &&
      data["is_schedule"] != 1
    ) {
      if (data && data.hasOwnProperty("is_fixed") && data["is_fixed"] == 0) {
        openNotificationWithIcon(
          "info",
          "Please Fix this voyage chareter first, or select other fixed Voyage charter.",
          3
        );
        return;
      }
      const voyage_estinmate_dwt = parseFloat(formData1?.dwt);
      const voyage_relate_dwt = parseFloat(formData2?.dwt);

      const cp_qty = parseFloat(state?.formData?.cp_qty);


      if (cp_qty > voyage_estinmate_dwt || cp_qty > voyage_relate_dwt) {
        openNotificationWithIcon(
          "info",
          "CP Quantity cannot exceed the Vessel's DWT",
          3
        );
      } 

      if (props.isVoyageRelete) {
        // let obj = {
        //   cargo_name: data && data.cargo_name,
        //   charterer: data && data.charterer,
        //   f_rate: data && data.freight_rate,
        //   f_type: data && data.freight_type,
        //   curr: data && data.currency,
        // };
        // props.cargoImport(obj);
        data["sp_type"] = 186;
        data["index"] = props.formData.index;
        data["serial_no"] = props.formData.serial_no;

        await props.getCargo(data);
        await props.onCancelModal();
      } else {
        data["sp_type"] = 186;
        props.getCargo(data);
      }
    } else {
      openNotificationWithIcon(
        "info",
        "This Voyage Charter has been already linked with voyage manager.",
        3
      );
      return;
    }
  };

  const onClickExtraIcon = async (action, data) => {
    let delete_id = data && data.id;
    let groupKey = action["gKey"];
    let frm_code = "";
    if (groupKey == "Rev/Exp Info") {
      groupKey = "revexpinfo";
      frm_code = "cargo_contract_rev_exp";
    }
    if (groupKey == "Rebill Settings") {
      groupKey = "rebillsettings";
      frm_code = "cargo_contract_rebill_settings";
    }
    if (groupKey == "Itinerary Options") {
      groupKey = "itineraryoptions";
      frm_code = "cargo_contract_itinerary_option_form";
    }
    if (groupKey == "Load Options") {
      groupKey = "loadoptions";
      frm_code = "cargo_contract_form";
    }
    if (groupKey == "Discharge Options") {
      groupKey = "dischargeoptions";
      frm_code = "cargo_contract_form";
    }
    if (groupKey == "--") {
      groupKey = "--";
      frm_code = "cargo_contract_pricing";
    }
    if (groupKey == "Extra Freight Term") {
      groupKey = "extrafreightterm";
      frm_code = "cargo_contract_pricing";
    }

    if (groupKey == "Broker") {
      groupKey = "broker";
      frm_code = "cargo_contract_pricing";
    }
    if (groupKey && delete_id && Math.sign(delete_id) > 0 && frm_code) {
      let data1 = {
        id: delete_id,
        frm_code: frm_code,
        group_key: groupKey,
      };
      postAPICall(
        `${URL_WITH_VERSION}/tr-delete`,
        data1,
        "delete",
        (response) => {
          if (response && response.data) {
            openNotificationWithIcon("success", response.message);
          } else {
            openNotificationWithIcon("error", response.message);
          }
        }
      );
    }
  };
  const getCargo = (data) => {
    setState({ ...state }, () => getCargoData(data));
  };
  const getCargoData = (data) => {};

  const onClickAddCargo = (cargoContractData = {}, data = {}) => {
    setState(
      { ...state, cargoData: cargoContractData, fullFormData: data },
      () => setState({ ...state, visisbleCargoChild: true })
    );
  };
  const onCancel = () => setState({ ...state, visisbleCargoChild: false });

  const {
    isShowTCOV,
    visisbleCargoChild,
    isShowEstimate,
    cargoData,
    estimateCargoData,
    isShowImport,
    isSetsRightBtn,
    formDataReport,
    reportFormData,
    loadComponent,
    title,
    visibleDrawer,
    frmName,
    formData,
    copyFrmName,
    copyFormData,
    cargoContracts,
    isSetsLeftBtn,
    isVisible,
    extraFormFields,
    isVisibleCopy,
    doCopyContract,
    showSideListBar,
    isShowCargoContractReports,
    visibleContactAttachment,
    postFormData,
    selectedID,
    frmVisible,
  } = state;

  let isSetsLeftBtnArr = [],
    isSetsRightBtnArr = [];

  if (props.hasOwnProperty("isDisabled") && props.isDisabled) {
    isSetsLeftBtnArr = [
      {
        id: "1",
        key: "menu-fold",
        type: <MenuFoldOutlined />,
        withText: "List",
        showToolTip: true,
        event: "menu-fold",
      },
      {
        id: "3",
        key: "save",
        type: <SaveOutlined />,
        withText: "Save",
        showToolTip: true,
        event: (key, data) => saveFormData(data),
      },
    ];
    isSetsRightBtnArr = [
      {
        key: "reports",
        isDropdown: 0,
        withText: "Click to generate and view reports of the cargo contract",
        type: "Reports",
        showToolTip: true,
        menus: null,
        event: (key, data) =>
          data && data.id > 0
            ? openCargoContractReport(true, data.cargo_contract_id)
            : openNotificationWithIcon(
                "info",
                "Please select any item in the list!"
              ),
      },
    ];
  } else {
    isSetsRightBtnArr = Object.assign([], isSetsRightBtn);
    if (isShowImport === true) {
      isSetsRightBtnArr.push({
        key: "import",
        isDropdown: 0,
        withText: "Import",
        type: "",
        menus: null,
        event: (key, data) => importData(data),
      });
      // isSetsRightBtnArr = [
      //   { key: 'import', isDropdown: 0, withText: 'Import', type: '', menus: null, event: (key, data) => importData(data) }
      // ]
    }
    if (props.isShowAddButton === false) {
      isSetsLeftBtnArr = [
        {
          id: "1",
          key: "menu-fold",
          type: <MenuFoldOutlined />,
          withText: "List",
          showToolTip: true,
          event: "menu-fold",
        },
        {
          id: "3",
          key: "save",
          type: <SaveOutlined />,
          withText: "Save",
          showToolTip: true,
          event: (key, data) => saveFormData(data),
        },
      ];
    } else {
      isSetsLeftBtnArr = isSetsLeftBtn?.filter(
        (item) =>
          !(
            formData &&
            formData.hasOwnProperty("id") &&
            formData.id <= 0 &&
            item.key == "delete"
          )
      );
    }

    isSetsRightBtnArr.push({
      key: "reports",
      isDropdown: 0,
      withText: "Click to generate and view reports of the cargo contract",
      type: "Reports",
      showToolTip: true,
      menus: null,
      event: (key, data) =>
        data && data.id > 0
          ? CargoContractReports(true)
          : openNotificationWithIcon(
              "info",
              "Please save voyage charter first."
            ),
    });
  }

  if (props && props.import == true) {
    isSetsRightBtnArr.push({
      key: "import",
      isDropdown: 0,
      withText: "Import",
      type: "",
      menus: null,
      event: (key, data) => importData(data),
    });
  }
  // else{
  //   isSetsRightBtnArr.push(
  //     {
  //       key: 'addcargo',
  //       isDropdown: 0,
  //       withText: 'Add Cargo',
  //       type: '',
  //       menus: null,
  //       event: (key, data) => {
  //         onClickAddCargo({}, data);
  //       },
  //     },
  //   )
  // }

  // isSetsRightBtnArr.push(  {
  //   key: "reports",
  //   isDropdown: 0,
  //   withText: "Click to generate and view reports of the cargo contract",
  //   type: "Reports",
  //   showToolTip: true,
  //   menus: null,
  //   event: (key, data) =>
  //     data && data.id > 0
  //       ? CargoContractReports(true)
  //       : openNotificationWithIcon(
  //           "info",
  //           "Please save voyage charter first."
  //         ),
  // })

  return (
    <div className="tcov-wrapper full-wraps voyage-fix-form-wrap cargo ">
      <Layout className="layout-wrapper">
        <Layout>
          <Content className="content-wrapper">
            <Row gutter={16} style={{ marginRight: 0 }}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <div className="body-wrapper">
                  <div className="row">
                    <div className="col-md-12">
                      <article className="article">
                        <div className="">
                          <div className="box-body fieldscroll-wraps">
                            <Row>
                              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                {frmName && frmVisible && extraFormFields ? (
                                  <article className="">
                                    <div className="box box-default">
                                      <div className="box-body" key={counter}>
                                        <NormalFormIndex
                                          key={"key_" + frmName + "_0"}
                                          formClass="label-min-height"
                                          formData={
                                            formData?.hasOwnProperty(
                                              "cargo_contract_id"
                                            )
                                              ? formData
                                              : formDataReport
                                          }
                                          showForm={true}
                                          frmCode={frmName}
                                          inlineLayout={true}
                                          extraFormFields={extraFormFields}
                                          getVesselNameFromChild={
                                            getVesselNameFromChild
                                          }
                                          openLoadPortDrawer={
                                            openLoadPortDrawer
                                          }
                                          showToolbar={[
                                            {
                                              leftWidth: 8,
                                              rightWidth: 16,
                                              isLeftBtn: [
                                                { isSets: isSetsLeftBtnArr },
                                              ],
                                              isRightBtn: [
                                                {
                                                  key: "s2",
                                                  isSets: [
                                                    {
                                                      key: "Contracts",
                                                      isDropdown: 1,
                                                      withText: "Contracts",
                                                      type: "Contracts",
                                                      showToolTip: true,
                                                      menus: [
                                                        {
                                                          href: null,
                                                          icon: null,
                                                          label:
                                                            "Go To COA(Cargo)",
                                                          modalKey:
                                                            "Go To COA(Cargo)",
                                                          event: (key) => {
                                                            navigate(
                                                              "/add-coa-contract"
                                                            );
                                                          },
                                                        },
                                                        {
                                                          href: null,
                                                          icon: null,
                                                          label:
                                                            "Go To COA(Cargo) List",
                                                          modalKey:
                                                            "Go To COA(Cargo) List",
                                                          event: (key) => {
                                                            navigate(
                                                              "/coa-list"
                                                            );
                                                          },
                                                        },

                                                        {
                                                          href: null,
                                                          icon: null,
                                                          label:
                                                            "Go To COA(VC)",
                                                          modalKey:
                                                            "Go To COA(VC)",
                                                          event: (key) => {
                                                            navigate(
                                                              "/add-coa-vci"
                                                            );
                                                          },
                                                        },
                                                        {
                                                          href: null,
                                                          icon: null,
                                                          label:
                                                            "Go To COA(VC) List",
                                                          modalKey:
                                                            "Go To COA(VC) List",
                                                          event: (key) => {
                                                            navigate(
                                                              "/list-coa-vci"
                                                            );
                                                          },
                                                        },
                                                        {
                                                          href: null,
                                                          icon: null,
                                                          label:
                                                            "Go To VC(Purchase)",
                                                          modalKey:
                                                            "Go To VC(Purchase)",
                                                          event: (key) => {
                                                            navigate(
                                                              "/add-voyage-cargo"
                                                            );
                                                          },
                                                        },
                                                        {
                                                          href: null,
                                                          icon: null,
                                                          label:
                                                            "Go To VC(Purchase) List",
                                                          modalKey:
                                                            "Go To VC(Purchase) List",
                                                          event: (key) => {
                                                            navigate(
                                                              "/voyage-cargo-list"
                                                            );
                                                          },
                                                        },
                                                      ],
                                                    },

                                                    state?.formData
                                                      ?.is_fixed === "0" ||
                                                      (!state?.formData
                                                        ?.is_fixed && {
                                                        key: "fix",
                                                        isDropdown: 0,
                                                        withText:
                                                          "Click to fix the current cargo contract details",
                                                        type: "Fix",
                                                        showToolTip: true,
                                                        menus: null,
                                                        event: (key, data) =>
                                                          data &&
                                                          data.hasOwnProperty(
                                                            "id"
                                                          ) &&
                                                          data["id"] > 0
                                                            ? Modal.confirm({
                                                                title:
                                                                  "Confirm",
                                                                content:
                                                                  "Are you sure, you want to Fix it?",
                                                                onOk: () =>
                                                                  fixedCargoContract(
                                                                    data
                                                                  ),
                                                              })
                                                            : openNotification(
                                                                "Fix"
                                                              ),
                                                      }),
                                                    {
                                                      key: "reports",
                                                      isDropdown: 0,
                                                      withText:
                                                        "Click to generate and view reports of the cargo contract",
                                                      type: "Reports",
                                                      showToolTip: true,
                                                      menus: null,
                                                      event: (key, data) =>
                                                        data && data.id > 0
                                                          ? openCargoContractReport(
                                                              true,
                                                              data.id
                                                            )
                                                          : openNotificationWithIcon(
                                                              "info",
                                                              "Please save Cargo Contract first."
                                                            ),
                                                    },
                                                    props &&
                                                      props.isShowImport && {
                                                        key: "import",
                                                        isDropdown: 0,
                                                        withText: "Import",
                                                        type: "",
                                                        menus: null,
                                                        event: (key, data) =>
                                                          importData(data),
                                                      },
                                                  ],
                                                },
                                              ],
                                              isResetOption: false,
                                            },
                                          ]}
                                          isShowFixedColumn={[
                                            "Load Options",
                                            "Rev/Exp Info",
                                            "Discharge Options",
                                            "Itinerary Options",
                                            "Broker",
                                          ]}
                                          showSideListBar={showSideListBar}
                                          sideList={{
                                            selectedID: selectedID,
                                            showList: true,
                                            title: "Voyage Charter List",
                                            uri: "/cargo/list?l=0",
                                            columns: [
                                              "cargo_contract_id",
                                              "charterer_name",
                                              "status_name",
                                            ],
                                            icon: true,
                                            rowClickEvent: (evt) =>
                                              _onLeftSideListClick(evt),
                                            statusList: "",
                                          }}
                                          tableRowDeleteAction={(
                                            action,
                                            data
                                          ) => onClickExtraIcon(action, data)}
                                          // staticTabs={{
                                          //   "Dem/Des Term": () => { return <DemdesTerm /> }
                                          // }}
                                        />
                                      </div>
                                    </div>
                                  </article>
                                ) : undefined}
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </article>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Content>
        </Layout>

        <RightBarUI
          pageTitle="voyage-cargo-righttoolbar"
          callback={(data, options) => onClickRightMenu(data, options)}
        />
        {visibleDrawer === true ? (
          <Drawer
            title={state.title}
            placement="right"
            closable={true}
            onClose={onCloseDrawer}
            open={state.visibleDrawer}
            getContainer={false}
            style={{ position: "absolute" }}
            width={state.width}
            maskClosable={false}
            className="drawer-wrapper-container"
          >
            <div className="tcov-wrapper">
              <div className="layout-wrapper scrollHeight">
                <div className="content-wrapper noHeight">
                  {state.loadComponent}
                </div>
              </div>
            </div>
          </Drawer>
        ) : undefined}

        <Modal
          title="Copy Setup"
          open={isVisibleCopy}
          // width={1200}
          onCancel={() => onCloseModal(false)}
          footer={false}
        >
          <article className="article">
            <div className="box box-default">
              <div className="box-body">
                {doCopyContract ? (
                  <NormalFormIndex
                    key={"key_" + copyFrmName + "_0"}
                    formClass="label-min-height"
                    formData={copyFormData}
                    showForm={true}
                    frmCode={copyFrmName}
                    inlineLayout={true}
                    showButtons={[
                      {
                        id: "create",
                        title: "Copy Contract",
                        type: "default",
                        event: (data, innerCB) => {
                          copyContract(data, innerCB);
                        },
                      },
                      { id: "cancel", title: "Reset", type: "danger" },
                      {
                        id: "save",
                        title: "Save",
                        type: "primary",
                        event: (data, innerCB) => {
                          updateCopyContarct(data, innerCB);
                        },
                      },
                    ]}
                    isShowFixedColumn={["-------", "--------"]}
                  />
                ) : (
                  <div className="col col-lg-12">
                    <Spin tip="Loading...">
                      <Alert
                        message=" "
                        description="Please wait..."
                        type="info"
                      />
                    </Spin>
                  </div>
                )}
              </div>
            </div>
          </article>
        </Modal>
        {visisbleCargoChild ? (
          <Modal
            style={{ top: "2%" }}
            open={visisbleCargoChild}
            title="Cargo Term Detail"
            onCancel={onCancel}
            footer={null}
            width={"90%"}
            maskClosable={false}
          >
            <CargoChildForm
              isShowFixedColumn={["-"]}
              onSaveEvent={(data) => getCargo(data)}
              formData={cargoData}
            />
          </Modal>
        ) : undefined}

        {isVisible === true ? (
          <Modal
            title={
              postFormData && postFormData.hasOwnProperty("params")
                ? "Edit VC(purchase)"
                : "Create VC(purchase)"
            }
            open={isVisible}
            width="95%"
            onCancel={() => toggleCargoRightMenu(false)}
            style={{ top: "10px" }}
            bodyStyle={{ height: 790, overflowY: "auto" }}
            footer={null}
          >
            <div className="body-wrapper">
              <article className="article">
                <div className="box box-default">
                  {postFormData && postFormData.hasOwnProperty("params") ? (
                    <CargoDetails
                      history={props.history}
                      match={postFormData}
                      isDisabled={true}
                      modalCloseEvent={() => toggleCargoRightMenu(false)}
                    />
                  ) : (
                    <CargoDetails
                      history={props.history}
                      formData={postFormData}
                      coaVciId={formData}
                      showSideListBar={true}
                      isDisabled={true}
                      modalCloseEvent={() => toggleCargoRightMenu(false)}
                    />
                  )}
                </div>
              </article>
            </div>
          </Modal>
        ) : undefined}
      </Layout>

      {isShowCargoContractReports ? (
        <Modal
          style={{ top: "2%" }}
          title="Reports"
          open={isShowCargoContractReports}
          onCancel={() =>
            setState({ ...state, isShowCargoContractReports: false })
          }
          width="95%"
          footer={null}
        >
          <CargoContractReports data={reportFormData} />
        </Modal>
      ) : undefined}

      {visibleContactAttachment ? (
        <Modal
          open={visibleContactAttachment}
          title="Upload Attachment ( Upload Cargo Contract Details )"
          onOk={isContactAttachmentOk}
          onCancel={isContactAttachmentCancel}
          footer={null}
          width={1000}
          maskClosable={false}
        >
          <Attachment
            uploadType="Address Book"
            directory={
              formData.hasOwnProperty("estimate_id")
                ? formData["estimate_id"]
                : null
            }
          />
        </Modal>
      ) : undefined}
      {isShowEstimate && (
        <Modal
          style={{ top: "2%" }}
          open={isShowEstimate}
          title="Estimate Create"
          onCancel={() => redirectToVoyageRelet(false)}
          footer={null}
          width={"90%"}
          maskClosable={false}
        >
          <FullEstimate formData={estimateCargoData} />
        </Modal>
      )}
      {isShowTCOV && (
        <Modal
          style={{ top: "2%" }}
          open={isShowTCOV}
          title="Create TCOV"
          onCancel={() => redirectToVoyageRelet(false)}
          footer={null}
          width={"90%"}
          maskClosable={false}
        >
          <TCOV cargosData={estimateCargoData} />
        </Modal>
      )}
    </div>
  );
};

export default CargoContract;
