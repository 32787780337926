import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Input,
  Table,
  Layout,
  Select,
  Row,
  Col,
  Modal,
  Button,
  Divider,
  Radio,
  InputNumber,
  List,
  Alert,
  Checkbox,
  Spin,
} from "antd";
import "../../styles/googlemap.scss";
import axios from "axios";
import mapboxgl from "mapbox-gl";
import "../../styles/style.scss";
//import "../track-my-fleet/mapstyle.css";
import "../track-vessel/mapstyle.css";
import "mapbox-gl/dist/mapbox-gl.css";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import StaticMode from "@mapbox/mapbox-gl-draw-static-mode";
import ShipDetails from "./ShipDetails";
import { apiDeleteCall, getAPICall, useStateCallback } from "../../shared";
// import Icon from 'src/@core/components/icon'
import { SearchOutlined } from "@ant-design/icons";
import {
  TO_FIXED_2_NUM,
  EMPTY_2_NUM,
  removeCommon,
  ldTermsOptions,
  DEFAULT_VALUE,
  addMapLayers,
  updateMapLineLayer,
  updateMapPointLayer,
  initformdata,
  getCo2ValFormula,
  tableColumns,
  defRouteOptions,
  canalPassoptions,
  piracyoptions,
  routeOptions,
  getMapPlaceItem,
  updateMapData,
  defcolumns,
  CustomSpinner,
  hideHighRiskArea,
  showHighRiskArea,
  showEcaArea,
  hideEcaArea,
} from "./portUtil.js";
import URL_WITH_VERSION, {
  postAPICall,
  IMAGE_PATH,
  openNotificationWithIcon,
} from "../../shared";
import useDebounce from "./customhook";
import SelectedVesselView from "../track-vessel/selected-vessel-view.js";
import FilterVessel from "../track-vessel/filterVessel.js";
import LiveVesselSearch from "../track-vessel/live-vessel-search.js";
import ToolBar from "./ToolBar.js";
import VesselLargeListFilter from "../track-vessel/vessel-large-list-filter.js";
import GenerateCargoEnquiry from "../chartering/routes/tcov/modals/GenerateCargoEnquiry.js";
import GenerateTonnageEnquiry from "../chartering/routes/tcov/modals/GenerateTonnageEnquiry.js";
import { eca_polygons, high_risk_area } from "./portUtil";
import "./Style.css";
import DefaultVesselModal from "./DefaultVesselModal.js";
import { PortDistanceSteps } from "./PortDistanceSteps.js";
import TrackMyFleetTable from "./trackMyFleetTable.js";
import { debounce } from "lodash";
const REACT_APP_MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN;
mapboxgl.workerClass =
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default; // eslint-disable-line
mapboxgl.accessToken = REACT_APP_MAPBOX_TOKEN;

const { Content } = Layout;
// const Option = Select.Option;
const { Option } = Select;

let allCoordinates = [];
let pointLayerData = [];
let mapClickTableData = [];

const FILTER_TYPES = {
  liveSearch: "live_search",
  localSearch: "local_search",
  allDataFilter: "all_data_filter",
};

let defmarkers = [];

const searchLabel = "Search Vessel by it's Name or IMO Number";

const PortMap = (props) => {
  const [portData, setPortData] = useStateCallback({
    checkedList: defRouteOptions,
    visibleModal: false,
    title: "",
    triangleCoords: [],
    poly_lat_lng: [],
    loading: false,
    shipData: null,
    vessel_name: "Spring",
    port_from: null,
    port_to: null,
    distanceData: [],
    spd: "",
    fetchInProgress: false,
    loadPortInProgress: false,
    selectOptions: [],
    finalPortFrom: "",
    finalPortTo: "",
    coordinates: [],
    lng: 0.0,
    lat: 0.0,
    zoom: 1.06,
    portList: [], // Port list for dropdown and get detail for tables
    portPoints: [], // Port points for call APIs and set into table
    tableData: [], // Display table data
    mapBoxPoints: [],
    allCoordinates: [],
    port_lng: 0.0,
    port_lat: 0.0,
    map: {},
    coordinates: [],
    searchedItem: [],
    lng: props.data && props.data.length > 0 ? props.data[0].vessel_lat : 0.0,
    lat: props.data && props.data.length > 0 ? props.data[0].vessel_lon : 0.0,
    visibleModal: false,
    data: props.mapData,
    filterData: props.mapData,
    allData: props,
    selectedData: null,
    selectedPropData: null,
    serchedData: "all",
    filterType: "",
    liveSearchValue: "",
    liveSearchList: [],
    isLiveDetailLoading: false,
    liveSearchResult: searchLabel,
    showlivelocationdata: props.livemapiconid,
    isShowLocationFromtable: props.isShowLocationFromtable || false,
    isGlobalShipOpen: false,
    isVesselListShow: false,
    opencargoenquiryModal: false,
    openTonnageEnquiryModal: false,
    vesselPositionDetails: {},
    isPortDistTable: false,
    mapDataArr: [],
    stepData: [],
    isInMyFleet: false,
  });
  const fleetTableRef = useRef();
  const [projections, setprojections] = useState("mercator");
  const [searchport, setSearchport] = useState("");
  const map = useRef(null);
  const MapRef = useRef(null);
  const drawRef = useRef(null);
  const [showDrawButtons, setShowDrawButtons] = useState(false);
  const [formdata, setFormData] = useState(initformdata);
  const [isDistancCalModal, setDistanceModal] = useState(false);
  const debouncedSearchTerm = useDebounce(searchport, 1000);
  const [isLoadedMap, setIsLoadedMap] = useState(false);
  const [featuresData, setFeaturesData] = useState([]);
  const [isEcaVisible, setIsEcaVisible] = useState(false);
  const [isHraVisible, setIsHraVisible] = useState(false);
  const [isMapModal, setIsMapModal] = useState(false);
  const [defaultVessel, setDefaultVessel] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [defaultModalInfo, setDefaultModalInfo] = useState({});
  const [defaultModal, setDefaultModal] = useState(false);
  const [visible, setVisible] = useState(false);
  const [isData, setIsData] = useState(false);
  const [istrackMyFleet, setIsTrackFleet] = useState(false);
  const [ports, setports] = useState([]);
  const [isbunkerPrice, setIsBunkerPrice] = useState(false);
  const [bunkerPrice, setBunkerPrice] = useState({});
  const [activeButton,setActiveButton] = useState("LiveVesselTable");
  const marker = useRef(null);
  const newSrc = IMAGE_PATH + "icons/mapArrowGreen.png";
  let colorFilter = "hue-rotate(0deg)";
  let heading = 0;

  const scrollToTable = () => {
    if (fleetTableRef.current) {
      fleetTableRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const scrollToMap = () => {
    if (MapRef.current) {
      MapRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  useEffect(() => {
    initilLoad();
  }, [props?.mapData?.length]);

  useEffect(() => {
    const dataset = portData.searchedItem;
    if (props.isShowLocationFromtable && portData.searchedItem) {
      getVesselIdByImoNumber(dataset.imo_no, dataset, true);
    }
  }, [props.isShowLocationFromtable]);
  const initilLoad = () => {
    let mapLocation = [];
    props?.mapData?.map((item) => {
      return mapLocation.push(getMapPlaceItem(item));
    });
    setFeaturesData(mapLocation);
    setIsLoadedMap(true);
  };

  const searchedVessel = (searchItem) => {
    setPortData((prevState) => ({
      ...prevState,
      mapDataArr: [searchItem],
      searchedItem: [searchItem],
    }));
  };

  useEffect(() => {
    if (isLoadedMap) {
      maprender();
    }
    return () => {
      setIsLoadedMap(false);
    };
  }, [isLoadedMap]);

  useEffect(() => {
    if (debouncedSearchTerm) {
      loadAPI(debouncedSearchTerm);
    }
  }, [debouncedSearchTerm]);

  // useEffect(() => {
  //   const defaultVesselFunc = async () => {
  //     try {
  //       const url = `${process.env.REACT_APP_MT_URL}marine/get-vessel-positions?v=9&timespan=180&limit=2000`;
  //       const resp = await getAPICall(url);
  //       if (resp) {
  //         setDefaultVessel(resp.data.DATA || []);
  //         setFilteredData(resp.data.DATA || []);
  //       }
  //       // if (isbunkerPrice) {
  //       setIsData(true);

  //       const fastData = await getAPICall(
  //         `${URL_WITH_VERSION}/port-bunker-activity/spotprice/fast`
  //       );

  //       if (fastData?.data) {
  //         setIsData(false);
  //       }
  //       const liveData = await getAPICall(
  //         `${URL_WITH_VERSION}/port-bunker-activity/spotprice/live`
  //       );
  //       const respdata = (await liveData?.data)
  //         ? liveData?.data
  //         : fastData?.data;

  //       console.log("RESPONCEDATA--------------", respdata);
  //       let allPorts = {};
  //       for (let item of respdata.BunkerExSpotPrices) {
  //         allPorts[item.Port] = {
  //           portname: item.Port,
  //           fuelType: "",
  //           fuelprice: "",
  //           portCoordinates: [item.Longitude, item.Latitude],
  //         };
  //       }
  //       for (let item of respdata.BunkerExSpotPrices) {
  //         if (allPorts[item.Port]) {
  //           allPorts[item.Port].fuelType += item.Grade + " ";
  //           allPorts[item.Port].fuelprice += item.Price + " ";
  //         }
  //       }
  //       let PortNameAndCoordinateArr = [];
  //       for (let key in allPorts) {
  //         PortNameAndCoordinateArr.push(allPorts[key]);
  //       }
  //       setports(PortNameAndCoordinateArr);
  //       // setDefaultVessel(resp.data.DATA || []);
  //       // setFilteredData(resp.data.DATA || []);
  //       setIsData(false);
  //       // }
  //     } catch (e) {
  //       console.error(e);
  //       setIsData(false);
  //     }
  //   };

  //   defaultVesselFunc();
  // }, []);

  useEffect(() => {
    if (portData.tableData.length > 1) showDrawer();
  }, [portData.tableData]);

  useEffect(() => {
   

    defaultVesselFunc();
  }, []);
  const defaultVesselFunc = async () => {
    try {
      const url = `${process.env.REACT_APP_MT_URL}marine/get-vessel-positions?v=9&timespan=180&pageSize=2000`;
      const resp = await getAPICall(url);
      if (resp) {
        setDefaultVessel(resp.data["DATA"] || []);
        setFilteredData(resp.data["DATA"] || []);
      }

      setIsData(true);

      const fastData = await getAPICall(
        `${URL_WITH_VERSION}/port-bunker-activity/spotprice/fast`
      );

      if (fastData?.data) {
        processAndSetPortData(fastData.data);
        setIsData(false);
      }

      getAPICall(`${URL_WITH_VERSION}/port-bunker-activity/spotprice/live`)
        .then((liveData) => {
          if (liveData?.data) {
            processAndSetPortData(liveData.data);
            setIsBunkerPrice(true);
            setBunkerPrice(liveData.data);
          }
        })
        .catch((error) => console.error("Error fetching live data:", error));
    } catch (e) {
      console.error(e);
      setIsData(false);
    }
  };

  const processAndSetPortData = (data) => {
    const allPorts = data.BunkerExSpotPrices.reduce((acc, item) => {
      if (!acc[item.Port]) {
        acc[item.Port] = {
          portname: item.Port,
          fuelType: "",
          fuelprice: "",
          portCoordinates: [item.Longitude, item.Latitude],
        };
      }
      acc[item.Port].fuelType += `${item.Grade} `;
      acc[item.Port].fuelprice += `${item.Price} `;
      return acc;
    }, {});

    const PortNameAndCoordinateArr = Object.values(allPorts);
    setports(PortNameAndCoordinateArr);
  };

  const showDrawer = () => {
    if (portData.tableData?.length > 0) {
      setVisible(true);
    }
  };

  const onClose = () => {
    setVisible(false);
  };

  const drawPolygon = (e) => {
    // console.log(e);
  };

  const selectedListPopup = new mapboxgl.Popup({});

  const addVesselDetailPopup = (newItem) => {
    if (!newItem) {
      return;
    }

    const popupCordinate = [
      newItem.last_pos_lon ? newItem.last_pos_lon : 0.0,
      newItem.last_pos_lat ? newItem.last_pos_lat : 0.0,
    ];
    map.current.flyTo({
      center: popupCordinate,
      // zoom: 2,
      essential: true,
    });

    const markerElement = document.createElement("div");
    markerElement.onclick = () => setIsMapModal(true);
    markerElement.innerHTML = `
    <div class="live-vessel-box">
        <div class="live-info-box">
            <span>Vessel :${newItem.vessel_name} </span>
            <span>IMO : ${newItem.imo_no}</span>
            <span>Lat :${newItem.vessel_lat} </span>
            <span>Long. :${newItem.vessel_lon} </span>
        </div>
        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" style="cursor: pointer;
               position: absolute;
                 transform: rotate(${newItem?.vessel_course}deg);
                color: yellow;"viewBox="0 0 24 24"><path fill=yellow stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="m3.685 18.783l7.88-14.008a.5.5 0 0 1 .87 0l7.88 14.008a.5.5 0 0 1-.617.71l-7.517-2.922a.5.5 0 0 0-.362 0l-7.517 2.923a.5.5 0 0 1-.617-.711"/></svg>
    </div>`;
    markerElement.style.width = "30px";
    markerElement.style.height = "30px";
    markerElement.style.backgroundSize = "contain";
    markerElement.style.backgroundRepeat = "no-repeat";
    marker.current = new mapboxgl.Marker({ element: markerElement })
      .setLngLat(popupCordinate)
      .addTo(map.current);
  };

  const handleDefaultModal = (info) => {
    setDefaultModalInfo(info);
    setDefaultModal(true);
  };

  const addMarkersToMap = (coordinates) => {
    defaultVessel.forEach((coord) => {
      const {
        LON,
        LAT,
        SHIPNAME,
        IMO,
        SPEED,
        HEADING,
        STATUS,
        SHIPTYPE,
        COURSE,
      } = coord;

      if (SHIPTYPE === "Cargo" || SHIPTYPE.includes("Cargo")) {
        // colorFilter = "hue-rotate(0deg)";
        colorFilter = "green";
      } else {
        // colorFilter = "hue-rotate(300deg)";
        colorFilter = "red";
      }
      heading = COURSE ? parseFloat(COURSE) : 0;
      const customMarker = document.createElement("div");
      customMarker.className = "marker-default-vessels";
      customMarker.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" style="cursor: pointer;
               position: absolute;
                 transform: rotate(${heading}deg);
                color: ${colorFilter};"viewBox="0 0 24 24"><path fill=${colorFilter} stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="m3.685 18.783l7.88-14.008a.5.5 0 0 1 .87 0l7.88 14.008a.5.5 0 0 1-.617.71l-7.517-2.922a.5.5 0 0 0-.362 0l-7.517 2.923a.5.5 0 0 1-.617-.711"/></svg>`;
      // `<img class="vessel-marker-def" src=${newSrc} width="16px"  style="cursor: pointer;
      //         position: absolute;
      //         transform: rotate(${heading}deg);
      //         filter: ${colorFilter};"/>`;
      customMarker.onclick = () => handleDefaultModal(coord);

      const marker = new mapboxgl.Marker(customMarker)
        ?.setLngLat([parseFloat(LON), parseFloat(LAT)])
        ?.addTo(map.current);

      const popupContent = `
              <div class="ship-info">
                  <table>
                      <tbody>
                          <tr>
                              <td>Ship :</td>
                              <td><span style="white-space: nowrap">${
                                SHIPNAME || "N/A"
                              }</span></td>
                          </tr>
                          <tr>
                              <td>IMO:</td>
                              <td>${IMO}</td>
                          </tr>
                          <tr>
                              <td>Speed:</td>
                              <td>${SPEED / 10 + " knots"}</td>
                          </tr>
                          <tr>
                              <td>Latitude:</td>
                              <td>${LAT}</td>
                          </tr>
                          <tr>
                              <td>Longitude:</td>
                              <td>${LON}</td>
                          </tr>
                          <tr>
                              <td>Status:</td>
                              <td>${STATUS}</td>
                          </tr>
                      </tbody>
                  </table>
              </div>
          `;

      const popup = new mapboxgl.Popup({
        offset: 25,
        closeButton: false,
        closeOnClick: false,
      })?.setHTML(popupContent);

      marker.getElement().addEventListener("mouseenter", () => {
        popup.addTo(map.current);
      });

      marker.getElement().addEventListener("mouseleave", () => {
        popup.remove();
      });

      marker?.setPopup(popup);

      // Add the marker to the markers array
      defmarkers.push(marker);
    });
  };

  // const showMyFleetOnMap = (PortData) => {
  //   const limitedData =
  //     Array.isArray(PortData) && portData.length > 0
  //       ? PortData.slice(0, 100)
  //       : [];
  //   for (let marker of defmarkers) {
  //     marker.remove();
  //   }
  //   removeMarkersFromMap();
  //   const fleetMarkers = [];
  //   limitedData.forEach((point) => {
  //     const { LON, LAT, SHIPNAME, MMSI, IMO } = point;
  //     if (isNaN(parseFloat(LON)) || isNaN(parseFloat(LAT))) {
  //       console.warn(
  //         `Invalid coordinates for vessel ${SHIPNAME}. Skipping marker.`
  //       );
  //       return; // Skip this marker if coordinates are NaN
  //     }

  //     // Create a custom HTML element for the marker
  //     const customMarker = document.createElement("div");
  //     customMarker.className = "marker-default-vessels";
  //     customMarker.innerHTML = `<img class="vessel-marker-def fleet-marker" src="boat.png" width="16px" style="cursor: pointer"/>`;
  //     customMarker.onclick = () => handleDefaultModal(point);

  //     const marker = new mapboxgl.Marker(customMarker)
  //       .setLngLat([parseFloat(LON), parseFloat(LAT)])
  //       .addTo(map.current);

  //     const popupContent = `  
  //       <div class="ship-info">
  //         <table>
  //           <tbody>
  //             <tr>
  //               <td>Vessel:</td>
  //               <td><span style="white-space: nowrap">${SHIPNAME}</span></td>
  //             </tr>
  //             <tr>
  //               <td>MMSI:</td>
  //               <td>${MMSI}</td>
  //             </tr>
  //             <tr>
  //               <td>IMO:</td>
  //               <td>${IMO}</td>
  //             </tr>
  //             <tr>
  //               <td>Latitude:</td>
  //               <td>${LAT}</td>
  //             </tr>
  //             <tr>
  //               <td>Longitude:</td>
  //               <td>${LON}</td>
  //             </tr>
  //           </tbody>
  //         </table>
  //       </div>
  //     `;
  //     const popup = new mapboxgl.Popup({
  //       offset: 25,
  //       closeButton: false,
  //       closeOnClick: false,
  //     }).setHTML(popupContent);

  //     // Add event listeners to show/hide the popup on marker hover
  //     marker.getElement().addEventListener("mouseenter", () => {
  //       popup.addTo(map.current);
  //     });

  //     marker.getElement().addEventListener("mouseleave", () => {
  //       popup.remove();
  //     });

  //     // Set the popup to the marker
  //     marker.setPopup(popup);

  //     // Add the marker to the markers array for later management if needed
  //     fleetMarkers.push(marker);
  //   });

  //   // Add a new GeoJSON source and layer to display the markers

  //   let featureMarkerData = limitedData
  //     .filter(
  //       (point) =>
  //         !isNaN(parseFloat(point.LON)) && !isNaN(parseFloat(point.LAT))
  //     )
  //     .map((point, index) => ({
  //       type: "Feature",
  //       geometry: {
  //         type: "Point",
  //         coordinates: [parseFloat(point.LON), parseFloat(point.LAT)],
  //       },
  //       properties: {
  //         id: index,
  //         description: `
  //         <div style="width: 300px; padding: 10px; display: flex; flex-direction: column; gap: 10px;">
  //             <div class='fleet'>
  //                 <span class='fleettext'>Vessel:</span> <span>${point.vessel_name}</span>
  //             </div>
  //             <div class='fleet'>
  //                 <span class='fleettext'>MMSI:</span>  <span>${point.mmsi_number}</span>
  //             </div>
  //             <div class='fleet'>
  //                 <span class='fleettext'>IMO:</span> <span>${point.imo_number}</span>
  //             </div>
  //             <div class='fleet'>
  //                 <span class='fleettext'>Latitude:</span>  <span>${point.latitude}</span>
  //             </div>
  //         </div>
  //       `,
  //       },
  //     }));
  //   if (!map.current.getSource("my-fleet")) {
  //     map.current.addSource("my-fleet", {
  //       type: "geojson",
  //       data: {
  //         type: "FeatureCollection",
  //         features: featureMarkerData,
  //       },
  //     });

  //     map.current.addLayer({
  //       id: "my-fleet",
  //       type: "symbol",
  //       source: "my-fleet",
  //       layout: {
  //         "icon-size": 0.3,
  //         "icon-allow-overlap": true,
  //         "icon-rotate": ["get", "rotation"],
  //         "icon-ignore-placement": true,
  //       },
  //     });
  //   }

  //   // Create a popup instance
  //   const popup = new mapboxgl.Popup({
  //     closeButton: false,
  //     closeOnClick: false,
  //   });

  //   // Add event listeners for popup
  //   map.current.on("mouseenter", "my-fleet", (e) => {
  //     map.current.getCanvas().style.cursor = "pointer";
  //     const coordinates = e.features[0].geometry.coordinates.slice();
  //     const description = e.features[0].properties.description;

  //     while (Math.abs(e.lngLat?.lng - coordinates[0]) > 180) {
  //       coordinates[0] += e.lngLat?.lng > coordinates[0] ? 360 : -360;
  //     }

  //     popup.setLngLat(coordinates).setHTML(description).addTo(map.current);
  //   });

  //   map.current.on("mouseleave", "my-fleet", () => {
  //     map.current.getCanvas().style.cursor = "";
  //     popup.remove();
  //   });
  // };

 
  //-------Upper Code Commented Bcz Data is not showing On Map---------------//
 
  
  
  const showMyFleetOnMap = (PortData) => {
    if (!PortData || PortData.length === 0) {
       console.warn("PortData is empty or undefined.");
      return;
    }
  
    // Clear existing markers
    for (let marker of defmarkers) {
      marker.remove();
    }
    removeMarkersFromMap();
  
    const fleetMarkers = [];
    const featureMarkerData = [];
  
    PortData.forEach((point) => {
      const { LON, LAT, SHIPNAME, MMSI, IMO,  SHIPTYPE,COURSE} = point;
  
      if (isNaN(parseFloat(LON)) || isNaN(parseFloat(LAT))) {
        console.warn(`Invalid coordinates for vessel ${SHIPNAME}. Skipping marker.`);
        return;
      }


      if (SHIPTYPE === "Cargo" || SHIPTYPE.includes("Cargo")) {
        // colorFilter = "hue-rotate(0deg)";
        colorFilter = "green";
      } else {
        // colorFilter = "hue-rotate(300deg)";
        colorFilter = "red";
      }
      heading = COURSE ? parseFloat(COURSE) : 0;
  
      // Create a custom HTML element for the marker
      const customMarker = document.createElement("div");
      customMarker.className = "marker-default-vessels";
      customMarker.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" style="cursor: pointer;
      position: absolute;
        transform: rotate(${heading}deg);
              color: ${colorFilter};"viewBox="0 0 24 24"><path fill=${colorFilter} stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="m3.685 18.783l7.88-14.008a.5.5 0 0 1 .87 0l7.88 14.008a.5.5 0 0 1-.617.71l-7.517-2.922a.5.5 0 0 0-.362 0l-7.517 2.923a.5.5 0 0 1-.617-.711"/></svg>`;
      // customMarker.innerHTML = `<img class="vessel-marker-def fleet-marker" src="boat.png" width="16px" style="cursor: pointer"/>`;
      customMarker.onclick = () => handleDefaultModal(point);
  
      const marker = new mapboxgl.Marker(customMarker)
        .setLngLat([parseFloat(LON), parseFloat(LAT)])
        .addTo(map.current);
  
      // Add marker to array for management
      fleetMarkers.push(marker);
  
      // Prepare GeoJSON data for feature layer
      featureMarkerData.push({
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [parseFloat(LON), parseFloat(LAT)],
        },
        properties: {
          description: `
            <div style="width: 300px; padding: 10px; display: flex; flex-direction: column; gap: 10px;">
              <div class='fleet'><span class='fleettext'>Vessel:</span> <span>${SHIPNAME}</span></div>
              <div class='fleet'><span class='fleettext'>MMSI:</span>  <span>${MMSI}</span></div>
              <div class='fleet'><span class='fleettext'>IMO:</span> <span>${IMO}</span></div>
              <div class='fleet'><span class='fleettext'>Latitude:</span>  <span>${LAT}</span></div>
              <div class='fleet'><span class='fleettext'>Longitude:</span> <span>${LON}</span></div>
            </div>
          `,
        },
      });
    });
  
    // Update GeoJSON source for dynamic map rendering
    if (!map.current.getSource("my-fleet")) {
      map.current.addSource("my-fleet", {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: featureMarkerData,
        },
      });
  

    } else {
      // Update the existing source data
      map.current.getSource("my-fleet").setData({
        type: "FeatureCollection",
        features: featureMarkerData,
      });
    }
  
    // Add popup behavior for feature layer
    const popup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false,
    });
  
    map.current.on("mouseenter", "my-fleet", (e) => {
      map.current.getCanvas().style.cursor = "pointer";
  
      const coordinates = e.features[0].geometry.coordinates.slice();
      const description = e.features[0].properties.description;
  
      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
      }
  
      popup.setLngLat(coordinates).setHTML(description).addTo(map.current);
    });
  
    map.current.on("mouseleave", "my-fleet", () => {
      map.current.getCanvas().style.cursor = "";
      popup.remove();
    });
  };
  
 
  const getSelectedData = async (dataset) => {
    selectedListPopup.remove();
    const {
      vessel_name,
      current_port_name,
      degree,
      imo_no,
      last_pos_lat,
      last_pos_lon,
      speed,
      status,
      mmsi,
    } = dataset;

    let tempSelectedData = {
      IMO: imo_no,
      last_port: {
        ata: "NA",
        atd: "NA",
        locode: "NA",
        name: "NA",
      },
      MMSI: mmsi,
      SHIPNAME: vessel_name,
      NEXT_PORT_NAME: null,
      position: {
        course_over_ground: 188.3,
        LAT: last_pos_lat,
        location_str: current_port_name,
        LON: last_pos_lon,
        nav_status: "NA",
        received: "NA",
        SPEED: speed,
        HEADING: 10,
      },
      vessel_id: "NA",
      voyage: {
        DESTINATION: "NA",
        DRAUGHT: "NA",
        ETA: "NA",
        received: "NA",
      },
    };
  };

  const searchIconClick = () => {
    setPortData((prev) => ({
      ...prev,
      filterType: FILTER_TYPES.liveSearch,
      liveSearchValue: "",
      selectedData: null,
      isGlobalShipOpen: true,
    }));
  };

  const onSearchDbData = () => {
    setPortData((prev) => ({
      ...prev,
      filterType: FILTER_TYPES.allDataFilter,
      serchedData: "all",
      selectedData: null,
    }));
  };

  const getVesselIdByImoNumber = async (
    imoNumber,
    localDataset,
    isNotShowInMap
  ) => {
    setPortData((prev) => ({
      ...prev,
      isLiveDetailLoading: true,
      selectedData: {},
      showlivelocationdata: true,
    }));

    const { SHIPNAME, IMO, vessel_id, vt_verbose, MMSI, SHIP_ID } =
      localDataset;
    const url_add_my_fleed = `${process.env.REACT_APP_MT_URL}marine/add-fleet/${IMO}/${SHIP_ID}`;
    const token = localStorage.getItem("oceanToken");
    try {
      await handleGetLiveVesselItemData(localDataset, isNotShowInMap);
    } catch (e) {
      setPortData((prev) => ({ ...prev, selectedData: null }));
    }
    setPortData((prev) => ({ ...prev, showlivelocationdata: false }));
  };

  const handleGetLiveVesselItemData = async (dataset, isNotShowInMap) => {
    const { SHIPNAME, IMO, vessel_id, vt_verbose, MMSI, SHIP_ID } =
      Array.isArray(dataset) ? dataset[0] : dataset;
    let shipid = null;
    setPortData((prev) => ({ ...prev, isLiveDetailLoading: true }));
    const url_add_my_fleed = `${process.env.REACT_APP_MT_URL}marine/add-fleet/${IMO}/${SHIP_ID}`;
    const token = localStorage.getItem("oceanToken");
    const respData = await fetch(url_add_my_fleed, {
      method: "GET",
      headers: {
        //"access-control-allow-origin": "*",
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    if (respData.ok) {
      const url_vessel_position = `${process.env.REACT_APP_MT_URL}marine/vessel-position-imo/${MMSI}/${SHIP_ID}/${SHIPNAME}/${IMO}`;

      // const vesselPositionApi = await fetch(url_vessel_position, {
      //   method: "GET",
      //   headers: {
      //     Accept: "application/json",
      //     "Content-Type": "application/json",
      //     Authorization: token,
      //   },
      // });

      const vesselPositionApi = await getAPICall(url_vessel_position);

      if (vesselPositionApi.error && !vesselPositionApi.data) {
        openNotificationWithIcon("info", vesselPositionApi.error);
        setPortData((prev) => ({
          ...prev,
          isLiveDetailLoading: false,
          // showlivelocationdata: false,
        }));
      } else if (vesselPositionApi.data && !vesselPositionApi.error) {
        const vesselPosition = vesselPositionApi;
        let mapDataItem = null;
        let saveData = null;
        let updateData = null;
        if (vesselPosition?.data) {
          setPortData((prev) => ({
            ...prev,
            vesselPositionDetails: vesselPosition.data,
          }));
          const data = vesselPosition.data;
          mapDataItem = {
            imo_no: data?.IMO || null,
            vessel_name: data?.SHIPNAME,
            speed: data?.SPEED,
            vessel_lat: data.LAT || null,
            vessel_lon: data.LON || null,
            vessel_status: data.STATUS || null,
            current_port_name: data.CURRENT_PORT || null,
            last_pos_lon: data.LON || null,
            last_pos_lat: data.LAT || null,
            vessel_head: data?.HEADING || 0,
            vessel_course: data?.COURSE || 0,
          };
          saveData = {
            imo_no: data.IMO,
            vessel_name: data.SHIPNAME,
            vessel_id: data.SHIP_ID,
            speed: data.SPEED,
            last_pos_lat: data.LAT,
            last_pos_lon: data.LON,
            status: data.STATUS,
            current_port_name: data.CURRENT_PORT,
            vessel_last_pos: data.LAST_PORT,
            mmsi_number: data.MMSI,
            view: 1,
            my_fleet: 0,
            degree: 0,
            toc_vessel_details: 0,
            deadWeight: null,
            new_name: data.SHIPNAME,
            vessel_type: data.SHIP_CLASS,
            ata: data.LAST_PORT_TIME,
            atd: data.LAST_PORT_TIME,
            last_port: data.LAST_PORT,
            next_port: data.NEXT_PORT_NAME,
            eta_calc: data.ETA_CALC,
            destination: data?.DESTINATION,
            eta: data.ETA,
            received: data.TIMESTAMP,
          };
          updateData = {
            imo_number: data.IMO,
            last_port: {
              ata: data.LAST_PORT_TIME,
              atd: data.LAST_PORT_TIME,
              locode: "PHDVO",
              name: data.LAST_PORT,
            },
            ship_id: data.SHIP_ID,
            mmsi_number: data.MMSI,
            name: data.SHIPNAME,
            next_port: {
              eta_calc: data.ETA_CALC,
              locode: null,
              name: data.SHIPNAME,
              travel_distance_nm: null,
              travel_time_h: null,
            },
            position: {
              course_over_ground: null,
              latitude: data.LAT,
              location_str: null,
              longitude: data.LON,
              nav_status: data.STATUS,
              received: data.TIMESTAMP,
              speed: data.SPEED,
              true_heading: null,
            },
            vessel_id: data.SHIP_ID,
            voyage: {
              destination: data.DESTINATION,
              DRAUGHT: "",
              eta: data.ETA,
              received: data.TIMESTAMP,
            },
            DRAUGHT: data.DRAUGHT,
          };
        }
        addNewSourceOnMapData(mapDataItem);

        const tempSelectedPropData = {
          vessel_name: vesselPosition?.data?.SHIPNAME,
          imo_no: vesselPosition?.data?.imo_no,
          current_port_name: "NA",
          last_pos_lat: "NA",
          last_pos_lon: "NA",
        };
        const tempSelectedData = {
          vessel_name: vesselPosition?.data?.SHIPNAME || "NA",
          //imo_number: 0,
          imo_number: vesselPosition?.data?.IMO || 0,
          last_port: {
            ata: vesselPosition?.data?.LAST_PORT_TIME || "",
            atd: "NA",
            locode: vesselPosition?.data?.LAST_PORT_UNLOCODE || "NA",
            name: vesselPosition?.data?.LAST_PORT || "NA",
          },
          mmsi_number: vesselPosition?.data?.MMSI || 0,
          name: "",
          next_port: {
            locode: vesselPosition?.data?.NEXT_PORT_UNLOCODE || "NA",
            name: vesselPosition?.data?.NEXT_PORT_NAME || "NA",
            travel_distance_nm: 0,
            travel_time_h: 0,
          },
          position: {
            destination: vesselPosition?.data?.DESTINATION || "NA",
            location: vesselPosition?.data?.CURRENT_PORT || "NA",
            course_over_ground: 0,
            //latitude: 0,
            latitude: vesselPosition.data?.LAT || 0,
            location_str: "",
            //longitude: 0,
            longitude: vesselPosition.data?.LON || 0,
            //nav_status: "",
            // nav_status: getStatus(vesselPosition.data.STATUS) || "",
            //received: "2022-12-01T10:24:33Z",
            received: vesselPosition.data?.TIMESTAMP || "",
            //speed: 0,
            speed: vesselPosition.data?.SPEED || "",
            true_heading: 0,
            HEADING: vesselPosition.data?.HEADING,
          },
          request_limit_info: {
            left_requests: 0,
            max_requests: 0,
            used_requests: 0,
          },
          vessel_id: 0,
          voyage: {
            DRAUGHT: vesselPosition.data?.DRAUGHT,
          },
          ship_id: vesselPosition.data?.SHIP_ID,
          NEXT_PORT_NAME: vesselPosition.data?.NEXT_PORT_NAME,
        };

        setPortData(
          (prev) => ({
            ...prev,
            selectedData: tempSelectedData,
            selectedPropData: tempSelectedPropData,
            filterType: "",
            isGlobalShipOpen: false,
            vesselPositionDetails: vesselPositionApi.data,
            isLiveDetailLoading: false,
            showlivelocationdata: false,
            isInMyFleet: vesselPositionApi?.Marked || false,
          }),
          () => {
            setPortData((prev) => ({ ...prev, isLiveDetailLoading: false }));
          }
        );
      } else if (vesselPositionApi.error && vesselPositionApi.data) {
        openNotificationWithIcon("info", vesselPositionApi.error);
        const vesselPosition = vesselPositionApi;
        let mapDataItem = null;
        let saveData = null;
        let updateData = null;
        if (vesselPosition?.data) {
          setPortData((prev) => ({
            ...prev,
            vesselPositionDetails: vesselPosition.data,
            isLiveDetailLoading: true,
            isInMyFleet: vesselPositionApi?.Marked || false,
          }));
          const data = vesselPosition.data;
          mapDataItem = {
            imo_no: data?.IMO || null,
            vessel_name: data?.SHIPNAME,
            speed: data?.SPEED,
            vessel_lat: data.LAT || null,
            vessel_lon: data.LON || null,
            vessel_status: data.STATUS || null,
            current_port_name: data.CURRENT_PORT || null,
            last_pos_lon: data.LON || null,
            last_pos_lat: data.LAT || null,
            vessel_head: data?.HEADING || 0,
            vessel_course: data?.COURSE || 0,

            //start==================
          };
          saveData = {
            imo_no: data.IMO,
            vessel_name: data.SHIPNAME,
            vessel_id: data.SHIP_ID,
            speed: data.SPEED,
            last_pos_lat: data.LAT,
            last_pos_lon: data.LON,
            status: data.STATUS,
            current_port_name: data.CURRENT_PORT,
            vessel_last_pos: data.LAST_PORT,
            mmsi_number: data.MMSI,
            view: 1,
            my_fleet: 0,
            degree: 0,
            toc_vessel_details: 0,
            deadWeight: null,
            new_name: data.SHIPNAME,
            vessel_type: data.SHIP_CLASS,
            ata: data.LAST_PORT_TIME,
            atd: data.LAST_PORT_TIME,
            last_port: data.LAST_PORT,
            next_port: data.NEXT_PORT_NAME,
            eta_calc: data.ETA_CALC,
            destination: data?.DESTINATION,
            eta: data.ETA,
            received: data.TIMESTAMP,
          };
          updateData = {
            imo_number: data.IMO,
            last_port: {
              ata: data.LAST_PORT_TIME,
              atd: data.LAST_PORT_TIME,
              locode: "PHDVO",
              name: data.LAST_PORT,
            },
            ship_id: data.SHIP_ID,
            mmsi_number: data.MMSI,
            name: data.SHIPNAME,
            next_port: {
              eta_calc: data.ETA_CALC,
              locode: null,
              name: data.SHIPNAME,
              travel_distance_nm: null,
              travel_time_h: null,
            },
            position: {
              course_over_ground: null,
              latitude: data.LAT,
              location_str: null,
              longitude: data.LON,
              nav_status: data.STATUS,
              received: data.TIMESTAMP,
              speed: data.SPEED,
              true_heading: null,
            },
            vessel_id: data.SHIP_ID,
            voyage: {
              destination: data.DESTINATION,
              DRAUGHT: "",
              eta: data.ETA,
              received: data.TIMESTAMP,
            },
            DRAUGHT: data.DRAUGHT,
          };
        }
        addNewSourceOnMapData(mapDataItem);

        const tempSelectedPropData = {
          vessel_name: vesselPosition?.data?.SHIPNAME,
          imo_no: vesselPosition?.data?.imo_no,
          current_port_name: "NA",
          last_pos_lat: "NA",
          last_pos_lon: "NA",
        };
        const tempSelectedData = {
          vessel_name: vesselPosition?.data?.SHIPNAME || "NA",
          //imo_number: 0,
          imo_number: vesselPosition?.data?.IMO || 0,
          last_port: {
            ata: vesselPosition?.data?.LAST_PORT_TIME || "",
            atd: "NA",
            locode: vesselPosition?.data?.LAST_PORT_UNLOCODE || "NA",
            name: vesselPosition?.data?.LAST_PORT || "NA",
          },
          mmsi_number: vesselPosition?.data?.MMSI || 0,
          name: "",
          next_port: {
            locode: vesselPosition?.data?.NEXT_PORT_UNLOCODE || "NA",
            name: vesselPosition?.data?.NEXT_PORT_NAME || "NA",
            travel_distance_nm: 0,
            travel_time_h: 0,
          },
          position: {
            destination: vesselPosition?.data?.DESTINATION || "NA",
            location: vesselPosition?.data?.CURRENT_PORT || "NA",
            course_over_ground: 0,
            //latitude: 0,
            latitude: vesselPosition.data?.LAT || 0,
            location_str: "",
            //longitude: 0,
            longitude: vesselPosition.data?.LON || 0,
            //nav_status: "",
            // nav_status: getStatus(vesselPosition.data.STATUS) || "",
            //received: "2022-12-01T10:24:33Z",
            received: vesselPosition.data?.TIMESTAMP || "",
            //speed: 0,
            speed: vesselPosition.data?.SPEED || "",
            true_heading: 0,
            HEADING: vesselPosition.data?.HEADING,
          },
          request_limit_info: {
            left_requests: 0,
            max_requests: 0,
            used_requests: 0,
          },
          vessel_id: 0,
          voyage: {
            DRAUGHT: vesselPosition.data?.DRAUGHT,
          },
          ship_id: vesselPosition.data?.SHIP_ID,
          NEXT_PORT_NAME: vesselPosition.data?.NEXT_PORT_NAME,
        };

        setPortData(
          (prev) => ({
            ...prev,
            selectedData: tempSelectedData,
            selectedPropData: tempSelectedPropData,
            filterType: "",
            isGlobalShipOpen: false,
            vesselPositionDetails: vesselPositionApi.data,
            isLiveDetailLoading: false,
            showlivelocationdata: false,
          }),
          () => {
            setPortData((prev) => ({ ...prev, isLiveDetailLoading: false }));
          }
        );
      } else {
        console.log("some error occured");
      }
    }
    if (!vessel_id) {
      return;
    }
  };

  const addNewSourceOnMapData = (newItem) => {
    const { selectedData } = portData;
    if (!newItem || !newItem.imo_no) {
      return;
    }

    const mapData = [newItem];
    // Add popup

    addVesselDetailPopup(newItem);

    let mapLocation = updateMapData(map, mapData, "liveVessel");
    setPortData((prev) => ({ ...prev, featuresData: mapLocation }));
  };

  const debouncedLiveSearchInput = debounce(async (value) => {
    setPortData((prev) => ({
      ...prev,
      liveSearchValue: value,
      liveSearchList: [],
      isLiveSearchLoading: true,
      liveSearchResult: "Searching your request",
    }));

    let url = `${process.env.REACT_APP_MT_URL}marine/get-vessels-name/${value}`;
    if (!url) {
      return;
    }
    if (value.length >= 3) {
      try {
        const response = await getAPICall(url);
        if (
          response.status !== "error" &&
          response.length &&
          !response.hasOwnProperty("error")
        ) {
          if (response.length > 0) {
            setPortData((prev) => ({
              ...prev,
              liveSearchList: response,
              isLiveSearchLoading: false,
            }));
          } else {
            setPortData((prev) => ({
              ...prev,
              liveSearchResult: "No data found",
              isLiveSearchLoading: false,
            }));
          }
        } else {
          setPortData((prev) => ({
            ...prev,
            isLiveSearchLoading: false,
          }));
          if (!isNaN(value)) {
            if (value.length > 5) {
              openNotificationWithIcon("info", "Data not Found");
            }
            return;
          } else {
            openNotificationWithIcon("info", "Data not Found");
          }
        }
      } catch (error) {
        setPortData((prev) => ({
          ...prev,
          liveSearchList: [],
          isLiveSearchLoading: false,
          liveSearchResult: "searching...",
        }));
      }
    } else {
      setPortData((prev) => ({
        ...prev,
        isLiveSearchLoading: false,
      }));
    }
  }, 1000);

  const handleLiveSearchInput = (e) => {
    let search_value = e.target.value.replace("/", "");
    // console.log("TESYG", search_value);
    debouncedLiveSearchInput(search_value);
  };

  const handleLocalDataFilter = () => {
    const searchText = portData.serchedData;

    const filterData = portData.data.filter(
      (v) =>
        searchText.toLowerCase() === "all" ||
        v.imo_no.includes(searchText) ||
        v.vessel_name.toLowerCase().includes(searchText.toLowerCase())
    );
    setPortData((prev) => ({ ...prev, filterData }));
    updateMapData(MapRef, filterData);
  };

  let searchTimer = null;

  const handleSearchValue = (event) => {
    const serchedData =
      event.target.value.length > 0 ? event.target.value : "all";

    setPortData((prev) => ({ ...prev, serchedData }));
    clearTimeout(searchTimer);
    searchTimer = setTimeout(() => {
      handleLocalDataFilter();
    }, 1000);
  };

  const addmyfleet = async (IMO) => {
    setPortData((prev) => ({ ...prev, isLiveDetailLoading: true }));

    if (IMO && !portData.isInMyFleet) {
      try {
        await postAPICall(
          `${process.env.REACT_APP_MT_URL}marine/add-to-fleet`,
          IMO,
          "post",
          (data) => {
            if (data) {
              openNotificationWithIcon("success", data.msg, 5);
              setPortData((prev) => ({
                ...prev,
                isLiveDetailLoading: false,
                isInMyFleet: true,
              }));
            }
          }
        );
      } catch (err) {
        openNotificationWithIcon("error", "Something went wrong", 5);
      }
    } else if (IMO && portData.isInMyFleet) {
      let _url = `${process.env.REACT_APP_MT_URL}marine/delete-from-fleet/${IMO.ship_id}`;
      apiDeleteCall(_url, { id: IMO.ship_id }, (response) => {
        if (response && response.msg) {
          openNotificationWithIcon(
            "success",
            "Vessel Removed From the my Fleet.",
            2
          );

          setPortData((prev) => ({
            ...prev,
            isLiveDetailLoading: false,
            isInMyFleet: false,
          }));
        } else {
          openNotificationWithIcon("error", response.message);
        }
      });
    } else {
      openNotificationWithIcon("error", "Something went wrong.", 5);
    }
  };

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchText(value);
    const filtered =
      defaultVessel.length > 0 &&
      defaultVessel?.filter((item) =>
        Object.keys(item).some((key) =>
          item[key] ? item[key].toString().toLowerCase().includes(value) : false
        )
      );
    setFilteredData(filtered);
  };

  const maprender = () => {
    map.current = new mapboxgl.Map({
      container: MapRef.current,
      style: "mapbox://styles/mapbox/streets-v11",
      center: { lat: 20.5937, lng: 78.9629 },
      zoom: 1.02,
      projection: projections,
    });

    map.current.on("load", () => {
      map.current.loadImage(
        IMAGE_PATH + "icons/mapArrowUnknown.png",
        (error, image) => {
          if (error) {
            return;
          }
          map.current.addImage("unknownIcon", image);
        }
      );

      map.current.loadImage(IMAGE_PATH + "icons/marker.png", (error, image) => {
        if (error) {
          return;
        }
        map.current.addImage("orangeIcon", image);
      });

      map.current.loadImage(
        IMAGE_PATH + "icons/mapArrowGreen.png",
        (error, image) => {
          if (error) {
            return;
          }
          map.current.addImage("greenIcon", image);
        }
      );

      map.current.loadImage(
        IMAGE_PATH + "icons/map-icon-pointer.png",
        (error, image) => {
          if (error) throw error;
          map.current.addImage("custom-marker", image);

          map.current.addSource("places", {
            // This GeoJSON contains features that include an "icon"
            // property. The value of the "icon" property corresponds
            // to an image in the Mapbox Streets style's sprite.

            type: "geojson",
            data: {
              type: "FeatureCollection",
              features: featuresData,
            },
          });

          // Add a layer showing the places.
          map.current.addLayer({
            id: "places",
            type: "symbol",
            source: "places",
            layout: {
              "icon-image": "greenIcon",
              "icon-allow-overlap": true,
              "icon-rotate": ["get", "rotation"],
              "icon-ignore-placement": true,
            },
          });

          // Create a popup, but don't add it to the map yet.

          const hoverpopup = new mapboxgl.Popup({
            maxWidth: "10vw",
            closeButton: false,
            closeOnClick: false,
          });

          // When a click event occurs on a feature in the places layer, open a popup at the
          // location of the feature, with description HTML from its properties.

          map.current.on("click", "places", (e) => {
            // Copy coordinates array.

            try {
              const dataset =
                JSON.parse(e.features[0].properties.dataset) || {};
              if (Object.keys(dataset).length) {
                getVesselIdByImoNumber(dataset.imo_no, dataset, true);
              }

              const addedMarker = document.getElementById("dottedArrowMarker");

              if (addedMarker) {
                addedMarker.remove();
              }
              const markerImage = document.createElement("img");
              markerImage.src = IMAGE_PATH + "icons/dottedArrow.png";
              markerImage.width = 140;
              markerImage.height = 20;
              markerImage.id = "dottedArrowMarker";

              new mapboxgl.Marker(markerImage, {
                rotation: e.features[0].properties.rotation,
              })
                .setLngLat([
                  parseFloat(dataset.last_pos_lon),
                  parseFloat(dataset.last_pos_lat),
                ])
                .addTo(map.current);
            } catch (error) {
              console.log(">>> ERROR", error);
            }
          });

          map.current.addSource("liveVessel", {
            // This GeoJSON contains features that include an "icon"
            // property. The value of the "icon" property corresponds
            // to an image in the Mapbox Streets style's sprite.

            type: "geojson",
            data: {
              type: "FeatureCollection",
              features: [],
            },
          });

          // Add a layer showing the places.
          map.current.addLayer({
            id: "liveVessel",
            type: "symbol",
            source: "liveVessel",
            layout: {
              "icon-image": "yellowIcon",
              "icon-allow-overlap": true,
            },
          });

          map.current.on("click", "liveVessel", (e) => {
            // Copy coordinates array.
            try {
              const dataset =
                JSON.parse(e.features[0].properties.dataset) || {};
              if (Object.keys(dataset).length) {
                getVesselIdByImoNumber(dataset.imo_no, dataset, true);
              }
            } catch (error) {
              //  console.log(">>> ERROR", error);
            }
          });

          // Change the cursor to a pointer when the mouse is over the places layer.

          map.current.on("mouseenter", "places", (e) => {
            const coordinates = e.features[0].geometry.coordinates.slice();
            const description = e.features[0].properties.hoverProperties;

            // Ensure that if the map is zoomed out such that multiple
            // copies of the feature are visible, the popup appears
            // over the copy being pointed to.
            while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
              coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
            }

            hoverpopup
              .setLngLat(coordinates)
              .setHTML(description)
              .addTo(map.current);

            map.current.getCanvas().style.cursor = "pointer";
          });

          // Change it back to a pointer when it leaves.
          map.current.on("mouseleave", "places", () => {
            map.current.getCanvas().style.cursor = "";
            hoverpopup.remove();
          });
        }
      );
    });

    const modes = MapboxDraw.modes;
    modes.static = StaticMode;

    drawRef.current = new MapboxDraw({
      displayControlsDefault: false,
      controls: {},
      modes: modes,
    });

    map.current.addControl(drawRef.current);

    map.current.on("draw.create", drawPolygon);
    map.current?.on("draw.update", drawPolygon);
    map.current.on("draw.delete", drawPolygon);

    map.current.on("load", () => {
      drawRef.current.changeMode("static");
      addMapLayers(map.current);
    });

    map.current.on("click", "points_layer", (e) => {
      e.clickOnLayer = true;
      setPortData((prev) => ({
        ...prev,
        visibleModal: true,
        port_lat: e.lngLat.lat,
        port_lng: e.lngLat.lng,
      }));
    });

    map.current.on("mousemove", "points_layer", (e) => {
      map.current.getCanvas().style.cursor = "pointer";
      e.clickOnLayer = true;
    });

    map.current.on("mouseleave", "points_layer", (e) => {
      map.current.getCanvas().style.cursor = "";
    });

    map.current.on("move", () => {
      setPortData((prev) => ({
        ...prev,
        lng: map.current.getCenter().lng.toFixed(4),
        lat: map.current.getCenter().lat.toFixed(4),
        zoom: map.current.getZoom().toFixed(2),
      }));
    });
  };

  const canalOnChange = (e) => {
    setFormData((prev) => ({ ...prev, canalOptions: e.target.value }));
  };

  const piracyOnChange = (e) => {
    setFormData((prev) => ({ ...prev, piracyArea: e.target.value }));
  };

  useEffect(() => {
    if (!map.current) return;
    if (portData.allCoordinates) {
      updateMapLineLayer({
        map: map.current,
        lineLayerData: portData.allCoordinates,
      });
    }

    if (portData.mapBoxPoints) {
      updateMapPointLayer({
        map: map.current,
        pointLayerData: portData.mapBoxPoints,
      });
    }
  }, [portData?.allCoordinates?.length, portData?.mapBoxPoints?.length]);

  useEffect(() => {
    addMarkersToMap(defaultVessel);
  }, [defaultVessel]);

  const onClearHandler = () => {
    allCoordinates = [];
    pointLayerData = [];
    mapClickTableData = [];
    setPortData((prev) => ({
      ...prev,
      allCoordinates,
      mapBoxPoints: pointLayerData,
      tableData: mapClickTableData,
      portPoints: [],
      isPortDistTable: false,
    }));
    // addMarkersToMap(defaultVessel);
    if (portData.isPortDistTable) {
      addMarkersToMap(defaultVessel);
    }
  };

  const onMapHandler = () => {
    if (projections == "mercator") {
      setprojections("globe");
      map.current.setProjection("globe");
    } else {
      setprojections("mercator");
      map.current.setProjection("mercator");
    }
  };

  const loadAPI = (portName) => {
    setPortData({ ...portData, loadPortInProgress: true });
    const token = localStorage.getItem("oceanToken");
    const lastClickedItem = localStorage.getItem("lastClickedItem");
    let getHeaders = {};
    getHeaders["CONTENT-TYPE"] = "application/json";
    getHeaders["Access-Control-Allow-Origin"] = "*";
    getHeaders["Authorization"] = token;
    getHeaders["endpoint"] = lastClickedItem;
    // getHeaders['Authorization'] = ""
    /* not this use authToken way because tomorrow it  will use authToken way */
    if (portName && portName.length > 2)
      getHeaders["X-FILTER"] = JSON.stringify(loadHeaders(portName));

    axios({
      method: "GET",
      url: `${process.env.REACT_APP_URL}v1/port/list?t=port&p=1&l=10`,
      headers: getHeaders,
    })
      .then((res) => {
        let dataRes = [];
        if (
          (res != undefined || res.data != undefined) &&
          res.data.data != false
        ) {
          res.data.data.forEach((element) => {
            dataRes.push({
              value: element.port_name,
              label: element.port_name,
            });
          });

          //setPortData({ ...portData, selectOptions: [...dataRes], portList: res.data.data })

          portData.selectOptions = dataRes;
          portData.portList = res.data.data;

          setPortData(
            (prevState) => ({
              ...prevState,
              selectOptions: portData.selectOptions,
              portList: portData.portList,
            }),
            () => {
              setPortData({ ...portData, loadPortInProgress: false });
            }
          );
        }
        // setPortData({ ...portData, loadPortInProgress: false });   //not updated
      })
      .catch((err) => {
        setPortData({ ...portData, loadPortInProgress: false });
      });
  };

  const showHideModal = (val, data) =>
    setPortData((prev) => ({
      ...prev,
      visibleModal: val,
      shipData: data,
    }));

  // Set table list for all points
  const getTableDataFromAndToItem = (
    distanceData,
    fromPortId,
    toPortId,
    isFirstItem
  ) => {
    const portPoints = portData.portPoints;

    const fromPortPoint = portPoints.find(
      (pointDetail) => pointDetail.PortID === fromPortId
    );
    const toPortPoint = portPoints.find(
      (pointDetail) => pointDetail.PortID === toPortId
    );
    const items = [];

    const vlsfoPerDay = DEFAULT_VALUE.vlsfo;
    const lsmgo = DEFAULT_VALUE.lsmgo;

    // From data
    if (fromPortPoint && isFirstItem) {
      items.push({
        id: fromPortPoint.id,
        PortID: fromPortPoint.PortID,
        port_name: fromPortPoint.port_name,
        distance: "0.000",
        crossed: " - ",
        eca: "0.000",
        seca: "0.000",
        spd: "0.000",
        seaDays: "0",
        vlsfoPerDay: "0.000",
        seaCons: "0.000",
        lsmgo: 0,
        portCons: 0,
        ttlBunkercons: 0,
        cargo: 0,
        ldRate: 0,
        ldTerm: ldTermsOptions[0].value,
        pDays: 0,
        totalVDays: 0,
        portExp: 0,
        co2: 0,
      });
    }

    if (toPortPoint) {
      const spd =
        Number(distanceData.spd) > 0 ? distanceData.spd : DEFAULT_VALUE.speed;
      const seaDays =
        distanceData.distance > 0
          ? Number(distanceData.distance / Number(Number(spd) * 24))
          : 0.0;
      const seaCons = seaDays > 0 ? Number(seaDays) * vlsfoPerDay : 0.0;

      const co2 = getCo2ValFormula({
        seaDays,
        vlsfoPerDay,
        lsmgo,
        portCons: 0,
      });

      items.push({
        id: toPortPoint.id,
        PortID: toPortPoint.PortID,
        port_name: toPortPoint.port_name,
        distance: distanceData.distance,
        eca: distanceData.eca,
        seca: distanceData.seca,
        spd: spd,
        eta: distanceData.eta,
        hra: distanceData.hra,
        crossed: distanceData.crossed || " - ",
        seaDays: Number(seaDays).toFixed(TO_FIXED_2_NUM),
        vlsfoPerDay,
        seaCons: Number(seaCons).toFixed(TO_FIXED_2_NUM),
        lsmgo,
        portCons: 0,
        ttlBunkercons: Number(seaDays).toFixed(TO_FIXED_2_NUM), // seaCons + portCons (seaCons + 0) = seaCons
        cargo: 0,
        co2,
        ldRate: 0,
        ldTerm: ldTermsOptions[0].value,
        pDays: 0,
        totalVDays: Number(seaDays).toFixed(TO_FIXED_2_NUM), // seaDays + pDay
        portExp: 0,
      });
    }
    return items;
  };

  // Set port point
  const setPortPoints = (portName) => {
    const portPoints = portData?.portPoints || [];
    if (portPoints?.length >= 5) {
      showError("Port point must be less then 5");
      return;
    }
    const portList = portData.portList;
    const pointDetail = portList.find((i) => i.port_name === portName);
    if (!pointDetail || !pointDetail.id) {
      return;
    }

    const portPointItem = { ...pointDetail };

    setPortData({
      ...portData,
      portPoints: [...portPoints, portPointItem],
    });
  };

  const onChangeAddDistances = (value, ss) => {
    setPortPoints(value);
    setFormData((prev) => ({
      ...prev,
      portsInfo: [...formdata.portsInfo, value],
    }));
    props.switchTable("");
  };

  const onSearchPortList = (value) => {
    setSearchport(value);
    setPortData((prev) => ({ ...prev, isPortDistTable: true }));
  };

  const onChangelocalEcaRadio = (e) => {
    setFormData((prev) => ({
      ...prev,
      localEca: e.target.value,
    }));
  };

  const onChangeEcaRadio = (e) => {
    setFormData((prev) => ({
      ...prev,
      seca: e.target.value,
    }));
  };

  const showError = (errors) => {
    const errorMsg = (
      <div className="notify-error">
        <div className="row">
          {Array.isArray(errors) ? (
            errors.map((msg, index) => (
              <div className="col-sm-12" key={index}>
                {msg}
              </div>
            ))
          ) : (
            <div className="col-sm-12">{errors}</div>
          )}
        </div>
      </div>
    );

    openNotificationWithIcon("error", errorMsg);
  };

  // Get continers string from check list and HRA, ECA

  const getFromAndToSetsForAPI = () => {
    const portPoints = portData.portPoints;
    const apiFormData = [];
    portPoints.map((item, index) => {
      const nextItem = portPoints[index + 1];

      if (!nextItem) {
        return;
      }

      apiFormData.push({
        from: item.port_name,
        fromPortId: item.PortID,
        to: nextItem.port_name, // to port name
        toPortId: nextItem.PortID, // to port id
        sort: index + 1,
      });
    });

    return apiFormData.sort((a, b) => a.sort - b.sort);
  };

  const getMapBoxFromAndToData = (portFrom, portTo, coordinates) => {
    return [
      {
        // feature for Mapbox DC
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: coordinates[0],
        },
        properties: {
          title: portFrom,
        },
      },
      {
        // feature for Mapbox SF
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: coordinates[coordinates.length - 1],
        },
        properties: {
          title: portTo,
        },
      },
    ];
  };

  const onSearchClick = async () => {
    const formDataArray = getFromAndToSetsForAPI();
    if (!formDataArray.length) {
      showError("Select Port points. Minimum 2 points requred to search");
      return;
    }
    setPortData((prev) => ({ ...prev, fetchInProgress: true }));
    const promiseArray = formDataArray.map(async (item) =>
      getCoordinateAPI(item)
    );

    const results = await Promise.all(promiseArray)
      .then((values) => values)
      .catch((error) => {
        showError(error.message || "Something wrong please try again");
      });
    const mapBoxPoints = [];
    const allCoordinates = [];
    let tableData = [];

    // Check any error found on these APIs
    const errors = [];
    results.map((item) => {
      if (!item.status) {
        errors.push(item.response);
      }
    });

    // Show error if found in APIs and return
    if (errors.length) {
      showError(errors);
      setPortData((prev) => ({
        ...prev,
        fetchInProgress: false,
        mapBoxPoints,
        allCoordinates,
        tableData,
      }));

      return;
    }

    // Get responses
    const responses = results.map(({ response }) => {
      return response;
    });

    responses.map((response, index) => {
      const mapPointFromAndTo = getMapBoxFromAndToData(
        response.from,
        response.to,
        response.coordinates
      );
      mapBoxPoints.push(...mapPointFromAndTo);
      allCoordinates.push(...response.coordinates);

      const tblItem = getTableDataFromAndToItem(
        response.distanceData,
        response.fromPortId,
        response.toPortId,
        index === 0
      );

      tableData.push(...tblItem);
    });

    const footerCol = getFooterColumn(tableData);
    tableData = tableData.map((i, index) => ({
      ...i,
      serialNumber: index + 1,
    }));
    portData.allCoordinates = allCoordinates;
    portData.mapBoxPoints = mapBoxPoints;
    portData.tableData = [...tableData, footerCol];
    portData.fetchInProgress = false;
    setPortData({
      ...portData,
      fetchInProgress: false,
      mapBoxPoints,
      allCoordinates,
      tableData: [...tableData, footerCol],
      stepData: tableData,
    });

    setPortData((prev) => ({
      ...prev,
      allCoordinates,
      mapBoxPoints,
      tableData: [...tableData, footerCol],
      fetchInProgress: false,
    }));

    showDrawer();

    removeMarkersFromMap();

    setDistanceModal(false);
  };

  const getCoordinateAPI = async (formData) => {
    const _method = "post";
    const postData = {
      from: formData.from,
      to: formData.to,
      localEca: formdata.localEca,
      seca: formdata.seca,
      canalOptions: formdata.canalOptions,
      piracyArea: formdata.piracyArea,
    };

    return await new Promise((resolve) => {
      postAPICall(
        `${URL_WITH_VERSION}/port/distance`,
        postData,
        _method,
        (data) => {
          if (data.data && data.data.features[0].properties.total_length >= 0) {
            const respData = data.data.features[0].properties;
            let distData = {},
              latlngarr = [],
              arr = [];
            const responseData = {};
            if (respData) {
              distData["distance"] = Number(respData.total_length).toFixed(
                TO_FIXED_2_NUM
              );
              distData["eca"] = EMPTY_2_NUM;
              // distData['eca'] = Number(respData.seca_length).toFixed(TO_FIXED_2_NUM);
              distData["seca"] = Number(respData.seca_length).toFixed(
                TO_FIXED_2_NUM
              );
              distData["hra"] = Number(respData.hra_length).toFixed(
                TO_FIXED_2_NUM
              );
              distData["crossed"] = Array.isArray(respData.crossed)
                ? respData.crossed.join(", ")
                : "";
              distData["spd"] = Number(
                respData.eta.averageVesselSpeedOverGround
              ).toFixed(TO_FIXED_2_NUM);
              distData["eta"] = Number(respData.eta.totalDurationDays).toFixed(
                TO_FIXED_2_NUM
              );

              let lat_lanf_frm =
                respData &&
                respData.lnglat_frm &&
                respData.lnglat_frm.split("/");
              if (lat_lanf_frm && lat_lanf_frm.length >= 2) {
                latlngarr.push({
                  latitude: lat_lanf_frm[0],
                  longitude: lat_lanf_frm[1],
                  port: portData.port_from,
                });
                arr.push({
                  lat: Number(lat_lanf_frm[0]),
                  lng: Number(lat_lanf_frm[1]),
                });
              }
              let lat_lanf_to =
                respData && respData.lnglat_to && respData.lnglat_to.split("/");
              if (lat_lanf_to && lat_lanf_to.length >= 2) {
                latlngarr.push({
                  latitude: lat_lanf_to[0],
                  longitude: lat_lanf_to[1],
                  port: portData.port_to,
                });
                arr.push({
                  lat: Number(lat_lanf_to[0]),
                  lng: Number(lat_lanf_to[1]),
                });
              }

              responseData.distanceData = distData;
              responseData.poly_lat_lng = latlngarr;
              responseData.triangleCoords = arr;
            }
            const cordinatesRes = data.data.features[0].geometry.coordinates;
            const resultData = {
              ...responseData,
              coordinates: cordinatesRes,
              lng: cordinatesRes[0],
              lat: cordinatesRes[cordinatesRes.length - 1],
              fromPortId: formData.fromPortId,
              toPortId: formData.toPortId,
              from: formData.from,
              to: formData.to,
            };

            // Return response with status
            resolve({ status: true, response: resultData });
            return;
          } else {
            const dataInner = data.data;
            const dataMessage =
              dataInner["error"] == undefined
                ? data.message
                : dataInner["error"];
            const msgError =
              typeof dataMessage !== "string"
                ? dataMessage["reason"]
                : dataMessage;

            // Return error response with status
            resolve({ status: false, response: msgError });
          }
        }
      );
    });
  };

  const loadHeaders = (portName) => {
    let headers = {
      order_by: {
        port_name: "ASC",
      },
      where: {
        OR: {
          id: {
            eq: "'" + portName + "'",
          },
          PortID: {
            l: portName,
          },
          port_name: {
            l: portName,
          },
          latitude: {
            l: portName,
          },
          longitude: {
            l: portName,
          },
          country_code: {
            l: portName,
          },
        },
      },
    };
    return headers;
  };

  const updateTableDataWithFooter = (updateTableData = []) => {
    const updataData = updateTableData.filter((i) => !i.isFooterCol);

    const footerCol = getFooterColumn(updataData);
    setPortData({
      ...portData,
      tableData: [...updataData, footerCol],
      stepData: updataData,
    });
  };

  // Speed calculation
  const handleSpeedChange = (value, record) => {
    const seaDays =
      record.distance > 0
        ? Number(record.distance / Number(value * 24))
        : EMPTY_2_NUM;
    const totalVDays = value
      ? Number(Number(record.pDays) + seaDays)
      : EMPTY_2_NUM;

    const co2 = getCo2ValFormula({
      seaDays,
      vlsfoPerDay: record.vlsfoPerDay,
      lsmgo: record.lsmgo,
      portCons: record.portCons,
    });

    const updataData = portData.tableData.map((item) => {
      if (item.id === record.id) {
        return {
          ...item,
          spd: value,
          seaDays: value
            ? Number(seaDays).toFixed(TO_FIXED_2_NUM)
            : EMPTY_2_NUM,
          totalVDays: Number(totalVDays).toFixed(TO_FIXED_2_NUM),
          co2,
        };
      }

      return item;
    });

    updateTableDataWithFooter(updataData);
  };

  const handleVLSFOChange = (value, record, name) => {
    const seaCons =
      record.seaDays > 0
        ? Number(record.seaDays) * value +
          Number(record.seaDays) * Number(record.lsmgo)
        : 0.0;
    const ttlBunkercons = Number(
      Number(seaCons) + Number(record.ttlBunkercons)
    ).toFixed(TO_FIXED_2_NUM);

    const co2 = getCo2ValFormula({
      seaDays: record.seaDays,
      vlsfoPerDay: value || 0,
      lsmgo: record.lsmgo,
      portCons: record.portCons,
    });

    const updataData = portData.tableData.map((item) => {
      if (item.id === record.id) {
        return {
          ...item,
          [name]: value,
          seaCons: value
            ? Number(seaCons).toFixed(TO_FIXED_2_NUM)
            : EMPTY_2_NUM,
          ttlBunkercons,
          co2,
        };
      }

      return item;
    });

    updateTableDataWithFooter(updataData);
  };

  const handleLsmgoChange = (value, record, name) => {
    const seaCons =
      record.seaDays > 0
        ? Number(record.seaDays) * Number(record.vlsfoPerDay) +
          Number(record.seaDays) * value
        : 0.0;
    const ttlBunkercons = Number(
      Number(seaCons) + Number(record.ttlBunkercons)
    ).toFixed(TO_FIXED_2_NUM);

    const co2 = getCo2ValFormula({
      seaDays: record.seaDays,
      vlsfoPerDay: record.vlsfoPerDay,
      lsmgo: value || 0,
      portCons: record.portCons,
    });

    const updataData = portData.tableData.map((item) => {
      if (item.id === record.id) {
        return {
          ...item,
          [name]: value,
          seaCons: value
            ? Number(seaCons).toFixed(TO_FIXED_2_NUM)
            : EMPTY_2_NUM,
          ttlBunkercons,
          co2,
        };
      }

      return item;
    });

    updateTableDataWithFooter(updataData);
  };

  const handleTtlBunkerconsChange = (value, record, name) => {
    const ttlBunkercons =
      record.seaCons >= 0
        ? Number(record.seaCons) + Number(value || 0)
        : EMPTY_2_NUM;

    const co2 = getCo2ValFormula({
      seaDays: record.seaDays,
      vlsfoPerDay: record.vlsfoPerDay,
      lsmgo: value || 0,
      portCons: name === "portCons" && value ? value : 0,
    });

    const updataData = portData.tableData.map((item) => {
      if (item.id === record.id) {
        return {
          ...item,
          [name]: value,
          ttlBunkercons: Number(ttlBunkercons).toFixed(TO_FIXED_2_NUM),
          co2,
        };
      }

      return item;
    });

    updateTableDataWithFooter(updataData);
  };

  const handlePDayChange = (value, record, name) => {
    // if(record.serialNumber == 1) {
    //   return;
    // }

    let pDays = 0;
    const cargo = name === "cargo" ? value : record.cargo;
    const ldRate = name === "ldRate" ? value : record.ldRate;
    const ldTerm = name === "ldTerm" ? value : record.ldTerm;
    const ldTermObj = ldTermsOptions.find((i) => i.value === ldTerm);
    const ldTermVal = ldTermObj && ldTermObj.value ? ldTermObj.numVal : 0;

    pDays = (Number(cargo) / Number(ldRate)) * Number(ldTermVal);
    if (!Number.isFinite(pDays) || Number.isNaN(pDays)) {
      pDays = 0;
    }

    const totalVDays = Number(pDays + Number(record.seaDays)).toFixed(
      TO_FIXED_2_NUM
    );

    const updataData = portData.tableData.map((item) => {
      if (item.id === record.id) {
        return {
          ...item,
          pDays: Number(pDays).toFixed(TO_FIXED_2_NUM),
          cargo,
          ldRate,
          ldTerm,
          totalVDays,
          [name]: value,
        };
      }

      return item;
    });

    updateTableDataWithFooter(updataData);
  };

  const handlePortExpChange = (value, record, name) => {
    const updataData = portData.tableData.map((item) => {
      if (item.id === record.id) {
        return {
          ...item,
          [name]: value,
        };
      }

      return item;
    });

    updateTableDataWithFooter(updataData);
  };

  const editableInput = (text, record, name) => {
    if (record.isFooterCol) {
      return " ";
    }

    return (
      <InputNumber
        style={{ width: "100px" }}
        value={Number(text)}
        onChange={(val) => {
          if (name == "spd") {
            handleSpeedChange(val, record, name);
          } else if (name === "vlsfoPerDay") {
            handleVLSFOChange(val, record, name);
          } else if (name === "lsmgo") {
            handleLsmgoChange(val, record, name);
          } else if (name === "portCons") {
            handleTtlBunkerconsChange(val, record, name);
          } else if (name === "cargo" || name === "ldRate") {
            handlePDayChange(val, record, name);
          } else if (name === "portExp") {
            handlePortExpChange(val, record, name);
          }
        }}
        onBlur={() => {
          if (name === "spd" || name === "lsmgo" || name === "vlsfoPerDay") {
            showDrawer();
          }
        }}
        min={0}
      />
    );
  };

  const editableSelect = (text, record, name, options) => {
    if (record.isFooterCol) {
      return " ";
    }

    return (
      <Select
        showSearch
        style={{ width: "100px" }}
        value={text}
        placeholder=" "
        optionFilterProp="children"
        onChange={(val) => {
          if (name === "ldTerm") {
            handlePDayChange(val, record, name);
          }
        }}
      >
        {options.map((element, i) => {
          return (
            <Option key={element.label} value={element.value}>
              {element.label}
            </Option>
          );
        })}
      </Select>
    );
  };

  const columnConfiguration = (columns) => {
    return columns.map((item, index) => {
      const isFirstCol = index === 0;
      if (item.isEditable && item.type == "numberInput") {
        return {
          ...item,
          render: (text, record) =>
            record.isFooterCol
              ? text
              : editableInput(text, record, item.dataIndex),
        };
      }

      if (item.isEditable && item.type == "select" && !isFirstCol) {
        return {
          ...item,
          render: (text, record) =>
            record.isFooterCol
              ? text
              : editableSelect(text, record, item.dataIndex, item.options),
        };
      }

      return item;
    });
  };

  const handleRemovePortPoint = (record) => {
    const filterPortPoints = portData.portPoints.filter(
      (i) => i.id !== record.id
    );

    pointLayerData = pointLayerData.filter(
      (data) =>
        data?.geometry?.coordinates[0]?.toFixed(2).toString() !==
        record.longitude
    );

    setPortData({
      ...portData,
      portPoints: filterPortPoints,
      mapBoxPoints: pointLayerData,
    });
  };

  const getFooterColumn = (tableData = []) => {
    const initialValue = {};
    tableColumns.map((item) => {
      if (item.isCalculateSum) {
        initialValue[item.dataIndex] = 0;
      }
    });

    const footerVal = tableData.reduce((acc, currentVal) => {
      const allVals = {};
      Object.keys(initialValue).map((key) => {
        allVals[key] = Number(acc[key]) + Number(currentVal[key]);
      });
      return allVals;
    }, initialValue);

    Object.keys(footerVal).map((key) => {
      footerVal[key] = Number(footerVal[key]).toFixed(TO_FIXED_2_NUM);
      if (Number.isNaN(footerVal[key])) {
        delete footerVal[key];
      }
    });

    footerVal.id = tableData.length;
    footerVal.isFooterCol = true; // set as footer flag

    return footerVal;
  };

  const {
    shipData,
    mapSettings,
    visibleModal,
    triangleCoords,
    poly_lat_lng,
    distanceData,
    spd,
    portPoints,
    checkedList,
    tableData,
  } = portData;

  const onCloseFilter = () => {
    setPortData(
      (prev) => ({
        ...prev,
        filterType: "",
        liveSearchValue: "",
        selectedData: null,
        liveSearchResult: searchLabel,
        liveSearchList: [],
        isShowLocationFromtable: false,
        isGlobalShipOpen: false,
      }),
      () => {
        if (typeof props.modalCloseEvent == "function") {
          props.modalCloseEvent(false);
        }
      }
    );
  };

  const renderDrawButtons = () => {
    return (
      <>
        {showDrawButtons && (
          <div className="draw-container">
            <span className="draw-mode-text">Draw Mode</span>
            <div className="draw-btn-container">
              <button
                className="btns-draw"
                onClick={() => {
                  if (drawRef.current) {
                    drawRef.current.changeMode("draw_polygon");
                  }
                }}
              >
                <i className="fas fa-plus" />
              </button>
              <button
                className="btns-draw"
                onClick={() => {
                  if (drawRef.current) {
                    drawRef.current.trash();
                  }
                }}
              >
                <i className="fas fa-trash" />
              </button>
              <button
                className="btns-draw"
                onClick={() => {
                  setShowDrawButtons(false);
                  if (drawRef.current) {
                    drawRef.current.changeMode("static");
                  }
                }}
              >
                <i className="far fa-times" />
              </button>
            </div>
          </div>
        )}
        <button
          className="btn-draw-polygon"
          onClick={() => {
            setShowDrawButtons(!showDrawButtons);
            if (drawRef.current) {
              drawRef.current.changeMode("simple_select");
            }
          }}
        >
          <svg
            height="100%"
            viewBox="0 0 512 512"
            width="100%"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="m234.558594 114.238281-136 296.960938-29.117188-13.4375 136-296.960938zm0 0"
              fill="#073352"
            />
            <path
              d="m427.039062 242.078125-23.359374 21.761719-142.71875-153.917969 23.359374-21.761719zm0 0"
              fill="#073352"
            />
            <path
              d="m409.921875 321.121094-295.523437 123.199218-12.320313-29.441406 295.523437-123.199218zm0 0"
              fill="#073352"
            />
            <path
              d="m205.441406 100.800781 29.117188 13.4375-19.359375 42.402344c-10.558594-2.71875-20.480469-7.199219-29.277344-13.28125zm0 0"
              fill="#073352"
            />
            <path
              d="m316.160156 122.398438c-6.558594 8.480468-14.558594 16-23.519531 21.761718l-31.679687-34.238281 23.359374-21.761719zm0 0"
              fill="#073352"
            />
            <path
              d="m304 64c0 35.347656-28.652344 64-64 64s-64-28.652344-64-64 28.652344-64 64-64 64 28.652344 64 64zm0 0"
              fill="#073352"
            />
            <path
              d="m118.078125 368.640625-19.519531 42.558594-29.117188-13.4375 19.359375-42.402344c10.558594 2.71875 20.480469 7.199219 29.277344 13.28125zm0 0"
              fill="#073352"
            />
            <path
              d="m157.441406 426.398438-43.042968 17.921874-12.320313-29.441406 43.042969-17.917968c5.757812 8.960937 10.078125 18.878906 12.320312 29.4375zm0 0"
              fill="#073352"
            />
            <path
              d="m128 448c0 35.347656-28.652344 64-64 64s-64-28.652344-64-64 28.652344-64 64-64 64 28.652344 64 64zm0 0"
              fill="#073352"
            />
            <path
              d="m397.601562 291.679688 12.320313 29.441406-43.042969 17.917968c-5.757812-8.960937-10.078125-18.878906-12.320312-29.4375zm0 0"
              fill="#073352"
            />
            <path
              d="m403.679688 263.839844-31.839844-34.238282c6.558594-8.480468 14.558594-16 23.519531-21.761718l31.679687 34.238281zm0 0"
              fill="#073352"
            />
            <path
              d="m512 288c0 35.347656-28.652344 64-64 64s-64-28.652344-64-64 28.652344-64 64-64 64 28.652344 64 64zm0 0"
              fill="#073352"
            />
            <g fill="#fff">
              <path d="m224 48h32v32h-32zm0 0" />
              <path d="m432 272h32v32h-32zm0 0" />
              <path d="m48 432h32v32h-32zm0 0" />
            </g>
          </svg>
        </button>
      </>
    );
  };

  let globalSearch = (
    <FilterVessel
      onSearchLiveData={searchIconClick}
      onSearchDbData={onSearchDbData}
      // onFilterData={filterIconClick}
      hideIcons={true}
      right={"-35px"}
    >
      {/* Single vessel view   */}

      <SelectedVesselView
        vesselDetails={portData.selectedData}
        onClose={onCloseFilter}
        isLiveDetailLoading={portData.isLiveDetailLoading}
        vesselPositionDetails={portData.vesselPositionDetails}
        liveSearchList={portData.liveSearchList}
        addmyfleet={(data) => addmyfleet(data)}
        isInMyFleet={portData.isInMyFleet}
      />

      {portData.isGlobalShipOpen && (
        <LiveVesselSearch
          onCloseFilter={onCloseFilter}
          listData={portData.liveSearchList}
          onChangeLiveSearchInput={handleLiveSearchInput}
          onLiveSearchDataClick={handleGetLiveVesselItemData}
          isLoading={portData.isLiveSearchLoading}
          isDetailLoading={portData.isLiveDetailLoading}
          liveSearchResult={portData.liveSearchResult}
        />
      )}

      {portData.isVesselListShow && (
        <VesselLargeListFilter
          onGetSelectedData={getSelectedData}
          onCloseFilter={onCloseFilter}
          handleSearchValue={handleSearchValue}
          listData={portData.filterData}
          closeVesselList={() =>
            setPortData((prev) => ({ ...prev, isVesselListShow: false }))
          }
        />
      )}
    </FilterVessel>
  );

  const removeMarkersFromMap = () => {
    defmarkers.forEach((marker) => marker.remove());
    defmarkers = [];
  };

  const portDistancePopUp = (
    <div>
      <div>
        <label style={{ fontWeight: "bold" }}>
          Search Place
          <span style={{ color: "#ff0000" }}>
            <b />
          </span>
          :
        </label>
      </div>
      <Row
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Col span={18}>
          <Select
            showSearch
            style={{
              width: "100%",
            }}
            placeholder="Select up to 5 Ports"
            value={portData.port_from}
            optionFilterProp="children"
            onChange={onChangeAddDistances}
            onSearch={onSearchPortList}
            filterOption={(input, option) =>
              option.props.children.toLowerCase().includes(input.toLowerCase())
            }
            loading={portData.loadPortInProgress}
          >
            {/* <Select placeholder="hello"> */}
            {portData.selectOptions &&
              portData.selectOptions.map((element, i) => {
                return (
                  <Option key={i} value={element.value}>
                    {element.label}
                  </Option>
                );
              })}
          </Select>
        </Col>
        <Col span={6}>
          <Button
            style={{
              float: "right",
              display: "flex",
              alignItems: "center",
            }}
            type="primary"
            icon={<SearchOutlined />}
            onClick={(e) => {
              e.stopPropagation();
              onSearchClick(e);
            }}
            loading={portData.fetchInProgress}
          >
            Search
          </Button>
        </Col>
      </Row>
      <Row style={{ margin: "12px 0" }}>
        {portPoints?.length > 0 && (
          <Col
            span={24}
            style={{
              display: "flex",
              justifyContent: "space-around",
              width: "100%",
            }}
          >
            {portPoints.map((item) => (
              <div style={{ display: "flex", gap: "12px" }}>
                <span>{item.port_name}</span>
                {portPoints.length > 1 &&
                  portPoints.length < portPoints.length && (
                    <>
                      <span
                        style={{
                          height: "5px",
                          width: "20px",
                          background: "blue",
                        }}
                      ></span>
                    </>
                  )}
                <span
                  style={{
                    cursor: "pointer",
                    userSelect: "none",
                    fontWeight: "bold",
                  }}
                  onClick={() => handleRemovePortPoint(item)}
                >
                  x
                </span>
              </div>
            ))}
          </Col>
        )}
      </Row>
      <Row gutter={16}>
        <Col>
          <div style={{ fontWeight: "bold", marginBottom: "4px" }}>
            Canal Options :
          </div>
          <Col>
            <Radio.Group
              // mode="multiple"
              style={{ display: "flex", flexDirection: "column", gap: "6px" }}
              allowClear
              // style={{ width: "100%" }}
              placeholder="Please select Canal Options"
              value={formdata.canalOptions}
              onChange={canalOnChange}
            >
              {canalPassoptions.map((el) => {
                return (
                  <Radio key={el.key} value={el.value}>
                    {el.label}
                  </Radio>
                );
              })}
            </Radio.Group>
          </Col>
        </Col>

        <Col>
          <div style={{ fontWeight: "bold", marginBottom: "4px" }}>
            Piracy Area :
          </div>

          <Col>
            <Radio.Group
              // mode="multiple"
              style={{ display: "flex", flexDirection: "column", gap: "6px" }}
              allowClear
              // style={{ width: "100%" }}
              placeholder="Please select piracy area"
              value={formdata.piracyArea}
              onChange={piracyOnChange}
            >
              {piracyoptions.map((el) => {
                return (
                  <Radio key={el.key} value={el.value}>
                    {el.label}
                  </Radio>
                );
              })}
            </Radio.Group>
          </Col>
        </Col>

        <Col>
          <div style={{ fontWeight: "bold", marginBottom: "4px" }}>SECA:</div>
          <Col>
            <Radio.Group
              style={{ display: "flex", flexDirection: "column", gap: "6px" }}
              name="secaGroup"
              onChange={onChangeEcaRadio}
              value={formdata.seca}
            >
              <Radio value={1}>None</Radio>
              <Radio value={2}>Normal</Radio>
              <Radio value={3}>Shortest</Radio>
              <Radio value={4}>Optimized</Radio>
            </Radio.Group>
          </Col>
        </Col>

        <Col>
          <div>
            <div style={{ fontWeight: "bold", marginBottom: "4px" }}>
              Local ECA:
            </div>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "4px" }}
            >
              <div>
                <Radio.Group
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                  }}
                  name="localecaGroup"
                  onChange={onChangelocalEcaRadio}
                  value={formdata.localEca}
                >
                  <Radio value={1}>True</Radio>
                  <Radio value={0}>False</Radio>
                </Radio.Group>
              </div>
            </div>
          </div>
        </Col>
        <Col>
          <div>
            <div style={{ fontWeight: "bold", marginBottom: "4px" }}>
              Map Areas:
            </div>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "4px" }}
            >
              <div
                style={{ display: "flex", flexDirection: "column", gap: "6px" }}
              >
                <Checkbox
                  checked={isEcaVisible}
                  onChange={(e) => {
                    e.stopPropagation();
                    if (map?.current) {
                      if (isEcaVisible) {
                        setIsEcaVisible(() => hideEcaArea(map));
                      } else {
                        setIsEcaVisible(() => showEcaArea(eca_polygons, map));
                      }
                    }
                  }}
                >
                  ECA
                </Checkbox>
                <Checkbox
                  checked={isHraVisible}
                  onChange={(e) => {
                    e.stopPropagation();
                    if (map?.current) {
                      if (isHraVisible) {
                        setIsHraVisible(() => hideHighRiskArea(map));
                      } else {
                        setIsHraVisible(() =>
                          showHighRiskArea(high_risk_area, map)
                        );
                      }
                    }
                  }}
                >
                  HRA
                </Checkbox>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Divider />
    </div>
  );

  // Chatbot open from popup when click on dashboard card
  const isFeaturePopupOpen = localStorage.getItem("featurePopup");
  const isPortDistanceOpen = localStorage.getItem("isPortDistance");

  useEffect(() => {
    if (isFeaturePopupOpen === "false" && isPortDistanceOpen === "true") {
      setDistanceModal(true);
    } else {
      localStorage.setItem("isPortDistance", false);
      setDistanceModal(false);
    }
  }, [isFeaturePopupOpen, isPortDistanceOpen]);
  // Chatbot open from popup when click on dashboard card

  const scrollToSection = () => {
    setIsTrackFleet(false);
    const section = document.getElementById("live-vessel");
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
    defaultVesselFunc()
  };

  const scrollToFleet = () => {
    setIsTrackFleet(true); // Assuming this state is managed elsewhere
    const section = fleetTableRef.current; // Use the ref here
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };


  const handleButtonClick = (button) => {
    setActiveButton(button);
    if (button === "LiveVesselTable") {
      scrollToSection();
    } else if (button === "MyFleetForTracking") {
      scrollToFleet();
    }
  };
  return (
    <>
      {isData && (
        <Spin fullscreen indicator={<CustomSpinner />}>
          <Alert message=" " description="Please wait..." type="info" />
        </Spin>
      )}
      <ToolBar
        map={map}
        openGlobalSearch={() =>
          setPortData((prev) => ({
            ...prev,
            isGlobalShipOpen: true,
            isVesselListShow: false,
          }))
        }
        openMarketOrder={() =>
          setPortData((prev) => ({
            ...prev,
            opencargoenquiryModal: true,
          }))
        }
        openTonnageOrder={() =>
          setPortData((prev) => ({
            ...prev,
            openTonnageEnquiryModal: true,
          }))
        }
        scrollFleetTable={scrollToTable}
        isPortDistanceVisible={isDistancCalModal}
        setIsPortDistModal={setDistanceModal}
        onSearchDbData={onSearchDbData}
        showTrackFleet={() => setIsTrackFleet(true)}
        distTable={portData.tableData}
        toggleDrawer={showDrawer}
        removeMarkersFromMap={removeMarkersFromMap}
        addMarkersToMap={addMarkersToMap}
        ports={ports}
        isbunkerPrice={isbunkerPrice}
        bunkerPrice={bunkerPrice}
      />

<div style={{ position: "relative" }}>
      <Button
        className={`vesselTable ${activeButton === "LiveVesselTable" ? "active" : ""}`}
        onClick={() => handleButtonClick("LiveVesselTable")}
        style={{ position: "absolute", right: "12rem", top: "-2.8rem" }}
      >
        Live Vessel Table
      </Button>
      <Button
        className={`fleetTracking ${activeButton === "MyFleetForTracking" ? "active" : ""}`}
        onClick={() => handleButtonClick("MyFleetForTracking")}
        style={{ position: "absolute", right: "1rem", top: "-2.8rem" }}
      >
        My Fleet For Tracking
      </Button>
    </div>

      {/* <div style={{ position: "relative" }}>
        <Button
          onClick={scrollToSection}
          style={{ position: "absolute", right: "12rem", top: "-2.8rem" }}
        >
          Live Vessel Table
        </Button>
        <Button
          onClick={scrollToFleet}
          style={{ position: "absolute", right: "1rem", top: "-2.8rem" }}
        >
          My Fleet For Tracking
        </Button>
      </div> */}
      <div className="wrap-rightbar full-wraps port-to-port-page">
        {globalSearch}

        <Row className="ant-form-item-label" style={{ overflow: "hidden" }}>
          <Col span={24} style={{ minHeight: "auto" }} className="map-padding">
            {/* <MapRender port_from={portDatafinalPortFrom} port_to={portDatafinalPortTo} /> */}
            <div className="sidebar">
              Longitude: {portData.lng} | Latitude:
              {portData.lat} | Zoom: {portData.zoom}
            </div>
            {renderDrawButtons()}
            <button
              onClick={onClearHandler}
              className="btn-clear"
              style={{ marginTop: "1rem" }}
            >
              Clear
            </button>
            <button
              onClick={onMapHandler}
              className="btn-clear"
              style={{ marginTop: "5rem" }}
            >
              Map
            </button>
            <div
              ref={MapRef}
              className="map-container"
              style={{
                width: "80wh",
                height: "100vh",
                overflow: "hidden !important",
              }}
            ></div>

            <div ref={fleetTableRef}>
              {portData.isPortDistTable ? (
                <Row style={{ marginTop: "4rem" }}>
                  <Col span={24}>
                    <b style={{ marginBottom: "2rem" }}>
                      Port Routes -Consumption Details
                    </b>
                    <div style={{ width: "100%" }}>
                      <Table
                        columns={columnConfiguration(tableColumns)}
                        dataSource={tableData}
                        // title={() => ""}
                        rowKey="id"
                        pagination={false}
                        align={"left"}
                      />
                    </div>
                  </Col>
                </Row>
              ) : istrackMyFleet ? (
                <TrackMyFleetTable
                  ref={fleetTableRef}
                  scrollToMap={scrollToMap}
                  alltabledata={(data, liveSearchList) => {
                    setPortData((prevState) => ({
                      ...prevState,
                      mapDataArr: data,
                      liveSearchList,
                    }));
                  }}
                  showlocation={(data) => props.modalCloseEvent(data)}
                  searchedVessel={searchedVessel}
                  showMyFleetOnMap={showMyFleetOnMap}
                  isInMyFleet={portData.isInMyFleet}
                />
              ) : null}
            </div>
            <PortDistanceSteps
              data={portData.stepData}
              visible={visible}
              onClose={onClose}
            />
          </Col>
          {isDistancCalModal && (
            <Modal
              title=""
              open={true}
              onCancel={() => {
                setDistanceModal(false);
                localStorage.setItem("isPortDistance", false);
              }}
              width={1000}
              footer={null}
            >
              {portDistancePopUp}
            </Modal>
          )}
        </Row>

        {visibleModal ? (
          <Modal
            style={{ top: "2%" }}
            title="Port Route Details"
            open={visibleModal}
            onCancel={() => showHideModal(false, null)}
            width="100%"
            footer={null}
          >
            <ShipDetails
              data={shipData}
              lat={portData.port_lat}
              lng={portData.port_lng}
            />
          </Modal>
        ) : undefined}

        {defaultVessel.length > 0 && !istrackMyFleet && (
          <div className="defaultVesselsTables">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                paddingBlock: "16px",
              }}
            >
              <span style={{ display: "block", marginLeft: "10px" }}>
                <b id="live-vessel">Live Vessel Information Table</b>
              </span>
              <Input
                placeholder="Search..."
                value={searchText}
                onChange={handleSearch}
                style={{ marginBottom: 20, width: 300 }}
                prefix={<SearchOutlined />}
              />
            </div>
            <Table
              bordered
              dataSource={filteredData}
              columns={defcolumns}
              rowHoverable
              scroll={{ x: 1300 }}
            />
          </div>
        )}

        {portData.opencargoenquiryModal ? (
          <Modal
            style={{ top: "2%" }}
            title="Market Order"
            open={portData.opencargoenquiryModal}
            onCancel={() =>
              setPortData((prev) => ({
                ...prev,
                opencargoenquiryModal: false,
              }))
            }
            width="45%"
            footer={null}
          >
            <GenerateCargoEnquiry />
          </Modal>
        ) : undefined}

        {portData.openTonnageEnquiryModal ? (
          <Modal
            style={{ top: "2%" }}
            title="Tonnage Order"
            open={portData.openTonnageEnquiryModal}
            onCancel={() =>
              setPortData((prevState) => ({
                ...prevState,
                openTonnageEnquiryModal: false,
              }))
            }
            width="45%"
            footer={null}
          >
            <GenerateTonnageEnquiry />
          </Modal>
        ) : undefined}
        {isMapModal && (
          <Modal
            open={isMapModal}
            onCancel={() => setIsMapModal(false)}
            footer={false}
            title="Live Vessel Information"
            width="50%"
            className="madMax"
          >
            <div
              className="vessel-pop"
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr 1fr",
                transition: "transform 0.4s ease, background-color 0.4s ease",
              }}
            >
              <div
                style={{
                  border: "1px solid #e0e0e0",
                  padding: 4,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <strong>Vessel Name</strong>
              </div>
              <div
                style={{
                  border: "1px solid #e0e0e0",
                  padding: 4,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {portData.vesselPositionDetails.SHIPNAME}
              </div>
              <div
                style={{
                  border: "1px solid #e0e0e0",
                  padding: 4,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <strong>IMO</strong>
              </div>
              <div
                style={{
                  border: "1px solid #e0e0e0",
                  padding: 4,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {portData.vesselPositionDetails.IMO}
              </div>
            </div>
            <div
              className="vessel-pop-one"
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr 1fr",
                transition: "transform 0.4s ease, background-color 0.4s ease",
              }}
            >
              <div
                style={{
                  border: "1px solid #e0e0e0",
                  padding: 4,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <strong>Dead Weight</strong>
              </div>
              <div
                style={{
                  border: "1px solid #e0e0e0",
                  padding: 4,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {portData.vesselPositionDetails.DWT}
              </div>
              <div
                style={{
                  border: "1px solid #e0e0e0",
                  padding: 4,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <strong>Average Speed</strong>
              </div>
              <div
                style={{
                  border: "1px solid #e0e0e0",
                  padding: 4,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {portData.vesselPositionDetails.AVG_SPEED}
              </div>
            </div>
            <div
              className="vessel-pop-two"
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr 1fr",
                transition: "transform 0.4s ease, background-color 0.4s ease",
              }}
            >
              <div
                style={{
                  border: "1px solid #e0e0e0",
                  padding: 4,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <strong>Current Port</strong>
              </div>
              <div
                style={{
                  border: "1px solid #e0e0e0",
                  padding: 4,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {portData.vesselPositionDetails.CURRENT_PORT}
              </div>
              <div
                style={{
                  border: "1px solid #e0e0e0",
                  padding: 4,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <strong>DESTINATION</strong>
              </div>
              <div
                style={{
                  border: "1px solid #e0e0e0",
                  padding: 4,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {portData.vesselPositionDetails?.DESTINATION}
              </div>
            </div>
            <div
              className="vessel-pop-three"
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr 1fr",
                transition: "transform 0.4s ease, background-color 0.4s ease",
                marginBottom: "1rem",
              }}
            >
              <div
                style={{
                  border: "1px solid #e0e0e0",
                  padding: 4,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <strong>Last Port</strong>
              </div>
              <div
                style={{
                  border: "1px solid #e0e0e0",
                  padding: 4,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {portData.vesselPositionDetails.LAST_PORT}
              </div>
              <div
                style={{
                  border: "1px solid #e0e0e0",
                  padding: 4,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <strong>Next Port</strong>
              </div>
              <div
                style={{
                  border: "1px solid #e0e0e0",
                  padding: 4,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {portData.vesselPositionDetails.NEXT_PORT_NAME}
              </div>
            </div>
            <strong>CII Simulator:</strong>
            <div
              className="vessel-pop"
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr 1fr",
                transition: "transform 0.4s ease, background-color 0.4s ease",
                marginTop: "1rem",
              }}
            >
              <div
                style={{
                  border: "1px solid #e0e0e0",
                  padding: 4,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <strong>Distance travelled</strong>
              </div>
              <div
                style={{
                  border: "1px solid #e0e0e0",
                  padding: 4,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {portData.vesselPositionDetails.DISTANCE_TRAVELLED}
              </div>
              <div
                style={{
                  border: "1px solid #e0e0e0",
                  padding: 4,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <strong>Co2 factor (g/t)</strong>
              </div>
              <div
                style={{
                  border: "1px solid #e0e0e0",
                  padding: 4,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span>
                  <i class="fas fa-lock yellow-icon-color"></i>
                </span>
              </div>
            </div>
            <div
              className="vessel-pop-one"
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr 1fr",
                transition: "transform 0.4s ease, background-color 0.4s ease",
              }}
            >
              <div
                style={{
                  border: "1px solid #e0e0e0",
                  padding: 4,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <strong>Fuel consumed</strong>
              </div>
              <div
                style={{
                  border: "1px solid #e0e0e0",
                  padding: 4,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span>
                  <i class="fas fa-lock yellow-icon-color"></i>
                </span>
              </div>
              <div
                style={{
                  border: "1px solid #e0e0e0",
                  padding: 4,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <strong>Co2 emission</strong>
              </div>
              <div
                style={{
                  border: "1px solid #e0e0e0",
                  padding: 4,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span>
                  <i class="fas fa-lock yellow-icon-color"></i>
                </span>
              </div>
            </div>
            <div
              className="vessel-pop-three"
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr 1fr",
                transition: "transform 0.4s ease, background-color 0.4s ease",
              }}
            >
              <div
                style={{
                  border: "1px solid #e0e0e0",
                  padding: 4,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <strong>CII Rating YTD</strong>
              </div>
              <div
                style={{
                  border: "1px solid #e0e0e0",
                  padding: 4,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span>
                  <i class="fas fa-lock yellow-icon-color"></i>
                </span>
              </div>
              <div
                style={{
                  border: "1px solid #e0e0e0",
                  padding: 4,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <strong>CII Voyage Rating</strong>
              </div>
              <div
                style={{
                  border: "1px solid #e0e0e0",
                  padding: 4,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span>
                  <i class="fas fa-lock yellow-icon-color"></i>
                </span>
              </div>
            </div>
          </Modal>
        )}
        <Modal
          title={
            defaultModalInfo?.SHIPNAME + "'s" + " Infomation" ||
            "Vessel Infomation"
          }
          open={defaultModal}
          onCancel={() => setDefaultModal(false)}
          centered
          footer={null}
        >
          <DefaultVesselModal info={defaultModalInfo} />
        </Modal>
      </div>
    </>
  );
};

export default PortMap;
