import { useEffect, useState, useRef } from "react";
import redux_state from "../../../../services/redux_state";
import { postAPICall } from "../../../../shared";

const useFetchAIData = (url, formName) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const formData1 = redux_state.reduxStore?.[formName];
  // console.log("fromaname", formName);
  // console.log("formdata3333", formData1);
  let parsedToken = JSON.parse(
    atob(localStorage.getItem("oceanToken").split(".")[1])
  );

  // console.log("parsedtoken",parsedToken)
  const previousValues = useRef({
    vessel_id: null,
    my_company_lob: null,
    cargos: [],
    portitinerary: [],
    my_company: null,
    tco_code: null,
  });

  useEffect(() => {
    let interval;

    checkForChangesAndFetch(); // Initial call

    interval = setInterval(checkForChangesAndFetch, 15000);

    return () => clearInterval(interval);
  }, [url, formData1]);

  const fetchData = async (payload) => {
    // debugger
    // console.log("DEMO-------->>>>>>>>>>>>>", formData1);
    try {
      setLoading(true);
      const response = await postAPICall(url, payload, "post");

      // console.log("result", response);
      setData(response);
      setError(null);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const checkForChangesAndFetch = () => {
    // console.log("formdata1111", formData1);

    if (
      formData1?.vessel_id &&
      formData1?.vessel_id !== previousValues.current.vessel_id
    ) {
      previousValues.current.vessel_id = formData1?.vessel_id;
      const vesselPayload = {
        company: parsedToken?.company_name,
        vessel_info: {
          vessel_id: formData1?.vessel_id || null,
          vessel_code: formData1?.vessel_code || null,
          vessel_name: formData1?.vesselInfo?.vessel_name,
          dwt: formData1?.dwt || null,
          vessel_type: formData1?.vessel_type || null,
          tci_code: formData1?.tci_code || null,
          head_fixture_id: formData1?.head_fixture_id || null,
          tci_d_hire: formData1?.tci_d_hire || null,
          add_percentage: formData1?.add_percentage || null,
          bro_percentage: formData1?.bro_percentage || null,
          dwf: formData1?.dwf || null,
          speed_table: formData1?.["-"],
        },
      };
      fetchData(vesselPayload);
    }

    if (
      formData1?.my_company_lob &&
      formData1?.my_company_lob !== previousValues.current.my_company_lob
    ) {
      previousValues.current.my_company_lob = formData1?.my_company_lob;
      const lobPayload = {
        company: parsedToken?.company_name,
        company_info: {
          my_company_lob: formData1?.my_company_lob || null,
          company_lob: formData1?.company_lob || null,
          cp_date: formData1?.cp_date || null,
          other_cost: formData1?.other_cost || null,
          bb: formData1?.bb || null,
          ops_type: formData1?.ops_type || null,
          eca_fuel_grade: formData1?.eca_fuel_grade || null,
          trade_area: formData1?.trade_area || null,
          total_days: formData1?.total_days || null,
          completing_date: formData1?.completing_date || null,
          commence_date: formData1?.commence_date || null,
          ops_user: formData1?.ops_user || null,
          user_name: formData1?.user_name || null,
          company_table: formData1?.["."],
        },
      };
      fetchData(lobPayload);
    }

    if (
      formName == "tcto_full_estimate_form" &&
      formData1?.my_company &&
      formData1?.my_company !== previousValues.current.my_company
    ) {
      previousValues.current.my_company = formData1?.my_company;
      const lobPayload = {
        company: parsedToken?.company_name,
        company_info: {
          my_company: formData1?.my_company || null,
          company_lob: formData1?.company_lob || null,
          cp_date: formData1?.cp_date || null,
          other_cost: formData1?.other_cost || null,
          bb: formData1?.bb || null,
          ops_type: formData1?.ops_type || null,
          eca_fuel_grade: formData1?.eca_fuel_grade || null,
          trade_area: formData1?.trade_area || null,
          total_days: formData1?.total_days || null,
          completing_date: formData1?.completing_date || null,
          commence_date: formData1?.commence_date || null,
          ops_user: formData1?.ops_user || null,
          user_name: formData1?.user_name || null,
          company_table: formData1?.["."],
        },
      };
      fetchData(lobPayload);
    }

    if (
      formName == "tcto_full_estimate_form" &&
      formData1?.tco_code &&
      formData1?.tco_code !== previousValues.current.tco_code
    ) {
      previousValues.current.tco_code = formData1?.tco_code;
      const tcoTermPayload = {
        company: parsedToken?.company_name || null,
        tco_term: formData1?.tcoterm,
        term: formData1?.term,
      };
      fetchData(tcoTermPayload);
    }

    // console.log("PREV_--CAR", previousValues.current.cargos);
    // console.log("CURR--CAR", formData1?.cargos);
    // console.log("CURR--frghCAR", formData1);

    if (
      JSON.stringify(formData1?.cargos) !==
      JSON.stringify(previousValues.current.cargos)
    ) {
      previousValues.current.cargos = formData1?.cargos;
      // console.log("cargo called");

      if (formData1?.cargos && formData1?.cargos.length > 0) {
        // console.log("CALLED---CARGO");
        const isValidCargo = formData1?.cargos.some(
          (cargo) => cargo?.cargo_contract_id
        );
        // console.log("isvalidcargo", isValidCargo);
        if (isValidCargo) {
          // console.log("isvalid");
          fetchData({
            company: parsedToken?.company_name,
            cargo_info: formData1?.cargos,
          });
        }
      }
    }

    if (
      JSON.stringify(formData1?.portitinerary) !==
      JSON.stringify(previousValues.current.portitinerary)
    ) {
      previousValues.current.portitinerary = formData1?.portitinerary;
      // console.log("cargo called");

      if (formData1?.portitinerary && formData1?.portitinerary.length > 0) {
        const isValidPort = formData1?.portitinerary.some(
          (port) => port?.port && port.port !== "Select Port" // Exclude "port select"
        );
        // console.log("isvalidport", isValidPort);
        if (isValidPort) {
          // console.log("isvalid");
          fetchData({
            company: parsedToken?.company_name,
            port_info: formData1?.portitinerary.filter(
              (port) => port?.port && port.port !== "Select Port"
            ), // Filter out "port select" before sending to API
          });
        }
      }
    }
  };

  return { data, error, loading };
};

export default useFetchAIData;
