import React, { useRef, useState, forwardRef, useEffect } from "react";
import { PrinterOutlined } from "@ant-design/icons";
import { Modal, Spin } from "antd";
import ReactToPrint from "react-to-print";
import jsPDF from "jspdf";
import Email from "../../components/Email";
import * as htmlToImage from "html-to-image";
import html2canvas from "html2canvas";
import { IMAGE_PATH } from "../../shared";

const ComponentToPrint = forwardRef((props, ref) => {
  const [formReportdata, setFormReportdata] = useState(
    Object.assign({}, props.data || {})
  );

  return (
    <article className="article toolbaruiWrapper" ref={ref}>
      <div className="box box-default" id="divToPrint">
        <div className="box-body">
          <div className="invoice-inner-download mt-3">
            <div className="row">
              <div className="col-12 text-center">
                <img
                  className="reportlogo"
                  src={formReportdata.vessel_img || "No Img"}
                ></img>
                <p className="sub-title m-0">
                  {formReportdata.vessel_name || "N/A"}
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-10 mx-auto">
              <div className="text-center invoice-top-address">
                <p>{formReportdata.address || "N/A"}</p>
              </div>
            </div>
          </div>

          <div className="row p10">
            <div className="col-md-12">
              <table className="table custom-table-bordered tc-table">
                <tbody>
                  <tr>
                    <td className="font-weight-bold">Vessel Name :</td>
                    <td>{formReportdata.vessel_name || "N/A"}</td>

                    <td className="font-weight-bold">Vessel Code :</td>
                    <td>{formReportdata.vessel_code || "N/A"}</td>

                    <td className="font-weight-bold">Vessel DWT :</td>
                    <td>{formReportdata.vessel_dwt || "N/A"}</td>
                  </tr>

                  <tr>
                    <td className="font-weight-bold">Vessel Sub Type:</td>

                    <td>
                      {formReportdata.type_code
                        ? formReportdata.type_code
                        : "N/A"}
                    </td>

                    <td className="font-weight-bold">Year Built :</td>
                    <td>{formReportdata.year_built || "N/A"}</td>

                    <td className="font-weight-bold">SW Summer DWT :</td>
                    <td>{formReportdata.sw_summer_draft || "N/A"}</td>
                  </tr>

                  <tr>
                    <td className="font-weight-bold">Ownership :</td>
                    <td>
                      {formReportdata.owner_ship_name
                        ? formReportdata.owner_ship_name
                        : "N/A"}
                    </td>

                    <td className="font-weight-bold">Vessel Type :</td>
                    <td>{formReportdata.vessel_type_name || "N/A"}</td>

                    <td className="font-weight-bold">TPC :</td>
                    <td>{formReportdata.tcp || "N/A"}</td>
                  </tr>

                  <tr>
                    <td className="font-weight-bold">IMO No :</td>
                    <td>{formReportdata.imo_no || "N/A"}</td>

                    <td className="font-weight-bold">Vessel Fleet :</td>
                    <td>{formReportdata.vessel_fleet || "N/A"}</td>

                    <td className="font-weight-bold">Capacity (Bail) :</td>
                    <td>{formReportdata.capacity_bale || "N/A"}</td>
                  </tr>

                  <tr>
                    <td className="font-weight-bold">Daily Cost :</td>
                    <td>{formReportdata.daily_cost || "N/A"}</td>

                    <td className="font-weight-bold">Trade Area :</td>
                    <td>{formReportdata.trade_area_name || "N/A"}</td>

                    <td className="font-weight-bold">Capacity (Grain) :</td>
                    <td>{formReportdata.capacity_grain || "N/A"}</td>
                  </tr>
                  <tr>
                    <td className="font-weight-bold">Speed Laden :</td>
                    <td>{formReportdata.spd_laden || "N/A"}</td>

                    <td className="font-weight-bold">Vessel Owner :</td>
                    <td>{formReportdata.vessel_owner_name || "N/A"}</td>
                    <td className="font-weight-bold">EEDI :</td>
                    <td>{formReportdata.EEDI || "N/A"}</td>
                  </tr>

                  <tr>
                    <td className="font-weight-bold">Speed Ballast :</td>
                    <td>{formReportdata.spd_ballast || "N/A"}</td>

                    <td className="font-weight-bold">Class Society :</td>
                    <td>{formReportdata.class_society || "N/A"}</td>

                    <td className="font-weight-bold">Scrubber :</td>
                    <td>{formReportdata["scrubber"] == 1 ? "Yes" : "No"}</td>
                  </tr>
                </tbody>
              </table>

              <h4 className="font-weight-bold">Consumptions</h4>

              <h6 className="font-weight-bold">Port Consp. Table (Per Day)</h6>

              <table className="table custom-table-bordered tc-table">
                <thead>
                  <tr className="HeaderBoxText">
                    <th>Fuel Type</th>
                    <th>Grade</th>
                    <th>Unit</th>
                    <th>Loading</th>
                    <th>Disch</th>
                    <th>Ideal On</th>
                    <th>Capacity</th>
                    <th>Margin</th>
                    <th>Heat</th>
                    <th>Heat +</th>
                    <th>Heat +2</th>
                    <th>IGS</th>
                    <th>Clean</th>
                    <th>Menu UV</th>
                    <th>AV</th>
                  </tr>
                </thead>
                <tbody>
                  {formReportdata["portconsp.tableperday"] &&
                  formReportdata["portconsp.tableperday"].length > 0
                    ? formReportdata["portconsp.tableperday"].map((e, idx) => {
                        return (
                          <>
                            <tr key={idx}>
                              <td>{e.con_type || "N/A"}</td>
                              <td>{e.con_g || "N/A"}</td>
                              <td>{e.con_unit || "N/A"}</td>
                              <td>{e.con_loading || "N/A"}</td>
                              <td>{e.con_disch || "N/A"}</td>
                              <td>{e.con_ideal_on || "N/A"}</td>
                              <td>{e.con_capacity || "N/A"}</td>
                              <td>{e.con_margin || "N/A"}</td>
                              <td>{e.con_heat || "N/A"}</td>
                              <td>{e.con_heat_p || "N/A"}</td>
                              <td>{e.con_heat_pp || "N/A"}</td>
                              <td>{e.con_igs || "N/A"}</td>
                              <td>{e.con_clean || "N/A"}</td>
                              <td>{e.con_maneuv || "N/A"}</td>
                              <td>{e.con_av || "N/A"}</td>
                            </tr>
                          </>
                        );
                      })
                    : undefined}
                </tbody>
              </table>

              <h6 className="font-weight-bold">
                Sea Spd Consp. Table (Per Day)
              </h6>

              <table className="table custom-table-bordered tc-table">
                <thead>
                  <tr className="HeaderBoxText">
                    <th>Speed Type</th>
                    <th>Speed (Kt)</th>
                    <th>Passage Type</th>
                    <th>Engine Load (%)</th>
                    <th>IFO Consp.</th>
                    <th>LSFO Consp.</th>
                    <th>ULSFO Consp.</th>
                    <th>LSMGO Consp.</th>
                    <th>MGO Consp.</th>
                  </tr>
                </thead>
                <tbody>
                  {formReportdata["seaspdconsp.tableperday"] &&
                  formReportdata["seaspdconsp.tableperday"].length > 0
                    ? formReportdata["seaspdconsp.tableperday"].map(
                        (e, idx) => {
                          return (
                            <>
                              <tr key={idx}>
                                <td>{e.speed_type || "N/A"}</td>
                                <td>{e.speed || "N/A"}</td>
                                <td>
                                  {e.ballast_laden ? e.ballast_laden : "N/A"}
                                </td>
                                <td>{e.engine_load}</td>
                                <td>{e.ifo ? e.ifo : "N/A"}</td>
                                <td>{e.vlsfo || "N/A"}</td>
                                <td>{e.ulsfo || "N/A"}</td>
                                <td>{e.lsmgo || "N/A"}</td>
                                <td>{e.mgo || "N/A"}</td>
                              </tr>
                            </>
                          );
                        }
                      )
                    : undefined}
                </tbody>
              </table>

              <h4 className="font-weight-bold">Deadweight Details</h4>

              <table className="table custom-table-bordered tc-table">
                <thead>
                  <tr className="HeaderBoxText">
                    <th>DWT(MT)</th>
                    <th>Displ(MT)</th>
                    <th>Draft(M)</th>
                    <th>TPC</th>
                    <th>Remarks</th>
                  </tr>
                </thead>
                <tbody>
                  {formReportdata.vesseldwtdrafts &&
                  formReportdata.vesseldwtdrafts.length > 0
                    ? formReportdata.vesseldwtdrafts.map((e, idx) => {
                        return (
                          <>
                            <tr key={idx}>
                              <td>{e.dwt_mt || "N/A"}</td>
                              <td>{e.displ_mt || "N/A"}</td>
                              <td>{e.draft_m || "N/A"}</td>
                              <td>{e.tpc || "N/A"}</td>
                              <td>{e.remarks || "N/A"}</td>
                            </tr>
                          </>
                        );
                      })
                    : undefined}
                </tbody>
              </table>

              <h4 className="font-weight-bold">Details</h4>

              <h5 className="font-weight-bold">Vessel Identification</h5>

              <table className="table custom-table-bordered tc-table">
                <tbody>
                  <tr>
                    <td className="font-weight-bold">Call Letters :</td>
                    <td>
                      {formReportdata?.vesselidentification?.call_letters ||
                        "N/A"}
                    </td>

                    <td className="font-weight-bold">Operator :</td>

                    <td>
                      {formReportdata?.vesselidentification?.operator || "N/A"}
                    </td>

                    <td className="font-weight-bold">Former Name :</td>

                    <td>
                      {formReportdata?.vesselidentification?.formar_name ||
                        "N/A"}
                    </td>
                  </tr>

                  <tr>
                    <td className="font-weight-bold">Official No :</td>

                    <td>
                      {formReportdata?.vesselidentification?.official_no ||
                        "N/A"}
                    </td>

                    <td className="font-weight-bold">Suez Vsl Type :</td>

                    <td>
                      {formReportdata?.vesselidentification?.suez_vsl_type ||
                        "N/A"}
                    </td>

                    <td className="font-weight-bold">PNS No :</td>

                    <td>
                      {formReportdata?.vesselidentification?.pns_no || "N/A"}
                    </td>
                  </tr>

                  <tr>
                    <td className="font-weight-bold">Yard :</td>
                    <td>
                      {formReportdata?.vesselidentification?.yard || "N/A"}
                    </td>

                    <td className="font-weight-bold">Hatch Type :</td>
                    <td>
                      {formReportdata?.vesselidentification?.hatch_type ||
                        "N/A"}
                    </td>

                    <td className="font-weight-bold">Builder :</td>
                    <td>
                      {formReportdata?.vesselidentification?.builder || "N/A"}
                    </td>
                  </tr>

                  <tr>
                    <td className="font-weight-bold">H&M Value :</td>
                    <td>
                      {formReportdata?.vesselidentification?.h_m_values ||
                        "N/A"}
                    </td>

                    <td className="font-weight-bold">Build Details :</td>
                    <td>
                      {formReportdata?.vesselidentification?.build_details ||
                        "N/A"}
                    </td>

                    <td className="font-weight-bold">P&I Club :</td>
                    <td>
                      {formReportdata?.vesselidentification?.p_i_club || "N/A"}
                    </td>
                  </tr>

                  <tr>
                    <td className="font-weight-bold">Vessel Flag :</td>
                    <td>
                      {formReportdata?.vesselidentification?.vessel_flag ||
                        "N/A"}
                    </td>
                    <td className="font-weight-bold">GAP Value :</td>
                    <td>
                      {formReportdata?.vesselidentification?.gap_value || "N/A"}
                    </td>

                    <td className="font-weight-bold">Registry :</td>
                    <td>
                      {formReportdata?.vesselidentification?.registry || "N/A"}
                    </td>
                  </tr>

                  <tr>
                    <td className="font-weight-bold">Pool Point :</td>
                    <td>
                      {formReportdata?.vesselidentification?.pool_point ||
                        "N/A"}
                    </td>

                    <td className="font-weight-bold">Disponent Owner :</td>
                    <td>
                      {formReportdata?.vesselidentification?.disponent_owner ||
                        "N/A"}
                    </td>

                    <td className="font-weight-bold">DWT Date :</td>
                    <td>
                      {formReportdata?.vesselidentification?.dwt_date || "N/A"}
                    </td>
                  </tr>

                  <tr>
                    <td className="font-weight-bold">Hull No./Type :</td>
                    <td>
                      {formReportdata?.vesselidentification?.hull_no || "N/A"}/
                      {formReportdata?.vesselidentification?.hull_type || "N/A"}
                    </td>

                    <td className="font-weight-bold">Last Dry Dock :</td>
                    <td>
                      {formReportdata?.vesselidentification?.last_dry_dock
                        ? formReportdata?.vesselidentification?.last_dry_dock
                        : "N/A"}
                    </td>

                    <td className="font-weight-bold">Cross Ref. No. :</td>
                    <td>
                      {formReportdata?.vesselidentification?.cross_ref_no}
                    </td>
                  </tr>

                  <tr>
                    <td className="font-weight-bold">Next Dry Dock :</td>
                    <td>
                      {formReportdata?.vesselidentification?.next_dry_dock ||
                        "N/A"}
                    </td>

                    <td className="font-weight-bold">Ventilation :</td>
                    <td>
                      {formReportdata?.vesselidentification?.ventilation ||
                        "N/A"}
                    </td>

                    <td className="font-weight-bold">Next Survey :</td>
                    <td>
                      {formReportdata?.vesselidentification?.next_survey ||
                        "N/A"}
                    </td>
                  </tr>

                  <tr>
                    <td className="font-weight-bold">Ice Class :</td>
                    <td>
                      {formReportdata?.vesselidentification?.ice_class
                        ? formReportdata?.vesselidentification?.ice_class
                        : "N/A"}
                    </td>

                    <td className="font-weight-bold">Next Inspection :</td>
                    <td>
                      {formReportdata?.vesselidentification?.next_inspection ||
                        "N/A"}
                    </td>

                    <td className="font-weight-bold">Engine Make :</td>
                    <td>
                      {formReportdata?.vesselidentification?.engine_make ||
                        "N/A"}
                    </td>
                  </tr>

                  <tr>
                    <td className="font-weight-bold">Last Prop Polished :</td>
                    <td>
                      {formReportdata?.vesselidentification?.last_prop_polished
                        ? formReportdata?.vesselidentification
                            ?.last_prop_polished
                        : "N/A"}
                    </td>

                    <td className="font-weight-bold">Propeller Pitch :</td>
                    <td>
                      {formReportdata?.vesselidentification?.propeller_pitch ||
                        "N/A"}
                    </td>

                    <td className="font-weight-bold">Last Hull Cleaning :</td>
                    <td>
                      {formReportdata?.vesselidentification?.last_hull_cleaning
                        ? formReportdata?.vesselidentification
                            ?.last_hull_cleaning
                        : "N/A"}
                    </td>
                  </tr>

                  <tr>
                    <td className="font-weight-bold">Cargo/Gear :</td>
                    <td>
                      {formReportdata?.vesselidentification?.cargo_gear ||
                        "N/A"}
                    </td>

                    <td className="font-weight-bold">TVE Expires :</td>
                    <td>
                      {formReportdata?.vesselidentification?.tve_expires ||
                        "N/A"}
                    </td>

                    <td colSpan="2"></td>
                  </tr>
                </tbody>
              </table>

              <h5 className="font-weight-bold">Capacity And Draft</h5>
              <table className="table custom-table-bordered tc-table">
                <tbody>
                  <tr>
                    <td className="font-weight-bold">OPA ^90 :</td>
                    <td>{formReportdata?.capacityanddraft?.opa_90 || "N/A"}</td>

                    <td className="font-weight-bold">Lightship :</td>
                    <td>
                      {formReportdata?.capacityanddraft?.lightship || "N/A"}
                    </td>

                    <td className="font-weight-bold">Winter Draft :</td>
                    <td>
                      {formReportdata?.capacityanddraft?.winter_draft || "N/A"}
                    </td>
                  </tr>

                  <tr>
                    <td className="font-weight-bold">Max Draft :</td>
                    <td>
                      {formReportdata?.capacityanddraft?.max_draft || "N/A"}
                    </td>

                    <td className="font-weight-bold">GRT Int'l :</td>
                    <td>
                      {formReportdata?.capacityanddraft?.grt_intl || "N/A"}
                    </td>

                    <td className="font-weight-bold">NRT Int'l :</td>
                    <td>
                      {formReportdata?.capacityanddraft?.nrt_intl || "N/A"}
                    </td>
                  </tr>

                  <tr>
                    <td className="font-weight-bold">Panama Gross :</td>
                    <td>
                      {formReportdata?.capacityanddraft?.panama_gross || "N/A"}
                    </td>

                    <td className="font-weight-bold">Net :</td>
                    <td>{formReportdata?.capacityanddraft?.net || "N/A"}</td>

                    <td className="font-weight-bold">Suez Gross :</td>
                    <td>
                      {formReportdata?.capacityanddraft?.suez_gross || "N/A"}
                    </td>
                  </tr>

                  <tr>
                    <td className="font-weight-bold">Net :</td>
                    <td>{formReportdata?.capacityanddraft?.net2 || "N/A"}</td>

                    <td className="font-weight-bold">Grabs Qty :</td>
                    <td>
                      {formReportdata?.capacityanddraft?.grabs_qty || "N/A"}
                    </td>

                    <td className="font-weight-bold">Grabs Capacity :</td>
                    <td>
                      {formReportdata?.capacityanddraft?.grabs_capacity ||
                        "N/A"}
                    </td>
                  </tr>

                  <tr>
                    <td className="font-weight-bold">Unit Factor :</td>
                    <td>
                      {formReportdata?.capacityanddraft?.unit_factor || "N/A"}
                    </td>

                    <td colSpan="6"></td>
                  </tr>
                </tbody>
              </table>

              <h5 className="font-weight-bold">Dimensions</h5>

              <table className="table custom-table-bordered tc-table">
                <tbody>
                  <tr>
                    <td className="font-weight-bold">LOA :</td>
                    <td>{formReportdata?.dimensions?.loa || "N/A"}</td>

                    <td className="font-weight-bold">Beam :</td>
                    <td>{formReportdata?.dimensions?.beam || "N/A"}</td>

                    <td className="font-weight-bold">Depth :</td>
                    <td>{formReportdata?.dimensions?.depth || "N/A"}</td>
                  </tr>
                </tbody>
              </table>

              <h4 className="font-weight-bold">Contacts</h4>

              <table className="table custom-table-bordered tc-table">
                <tbody>
                  <tr>
                    <td className="font-weight-bold">Manager :</td>
                    <td>{formReportdata?.contacts?.manager || "N/A"}</td>

                    <td className="font-weight-bold">Sat A :</td>
                    <td>{formReportdata?.contacts?.sat_a || "N/A"}</td>

                    <td className="font-weight-bold">Sat B :</td>
                    <td>{formReportdata?.contacts?.sat_b || "N/A"}</td>
                  </tr>

                  <tr>
                    <td className="font-weight-bold">Sat C :</td>
                    <td>{formReportdata?.contacts?.sat_c || "N/A"}</td>

                    <td className="font-weight-bold">Mini-M :</td>
                    <td>{formReportdata?.contacts?.mini_m || "N/A"}</td>

                    <td className="font-weight-bold">Telex :</td>
                    <td>{formReportdata?.contacts?.telex}</td>
                  </tr>

                  <tr>
                    <td className="font-weight-bold">Fax :</td>
                    <td>{formReportdata?.contacts?.fax || "N/A"}</td>

                    <td className="font-weight-bold">Cellular :</td>
                    <td>{formReportdata?.contacts?.cellular || "N/A"}</td>

                    <td className="font-weight-bold">Master's No :</td>
                    <td>
                      {formReportdata?.contacts?.masters_no
                        ? formReportdata?.contacts?.masters_no
                        : "N/A"}
                    </td>
                  </tr>

                  <tr>
                    <td className="font-weight-bold">CCR No :</td>
                    <td>{formReportdata?.contacts?.ccr_no || "N/A"}</td>

                    <td className="font-weight-bold">Bridge No :</td>
                    <td>{formReportdata?.contacts?.bridge_no || "N/A"}</td>

                    <td className="font-weight-bold">Email :</td>
                    <td>{formReportdata?.contacts?.email || "N/A"}</td>
                  </tr>

                  <tr>
                    <td className="font-weight-bold">Remarks :</td>
                    <td>{formReportdata?.contacts?.vessel_remarks || "N/A"}</td>

                    <td colSpan="6"></td>
                  </tr>
                </tbody>
              </table>

              <h4 className="font-weight-bold">Tankers</h4>
              <h5 className="font-weight-bold">Capacity And Specifications</h5>


              <table className="table custom-table-bordered tc-table">
                <thead>
                  <tr className="HeaderBoxText">
                    <th>Deadweight Tonnage (DWT)</th>
                    <th>Gross Tonnage (GT)</th>
                    <th>Net Tonnage (NT)</th>
                    <th>Cargo Tank Capacity (CBM)</th>
                    <th>Ballast Water Capacity (BWC)</th>
                  </tr>
                </thead>
                <tbody>
                  {formReportdata["capacityandspecifications"] &&
                  formReportdata["capacityandspecifications"].length > 0
                    ? formReportdata["capacityandspecifications"].map((e, idx) => {
                        return (
                          <>
                            <tr key={idx}>
                              <td>{e?.dead_weight_tonnage || "N/A"}</td>
                              <td>{e?.gross_tonnage || "N/A"}</td>
                              <td>{e?.net_tonnage || "N/A"}</td>
                              <td>{e?.cargo_tank_capacity || "N/A"}</td>
                              <td>{e?.ballast_water_capacity || "N/A"}</td>
                            </tr>
                          </>
                        );
                      })
                    : undefined}
                </tbody>
              </table>

              <h5 className="font-weight-bold">Cargo And Loading Information</h5>

              <table className="table custom-table-bordered tc-table">
                <thead>
                  <tr className="HeaderBoxText">
                    <th>Cargo Type</th>
                    <th>Tank Coating Type (Inside)</th>
                    <th>Max Loading Rate (m³/hr)</th>
                    <th>Discharge Rate (m³/hr)</th>
                    <th>Pump Type</th>
                  </tr>
                </thead>
                <tbody>
                  {formReportdata["cargoandloadinginformation"] &&
                  formReportdata["cargoandloadinginformation"].length > 0
                    ? formReportdata["cargoandloadinginformation"].map((e, idx) => {
                        return (
                          <>
                            <tr key={idx}>
                              <td>{e?.cargo_type_name || "N/A"}</td>
                              <td>{e?.insie_tank_coating_type_name || "N/A"}</td>
                              <td>{e?.max_loading_rate || "N/A"}</td>
                              <td>{e?.discharge_rate || "N/A"}</td>
                              <td>{e?.pump_type_name || "N/A"}</td>
                            </tr>
                          </>
                        );
                      })
                    : undefined}
                </tbody>
              </table>

              <h5 className="font-weight-bold">Operations And Safety</h5>

              <table className="table custom-table-bordered tc-table">
                <thead>
                  <tr className="HeaderBoxText">
                    <th>Pump Rate (m³/hr)</th>
                    <th>Heating Required</th>
                    <th>Tank Coating Type</th>
                    <th>Inert Gas System (IGS)</th>
                    <th>Last Inspection Date</th>
                  </tr>
                </thead>
                <tbody>
                  {formReportdata["operationsandsafety"] &&
                  formReportdata["operationsandsafety"].length > 0
                    ? formReportdata["operationsandsafety"].map((e, idx) => {
                        return (
                          <>
                            <tr key={idx}>
                              <td>{e?.pump_rate || "N/A"}</td>
                              <td>{e?.heating_required_name || "N/A"}</td>
                              <td>{e?.tank_coating_type_name || "N/A"}</td>
                              <td>{e?.inert_gas_System_name || "N/A"}</td>
                              <td>{e?.last_inspection_date || "N/A"}</td>
                            </tr>
                          </>
                        );
                      })
                    : undefined}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </article>
  );
});

const VesselFormReport = (props) => {
  const [pdfData, setPdfData] = useState();
  const [userInput, setUserInput] = useState();
  const [emailModal, setEmailModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [mailTitlePayload, setMailTitlePayload] = useState({});
  const [titleArray, setTitleArray] = useState([]);
  const [state, setState] = useState({
    name: "Printer",
  });

  const componentRef = useRef();

  const printReceipt = () => {
    window.print();
  };

  useEffect(() => {
    // const{vessel_name,vessel_code,vessel_type_name,vessel_owner_name}=props.data
    // const tempArray=[vessel_name,vessel_code,vessel_type_name,vessel_owner_name]
    // setTitleArray(tempArray)
    setUserInput(props.data);
  }, []);

  const sendEmailReportModal = async () => {
    try {
      setLoading(true);

      const quotes = document.getElementById("divToPrint");

      const canvas = await html2canvas(quotes, {
        logging: true,
        letterRendering: 1,
        useCORS: true,
        allowTaint: true,
        scale: 2,
      });

      const imgWidth = 210;
      const pageHeight = 290;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;

      const doc = new jsPDF("p", "mm");
      let position = 25;
      const pageData = canvas.toDataURL("image/jpeg", 1.0);
      doc.addImage(pageData, "PNG", 5, position, imgWidth - 8, imgHeight - 7);
      doc.setLineWidth(5);
      doc.setDrawColor(255, 255, 255);
      doc.rect(0, 0, 210, 295);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(
          pageData,
          "PNG",
          5,
          position + 25,
          imgWidth - 8,
          imgHeight - 7
        );
        doc.setLineWidth(5);
        doc.setDrawColor(255, 255, 255);
        doc.rect(0, 0, 210, 295);
        heightLeft -= pageHeight;
      }

      // Create Blob
      const blob = doc.output("blob");

      // Use the blob as needed (e.g., send it to the server, create a download link, etc.)
      setLoading(false);
      setPdfData(blob);
      setEmailModal(true);
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
      // this.setState({ loading: false });
      // Handle errors here
    }
  };

  const printDocument = () => {
    var quotes = document.getElementById("divToPrint");
    html2canvas(quotes).then(function (canvas) {
      var imgWidth = 210;
      var pageHeight = 290;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      var heightLeft = imgHeight;
      var doc = new jsPDF("p", "mm");
      var position = 7;
      var pageData = canvas.toDataURL("image/jpeg", 1.0);
      var imgData = encodeURIComponent(pageData);
      doc.addImage(imgData, "PNG", 5, position, imgWidth - 8, imgHeight - 7);
      doc.setLineWidth(5);
      doc.setDrawColor(255, 255, 255);
      doc.rect(0, 0, 210, 295);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;

        doc.addPage();
        doc.addImage(
          imgData,
          "PNG",
          5,
          position + 10,
          imgWidth - 8,
          imgHeight - 7
        );
        doc.setLineWidth(5);
        doc.setDrawColor(255, 255, 255);
        doc.rect(0, 0, 210, 295);
        heightLeft -= pageHeight;
      }
      doc.save("vessel-form.pdf");
    });
  };

  return (
    <div className="body-wrapper modalWrapper">
      <article className="article toolbaruiWrapper">
        <div className="box box-default">
          <div className="box-body">
            <div className="toolbar-ui-wrapper">
              <div className="leftsection"></div>
              <div className="rightsection">
                <span className="wrap-bar-menu">
                  <ul className="wrap-bar-ul">
                    <li onClick={sendEmailReportModal}>Send Email</li>
                    <li onClick={printDocument}>Download</li>
                    <li>
                      <ReactToPrint
                        trigger={() => (
                          <span className="text-bt">
                            <PrinterOutlined /> Print
                          </span>
                        )}
                        content={() => componentRef.current}
                      />
                    </li>
                  </ul>
                </span>
              </div>
            </div>
          </div>
        </div>
      </article>

      <article className="article">
        <div className="box box-default">
          <div className="box-body">
            <ComponentToPrint ref={componentRef} data={props.data} />
          </div>
        </div>
      </article>

      {emailModal && (
        <Modal
          title="New Message"
          visible={emailModal}
          onOk={() => {
            setEmailModal(false);
          }}
          onCancel={() => {
            setEmailModal(false);
          }}
          footer={null}
        >
          {pdfData && (
            <Email
              handleClose={() => {
                setEmailModal(false);
              }}
              attachmentFile={pdfData}
              title={window.corrector(
                `Vessel_Form_report||${userInput.vessel_name}||${userInput.vessel_code}||${userInput.vessel_type_name}||${userInput.vessel_owner_name}`
              )}

              // title={window.emailTitltCorrectFunction('Vessel_Form_Report',titleArray) }
              // title={`Vessel_Form_Report|| ${userInput.vessel_name}(${userInput.vessel_code})||${userInput.vessel_type_name}||${userInput.vessel_owner_name}`}
            />
          )}
        </Modal>
      )}
      {loading && (
        <div
          style={{
            position: "absolute",
            top: "10%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Spin size="large" />
        </div>
      )}
    </div>
  );
};

export default VesselFormReport;
